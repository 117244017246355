import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { IoWalletOutline } from "react-icons/io5";
import Switch from "react-switch";
import { MdLocalGasStation } from "react-icons/md";

function Withdrawmodals(props) {

    const [withdrawmodal, setWithdrawmodal] = useState(true);
    const [withdrawstep, setWithdrawstep] = useState(1);
    const [checked, setChecked] = useState(true);
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    return (

        <div className='walletmodal'>

            <Modal show={withdrawmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body className="py-4 px-3">
                    <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                    {withdrawstep === 1 &&
                        <>
                            <h5 className="themeclr fw-600 mb-4">Withdraw ETH</h5>
                            <h6 className="fw-500 themeclr mt-2">Amount</h6>
                            <div className="swap__blueBox px-3 d-flex pt-2 pb-2 justify-content-between rounded-4">
                                <div>
                                    <input type="text" className="form-control ps-0 pt-0 swap_input" placeholder="0.00" />
                                    <p className="mb-0">$2.88</p>
                                </div>
                                <div>
                                    <p className="mb-2 themeclr text-end"><img src={Images.eth} className="img-fluid ethimg" /> ETH</p>
                                    <p className="mb-0">Bal 0.236 Max</p>
                                </div>
                            </div>
                            <h6 className="fw-500 themeclr mt-3">Transaction Overview</h6>
                            <div className="swap__blueBox px-3 pt-2 pb-2 rounded-4">
                                <div className="d-flex justify-content-between mb-2">
                                <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    offColor="#162019"
                                    onColor="#1888b7"
                                    offHandleColor="#ffff"
                                    onHandleColor="#00e7fa"
                                    height={20}
                                    width={36}
                                    handleDiameter={18}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                    <p className="mb-0 themeclr">unwrap WDEX[to withdraw DEX]</p></div>
                                <div className="d-flex justify-content-between"><p className="mb-0 fw-500 themeclr">Remaining Supply</p><p className="mb-0 themeclr">0 DEX</p></div>
                            </div>
                            <p className="mt-3 themeclr fw-600 mb-0"><MdLocalGasStation className="mts"/> 0.98</p>

                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-2 w-100" onClick={() => setWithdrawstep(2)}>Approve to Continue</button>
                            <button class="btn btn-brand-1 sitebtn hover-up mb-3 w-100" disabled onClick={() => setWithdrawstep(2)}>Withdraw DEX</button>

                        </>
                    }

                    {withdrawstep === 2 &&
                        <>
                            <div className="success_tick">
                                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                        <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                        <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                                    </g>
                                </svg>
                                <h5 className="themeclr text-center mt-4 mb-2">All done</h5>
                                <p className="themeclr text-center">you withdraw 1.0034 DEX</p>
                            </div>
                           
                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => props.onDismiss()}>Ok, Close</button>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Withdrawmodals;