import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import toast, { Toaster } from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown, MdSwapHoriz, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaArrowDown } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { Balance } from "../../hooks/useErc20";
import { UnStake, Deposit } from "../../hooks/useMasterChef";
import { isEmpty } from "../../Lib/isEmpty";
import { toFixedNumber } from "../../Lib/FixedNumber";


function Unstaketokenmodal(props) {
    const [unstaketokemodals, setUnstaketokemodals] = useState(true);
    const [stakemodal, setStakemodal] = useState(true);
    const [data, setData] = useState();
    const [bal, setBal] = useState();
    const [unstakeValue, setUnstakeValue] = useState("");
    const [pending, setPending] = useState(false);
    useEffect(() => {
        console.log("unstakemodal", props);
        setData(props?.datas);
        getBalance();
      });
      const getBalance = async () => {
        var bal = await Balance(data?.lpAddress);
        console.log("bal", bal);
        setBal(bal);
      };
      const Max = () => {
        setUnstakeValue(bal);
      };


  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "unstakeValue") {
      var numbers = /^\d+((.)|(.\d{0,30})?)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false;
      }
      setUnstakeValue(value);
    }
  };

  const unstaking = async () => {
    setPending(true);
    console.log(
      "parseFloat(unstakeValue*10**18).toFixed(0) : ",
      parseFloat(unstakeValue * 10 ** 18).toFixed(0)
    );
    var res = await UnStake(
      data?.pid,
      toFixedNumber(parseFloat(unstakeValue * 10 ** 18).toFixed(0))
    );
    console.log("res", res);
    if (res == true) {
      toast.success(
        `Your ${data?.lpSymbol} earnings have also been unstaked to your wallet!`
      );
      props.onHide();
      setPending(false);
    } else {
      toast.error("Try Again!");
    }
  };
    return (
        <>
            <div className='walletmodal'>
                <Modal show={unstaketokemodals} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Unstake LP tokens</h5>
                        <div className="swap__blueBox rounded-3 py-3 px-3">
                           <div className="d-flex justify-content-between">
                               <p className="themeclr fw-600 mb-1">Unstake</p>
                               <p className="themeclr fw-600">Balance :{bal}</p>
                           </div>
                           <div className="d-flex justify-content-between align-items-center">
                               <input className="form-control fset ps-0" placeholder="0.00"
                                id="tokenAval"
                               value={
                                isEmpty(unstakeValue) && isNaN(unstakeValue)
                                  ? 0.0
                                  : unstakeValue
                              }
                              name="unstakeValue"
                              onChange={(e) => {
                                onChange(e);
                              }}
                               ></input>
                               <div className="d-flex align-items-center gap-2">
                                 <button className="btn btn-brand-1 py-2  hover-up"
                                  onClick={() => {
                                    Max();
                                  }}>{" "}
                                  Max{" "}</button>
                                 <h6 className="mb-0"> {data?.lpSymbol}</h6>
                               </div>
                           </div>
                        </div>

                        <div className="mt-4 d-flex justify-content-center gap-3">
                          <button className="btn btn-brand-1 sitebtn  hover-up"
                onClick={props.onDismiss}
                          
                          >Cancel</button>
                          <button className="btn btn-brand-1 sitebtn  hover-up"
                          onClick={() => {
                            unstaking();
                          }}
                          disabled={unstakeValue == 0}
                          >
                {pending == true ? "Confirming..." : "Confirm"}

                          </button>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>

        </>
    )
}

export default Unstaketokenmodal;