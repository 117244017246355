import React, { useState, useEffect } from "react";
import Innerheader from "../../Layouts/innerheader";
import Footer from "../../Layouts/footer";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import "../../Karthick.css";
import "../../inner.css";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import CoinTokenSupply from "./cointokensupply";
import Companydetail from "./companydetail";
import Socialmedia from "./socialmedial";
import Projectsubmitter from "./projectsubmitter";
import Projectteam from "./projectteam";
import Swal from 'sweetalert2';
import Liquidity from "./liquiditysupply";
import Submitrequest from "./submitrequest";
import Blockchainsubmission from "./blockchainsubmission";
import Websiteurl from "./websiteurl";
import Whitepaper from "./whitepaper";
import Blockchainplatform from "./blockchainplatform";
import Auditdetail from "./auditdetail";
import Sourcecode from "./sourcecode";
import Projectscore from "./projectscore";
import Congrats from "./congrats";

const Allcointokenform = () => {

    const [steps, setSteps] = useState(1);
    const [yes, setYes] = useState(false);
    const [yestwo, setYestwo] = useState(true);
    const [yesthree, setYesthree] = useState(true);
    const [yesfour, setYesfour] = useState(false);
    const [yesfive, setYesfive] = useState(true);
    const [yessix, setYessix] = useState(true);
    const [addimg, setAddimg] = useState("");
    const [addimgtwo, setAddimgtwo] = useState("");
    const [btndisable, setBtndisable] = useState(true);

    const [filterRows, setFilterRows] = useState([{ filters: "" }]);
    const addFilters = () => {
        setFilterRows([...filterRows, { filters: "" }]);
    };
    const removeFilters = (index) => {
        const list = [...filterRows]
        list.splice(index, 1)
        setFilterRows(list)
    };

    const [filterRows1, setFilterRows1] = useState([{ filters: "" }]);
    const addFilters1 = () => {
        setFilterRows1([...filterRows1, { filters: "" }]);
    };
    const removeFilters1 = (index) => {
        const list = [...filterRows1]
        list.splice(index, 1)
        setFilterRows1(list)
    };

    const [filterRows2, setFilterRows2] = useState([{ filters: "" }]);
    const addFilters2 = () => {
        setFilterRows2([...filterRows2, { filters: "" }]);
    };
    const removeFilters2 = (index) => {
        const list = [...filterRows2]
        list.splice(index, 1)
        setFilterRows2(list)
    };

    const yesfourfunc = () => {
        setYesfour(!yesfour);
        setYesthree(!yesthree);
    }
    const imgchange = (e) => {
        setAddimg(URL.createObjectURL(e.target.files[0]));
    }
    const imgchangetwo = (e) => {
        setAddimgtwo(URL.createObjectURL(e.target.files[0]));
    }

    const scoretablehead = ["Total Score", "Percentage", "Investment Interpretation"];
    const [scoretable, setScoretable] = useState([
        {
            tscore: "0 – 25.0",
            percent: "0 – 20",
            invest: "Bad Investment"
        },
        {
            tscore: "25.1 - 50.0",
            percent: "21 – 40",
            invest: "Cautious Investment"
        },
        {
            tscore: "50.1 – 75.0",
            percent: "41 – 60",
            invest: "Good Investment"
        },
        {
            tscore: "75.1 – 100.0",
            percent: "61 – 80",
            invest: "Very Good Investment"
        },
        {
            tscore: "100.1 – 125.0",
            percent: "81 – 100",
            invest: "Awesome Investment"
        }
    ]);

    const submitlist = () => {
        Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Submitted Succesfully",
            showConfirmButton: false,
            timer: 3500
        });
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [steps]);

    return (
        <>
            <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />

                    <div className="cointoken">
                        {steps == 1 &&
                            <>
                                <div className="submitrequest">
                                    <div className="formbox formboxcenter">
                                        <Submitrequest path="/cointoken" />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(2)}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>}

                        {steps == 2 &&
                            <>
                                <div className="submitrequest">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(1)}>Back</button>
                                        </div>
                                        <div className="text-center">
                                            <h6 class="formhead">Accepts Legal Requirements</h6>
                                            <p className="content">
                                                Please, review all the legal requirements before you proceed further
                                            </p>
                                        </div>
                                        <Form>
                                            <div>
                                                <div className="d-flex align-items-start mt-5">
                                                    <Form.Check type="checkbox" onClick={() => setBtndisable(!btndisable)}></Form.Check>
                                                    <Form.Label>
                                                        <span className="content d-block">
                                                            I have read and I agree to all the <Link to="/" className="content noteblue">Terms of Use, Disclaimers, Listing Terms and Conditions, Privacy Policy</Link>, and all legal requirements of EDA
                                                        </span>
                                                    </Form.Label>
                                                </div>
                                            </div>
                                            <div className="mt-5 text-center">
                                                <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" disabled={btndisable} onClick={() => setSteps(3)}>Next</button>
                                            </div>
                                        </Form>
                                    </div></div>
                            </>
                        }

                        {steps == 3 &&
                            <>
                                <div className="submissionform">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(2)}>Back</button>
                                        </div>
                                        <div className="text-center mb-3">
                                            <h6 class="formhead">Submission Form <span className="d-block mt-1">(Coin or Token)</span></h6>
                                            <p className="content">
                                                Please, fill in the details. Note that EdaFace automated system will verify and grade your Project before submission.
                                            </p>
                                        </div>
                                        <Form.Label>Coin/Token Name<span className="text-danger">*</span></Form.Label>
                                        <InputGroup className="mb-4">
                                            <InputGroup.Text><GoMail /></InputGroup.Text>
                                            <Form.Control type="text" placeholder="Eda Token"></Form.Control>
                                        </InputGroup>
                                        <Form.Label>Coin/Token Symbol<span className="text-danger">*</span></Form.Label>
                                        <InputGroup className="mb-4">
                                            <InputGroup.Text><GoMail /></InputGroup.Text>
                                            <Form.Control type="text" placeholder="$EDA"></Form.Control>
                                        </InputGroup>
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(4)} >Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {steps == 4 &&
                            <>
                                <div className="submissionform">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(3)}>Back</button>
                                        </div>
                                        <div className="text-center mb-3">
                                            <h6 class="formhead">Submission Form <span className="d-block mt-1">(Coin or Token)</span></h6>
                                            <p className="content">
                                                Please, fill in the details. Note that EdaFace automated system will verify and grade your Project before submission.
                                            </p>
                                        </div>
                                        <Blockchainsubmission path="/cointoken" />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(5)} >Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {steps == 5 &&
                            <>
                                <div className="websiteurl">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(4)}>Back</button>
                                        </div>
                                        <div className="text-center">
                                            <h6 class="formhead">Website URL</h6>
                                            <p className="content">
                                                Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                                            </p>
                                        </div>
                                        <Websiteurl />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(6)} >Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {steps == 6 &&
                            <>
                                <div className="websiteurl">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(5)}>Back</button>
                                        </div>
                                        <Whitepaper />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(7)} >Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {steps == 7 &&
                            <>
                                <div className="websiteurl">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(6)}>Back</button>
                                        </div>
                                        <div className="text-center">
                                            <h6 class="formhead">Blockchain details</h6>
                                            <p className="content">
                                                What blockchain platform is your coin
                                                <span className="content noteblue d-block">
                                                    (if it is a multichain coin/token, Blockchain Platform 1 will be taken as the Primary Chain)
                                                </span>

                                            </p>
                                        </div>

                                        <Blockchainplatform />

                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(8)}>Next</button>
                                        </div>
                                    </div></div>
                            </>}

                        {steps == 8 &&
                            <>
                                <div className="formbox">
                                    <div className="backbtn">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(7)}>Back</button>
                                    </div>
                                    <CoinTokenSupply />
                                    <div className="mt-4 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(9)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }
                        {steps == 9 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(8)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">MARKET VALUE</h6>
                                    {/* <p className="content">
                                        Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                                    </p> */}
                                </div>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Listed Centralised Exchanges<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Centralised Exchanges"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Listed Decentralised Exchanges</Form.Label>
                                        <Form.Control type="text" placeholder="Decentralised Exchanges"></Form.Control>
                                    </Form.Group>
                                    <div className="mt-4 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(10)}>Next</button>
                                    </div>
                                </Form>
                            </div>
                        }
                        {steps == 10 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(9)}>Back</button>
                                </div>
                                <Liquidity path="/cointoken" />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(11)}>Next</button>
                                </div>
                            </div>
                        }
                        {steps == 11 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(10)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Ownership Wallet</h6>
                                </div>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Owner’s wallet url<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Insert Owner’s wallet url"></Form.Control>
                                        <span className="d-block mt-3 text-end">(Or)</span>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Contract Address<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="5435ghugyrtufjgvgilu897"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label className="mb-3">Coin/Token Renunciation<span className="text-danger">*</span></Form.Label>
                                        <Row>
                                            <Col xs={12} md={3} lg={3} className="mb-3 mb-sm-0">
                                                <Form.Check type="checkbox" label="Yes" onClick={() => setYestwo(!yestwo)}></Form.Check>
                                            </Col>
                                            <Col xs={12} md={3} lg={3}>
                                                <Form.Check type="checkbox" label="No"></Form.Check>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    {yestwo == false &&
                                        <Form.Group>
                                            <Form.Label>provide the ownership renunciation URL<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Insert Owner’s wallet url"></Form.Control>
                                        </Form.Group>
                                    }
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(12)} disabled={yestwo}>Next</button>
                                    </div>
                                </Form>
                            </div>
                        }
                        {steps == 12 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(11)}>Back</button>
                                </div>
                                <Auditdetail />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(13)} >Next</button>
                                </div>
                            </div>
                        }
                        {steps == 13 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(12)}>Back</button>
                                </div>
                                <Sourcecode />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(14)}>Next</button>
                                </div>
                            </div>
                        }
                        {steps == 14 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(13)}>Back</button>
                                </div>
                                <Projectteam />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(15)} >Next</button>
                                </div>
                            </div>
                        }
                        {steps == 15 &&
                            <div className="formbox companydet">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(14)}>Back</button>
                                </div>
                                <Companydetail />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(16)}>Next</button>
                                </div>
                            </div>
                        }
                        {steps == 16 &&
                            <div className="formbox">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(15)}>Back</button>
                                </div>
                                <Socialmedia />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(17)}>Next</button>
                                </div>
                            </div>
                        }
                        {steps == 17 &&
                            <div className="formbox companydet projectsubmitter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(16)}>Back</button>
                                </div>
                                <Projectsubmitter />
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(18)}>Next</button>
                                </div>
                            </div>
                        }
                        {steps == 18 &&
                            <>
                                <h3 className="h3">Your Project scores a total of <span className="t-orange">28%</span>.</h3>
                                <div className="formbox projectscore mt-3 mt-sm-5">
                                    <div className="backbtn mb-5">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(17)}>Back</button>
                                    </div>
                                    <Projectscore />
                                    <div className="mt-4 mb-3 text-center">
                                        <button type="button" className="btn txtbtn" onClick={() => setSteps(19)}>
                                            (Please, Click here to improve your score and list on EdaFace)
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                        {steps == 19 &&
                            <>
                                <h3 className="h3">Your Project scores a total of <span className="t-orange">40%</span>.</h3>
                                <div className="formbox congratstop mt-3 mt-sm-5">
                                    <div className="backbtn mb-5">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(18)}>Back</button>
                                    </div>
                                <Congrats/>
                                    <div className="mt-5 mb-3 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(20)}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                        {steps == 20 &&
                            <>
                                <h3 className="h3">Project submission</h3>
                                <div className="formbox projectsubmit mt-3 mt-sm-5">
                                    <h3 className="h3">Please, tick:</h3>
                                    <Form className="mb-3 mb-sm-5 pb-3">
                                        <Form.Group className="mb-4">
                                            <Form.Check type="checkbox" label="All the information I have provided are accurate and true. Any misrepresentation may result in  delisting my Project and legal action."></Form.Check>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check type="checkbox" label="Since EdaFace Listing Platforms are decentralized and automated, once you successfully click  the Submit Button, your Project will automatically become live on EdaFace, and receive
                                            Prelim Verif status that will be confirmed later in accordance with EdaFace Compliance Grading."></Form.Check>
                                        </Form.Group>
                                    </Form>
                                    <div className="mt-3 mb-3 text-center  d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn me-0 me-sm-3" onClick={() => setSteps(1)}>
                                            Make Correction
                                        </button>
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn mt-3 mt-sm-0" onClick={submitlist}>
                                            Submit Now
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Container>
            </Container>
            <Footer />
        </>
    )
}

export default Allcointokenform;