import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import Repaymodals from "./Modals/repaymodal";
import Borrowmodals from "./Modals/borrowmodal";


function Supplytable() {

    const [repaymodal, setRepaymodal] = useState(false);
    const [borrowmodal, setBorrowmodal] = useState(false);
    const [checked, setChecked] = useState(true);
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, names: "ETH", balance: "1.0", apy: "0.01%", tick: Images.tick,
        },
        {
            id: 2, image: Images.dai, names: "DAI", balance: "1.3", apy: "0.01%", tick: Images.tick,
        },
    ]);
    return (
        <>
      {repaymodal && <Repaymodals onDismiss={()=>setRepaymodal(false)}/>}
      {borrowmodal && <Borrowmodals onDismiss={()=>setBorrowmodal(false)}/>}
        <div className="supplytable mt-3">
            <div className="d-flex gap-3 pt-2">
                <p className="themeclr stab">Balance <span className="fw-600">$1.03</span></p>
                <p className="themeclr stab">APY <span className="fw-600">$1.03</span></p>
                <p className="themeclr stab">Borrow Power Used <span className="fw-600">$1.03</span></p>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Assets <FaSort /></th>
                        <th>Debt <FaSort /></th>
                        <th>APY <FaSort /></th>
                        <th>APY Type <FaSort /></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="t_body">
                    {coinlists.map((e, i) =>
                        <tr>
                            <td className="pos"><img src={e.image} className="img-fluid wbox" alt="coinimg"/><span className="themeclr ms-2">{e.names}</span></td>
                            <td><p className="themeclr mb-0">{e.balance}</p></td>
                            <td><p className="themeclr mb-0">{e.apy}</p></td>
                            <td>
                                <Dropdown>
                                    <DropdownToggle>Variable</DropdownToggle>
                                    <DropdownMenu>
                                       <DropdownItem>Variable</DropdownItem>
                                        <DropdownItem>Stable</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-brand-1 sitebtn hover-up me-2" onClick={()=>setRepaymodal(true)}>Repay</button>
                                    <button className="supplybtn"  onClick={()=>setBorrowmodal(true)}>Borrow</button>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
        </>
    )
}

export default Supplytable;