import React, { useState, useEffect } from "react";
import { Form, Table, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../../Karthick.css";
import "../../inner.css";
import Images from "../../Components/Images";
import { IoMdSwap } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Slider } from 'rsuite';
// import "rsuite/dist/rsuite.min.css";
import 'rsuite/dist/rsuite-no-reset.min.css';

function Buysell(props) {

    const [alllimit, setAlllimit] = useState("limit");
    const [swaps, setSwaps] = useState(true);
    const [advanced, setAdvanced] = useState(true);

    const [selectedtimeforce, setSelectedtimeforce] = useState('Good Til Time');
    const handleSelecttimeforce = (eventKey) => {
        setSelectedtimeforce(eventKey);
    };
    const [selectedtime, setSelectedtime] = useState('Days');
    const handleSelecttime = (eventKey) => {
        setSelectedtime(eventKey);
    };
 
    const [selectedlimit, setSelectedlimit] = useState();
    // const handleSelectlimit = (eventKey) => {
    //     setSelectedlimit(eventKey);
    // };
    const [valueslide, setValueslide] = useState(0);
    // const [value1, setValue1] = useState(1);
    // const handleStyle1 = {
    //     color: '#fff',
    //     fontSize: 12,
    //     width: 32,
    //     height: 22
    // };
    // const labels1 = ['1x', '5x', '10x', '15x', '20x'];

    return (

        <>

            <ul className="listtab syn_limits d-flex align-items-center gap-3 mt-4">
                <li className={alllimit == "limit" ? "active" : ""} onClick={() => setAlllimit("limit")}>Limit</li>
                <li className={alllimit == "market" ? "active" : ""} onClick={() => setAlllimit("market")}>Market</li>
                <Dropdown className="drop_no">
                    <DropdownToggle className={(alllimit == "Stop Limit" || alllimit == "Stop Market" || alllimit == "Take Profit Limit"|| alllimit == "Take Profit Market") ? "active" : ""}>{(alllimit == "market" || alllimit == "limit") ?"Stop Limit" : alllimit}</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem  onClick={()=>setAlllimit("Stop Limit")}>Stop Limit</DropdownItem>
                        <DropdownItem  onClick={()=>setAlllimit("Stop Market")}>Stop Market</DropdownItem>
                        <DropdownItem  onClick={()=>setAlllimit("Take Profit Limit")}>Take Profit Limit</DropdownItem>
                        <DropdownItem  onClick={()=>setAlllimit("Take Profit Market")}>Take Profit Market</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </ul>

            {(alllimit != "market" && alllimit != "limit") && 
             <div className="swap__blueBox p-2 p-sm-3 rounded-4 d-flex align-items-center mt-3">
                    <div>
                        <h6 class="themeclr fw-600 mb-0">Trigger Price (USD)</h6>
                        <input type="text" class="form-control swap_input ps-0" placeholder="$0.00"></input>
                    </div>              
                </div>}

            {(alllimit != "market" && alllimit != "Stop Market"&& alllimit != "Take Profit Market") &&
                <div className="swap__blueBox p-2 p-sm-3 rounded-4 d-flex align-items-center justify-content-between mt-3">
                    <div>
                        <h6 class="themeclr fw-600 mb-0">Limit Price (USD)</h6>
                        <input type="text" class="form-control swap_input ps-0" placeholder="0.00"></input>
                    </div>
                    <button className="btn btn-brand-1 hover-up py-2">Mid</button>
                </div>}
            <div className="swap__blueBox p-2 p-sm-3 rounded-4 d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h6 class="themeclr fw-600 mb-0">Amount {swaps ? "(ETH)" : "(USD)"}</h6>
                    <input type="text" class="form-control swap_input ps-0" placeholder="0.000"></input>
                </div>
                <button className="btn btn-brand-1 hover-up py-2" onClick={() => setSwaps(!swaps)}><IoMdSwap /></button>
            </div>
           
           
            {alllimit == "market" &&
            <>
            <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className={props.tab == "buy" ? "w-100 greens":"w-100 reds"}>
                        <h6 class="themeclr fw-600 mb-3">Leverage</h6>
                        <Slider
                            min={0}
                            max={200}
                            progress
                            value={valueslide*10}
                            onChange={value => {
                                setValueslide(value/10);
                            }}
                        />
                    </div>
                    <div class="d-flex align-items-center group_input">
                        <input type="text" class="form-control swap_input slide_input text-end ps-0 pe-1" placeholder="0.00" onChange={(e) => {setValueslide( Number(e.target.value)>20 ?  "20" :  e.target.value)}} value={valueslide} inputmode="numeric" />
                        <div class="input-group-append">
                            <span class="input-group-text ps-0">X</span>
                        </div>
                    </div>

                </div>

            </div> 
                <div className="d-flex gap-2 justify-content-between nox mt-3 px-1">
                <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslide(1*1)}>1x</button>
                <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslide(2*1)}>2x</button>
                <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslide(3*1)}>3x</button>
                <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslide(5*1)}>5x</button>
                <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslide(10*1)}>10x</button>
              </div>
            
            </>
            }

        

            <h6 className="themeclr fw-600 mt-3 cursor" onClick={() => setAdvanced(!advanced)}>Advanced  {advanced ? <IoIosArrowDown /> : <IoIosArrowUp />}</h6>

            {advanced ?
                <>
                {alllimit != "market" &&
                    <div className="d-block d-sm-flex gap-3">
                        <div className="swap__blueBox p-2 p-sm-3 p-md-2 p-xl-3 rounded-4 mt-3">
                            <h6 class="orgclr fw-600 mb-2">Time in Force</h6>
                            <Dropdown className="drop_no" onSelect={handleSelecttimeforce}>
                                <DropdownToggle>{selectedtimeforce}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem eventKey="Good Til Time">Good Til Time</DropdownItem>
                                    <DropdownItem eventKey="Immediate Or Cancel">Immediate Or Cancel</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="swap__blueBox p-2 p-sm-3 p-md-2 p-xl-3 rounded-4 mt-3">
                            <h6 class="orgclr fw-600 mb-0">Time</h6>
                            <div className="d-flex align-items-center">
                                <input type="text" class="form-control swap_input days_input ps-0" placeholder="28"></input>
                                <Dropdown className="drop_no" onSelect={handleSelecttime}>
                                    <DropdownToggle>{selectedtime}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem eventKey="Mins">Mins</DropdownItem>
                                        <DropdownItem eventKey="Hours">Hours</DropdownItem>
                                        <DropdownItem eventKey="Days">Days</DropdownItem>
                                        <DropdownItem eventKey="Weeks">Weeks</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown></div>
                        </div>
                    </div>}
                    <div class="allcheck form-check mt-3">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                            Reduce Only
                        </label>
                    </div>
                    {alllimit != "market" &&
                    <div class="allcheck form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                        <label class="form-check-label" for="flexCheckDefault">
                            Post Only
                        </label>
                    </div>}
                </>
                : <></>}

            <div className="fundclr rounded-4 mt-3 px-3 py-3 mt-2">
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="themeclr fw-500 mb-3">Expected Price</h6>
                    <h6 class="themeclr fw-500 mb-3">-</h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="themeclr fw-500 mb-3">Liquidation Price</h6>
                    <h6 class="themeclr fw-500 mb-3">-</h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="themeclr fw-500 mb-3">Position Margin</h6>
                    <h6 class="themeclr fw-500 mb-3">-</h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="themeclr fw-500 mb-3">Position Leverage</h6>
                    <h6 class="themeclr fw-500 mb-3">-</h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="themeclr fw-500 mb-3">Fee</h6>
                    <h6 class="themeclr fw-500 mb-3">-</h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="themeclr fw-500 mb-3">Estimated Rewards</h6>
                    <h6 class="themeclr fw-500 mb-3">-</h6>
                </div>
            </div>
            <button class="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Enter Amount</button>


        </>

    )
}

export default Buysell;