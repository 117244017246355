import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";


const Projectsubmitter = () => {
    
    const [addimgtwo, setAddimgtwo] = useState("");
    const imgchangetwo = (e) => {
        setAddimgtwo(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <>
            <div className="text-center">
                <h6 className="formhead">Project Submitter</h6>
                <p className="content">
                    The Project Submitter is Person legally authorised to submit the Project. The person submitting this Project application to EdaFace Listing Platforms for approval must be  either an Official Member of the Company or an Authorized Representative with permission to  represent the project and information given in this application. Proof of your involvement and  permission to represent the company and submit this application will be required.
                </p>
            </div>
            <Form>
                <Row className="justify-content-between">
                    <Col xs={12} md={12} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Name of Project Submitter<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Name of Project Submitter"></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Gender of Project Submitter<span className="text-danger">*</span></Form.Label>
                                    <Form.Select className="form-control">
                                        <option value="1">male</option>
                                        <option value="2">female</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>National Identification<span className="text-danger">*</span></Form.Label>
                                    <div className="oropt">
                                        <Form.Control type="text" placeholder="National Identification"></Form.Control>
                                        <p className="content">(or)</p>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Passport number of Project Submitter<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Passport number of Project Submitter"></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Upload a Proof of National Identification<span className="text-danger">*</span></Form.Label>
                                    <div className="oropt">
                                        <Form.Control type="text" placeholder="Upload"></Form.Control>
                                        <p className="content">(or)</p>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Passport number<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Passport number"></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Country where National Identification<span className="text-danger">*</span></Form.Label>
                                    <div className="oropt">
                                        <Form.Control type="text" placeholder="National Identification"></Form.Control>
                                        <p className="content">(or)</p>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Passport<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Passport"></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Telegram Link of Project Submitter<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Telegram link"></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3 pe-sm-4">
                                    <Form.Label>Please describe your involvement with the Company<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Founder/Team Member"></Form.Control>
                                    <p className="content mt-2 text-black">
                                        If your involvement is not listed please specify your role
                                    </p>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Current Physical address of Project Submitter<span className="text-danger">*</span></Form.Label>
                            <Form.Control as="textarea" placeholder="Physical address"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Upload a Proof of Current Physical Address<span className="text-danger">*</span></Form.Label>
                            <Form.Control as="textarea" placeholder="(acceptable documents include: bank statement, utility bill, etc.)"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-3">
                                Upload a Proof of Authorisation Letter from the Company that shows you have the  authorization to submit this Project
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="flexbox up align-items-start">
                                <div className="addimg">
                                    <input type="file" onChange={imgchangetwo} accept="image/*" className="form-control" />
                                    <div className="addimgbox">
                                        Upload
                                    </div>
                                </div>
                                <div>
                                    <p className="content text-black">
                                        Note your primary means of communication with EdaFace will be via the company’s email indicated
                                    </p>
                                </div>
                            </div>
                            <img src={addimgtwo} alt="Member" className="img-fluid memberimg" />
                        </Form.Group>
                    </Col>
                </Row>

            </Form>

        </>
    )
}

export default Projectsubmitter;