import React, { useState, useEffect } from 'react';
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaSort } from 'react-icons/fa6';

function Positions() {

    return (
        <div className='text-center'>
         
            <div className="price_table w-100 overauto">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Coin <FaSort/></th>
                            <th>Position (QTY/USDT) <FaSort/></th>
                            <th>Index Price(USDT) <FaSort/></th>
                            <th>Entry Price(USDT) <FaSort/></th>
                            <th>PNL(USDT) <FaSort/></th>
                            <th>Est. Liq. Price (USDT) </th>
                            <th>Action</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>                           
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default Positions;