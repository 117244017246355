import React, { useState, useEffect } from 'react';
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";

function Transfersettings(props) {
    const [transfersetting, setTransfersetting] = useState(true);
    return (
        <>
            <div className='walletmodal'>
                <Modal show={transfersetting} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>

                        <h5 className="themeclr fw-600 mb-4">Advanced Settings</h5>

                        <div className="d-flex justify-content-between"><p className="blkclr mb-0">Gas on destination</p><p className="themeclr fw-600 mb-0">$0.00</p></div>
                        <hr></hr>
                        <div class="d-flex align-items-center defaultall gap-3 mb-4"><button class="btn bbtn rounded-3 px-3">None</button><button class="btn bbtn rounded-3 px-3">Medium</button><button class="btn bbtn rounded-3 px-3">Max</button></div>

                        <div class="pos">
                            <input type="text" class="form-control input_spot text-end fw-500" placeholder='0' />
                            <img src={Images.eth} className='img-fluid ethimg p_pos'/>
                            <p class="net_pos themeclr mb-0 fw-500 px-2">Max</p>
                        </div>


                        <div className="d-flex justify-content-between mt-4"><p className="blkclr mb-0">Slippage tolerance</p><p className="themeclr fw-600 mb-0">$5.00</p></div>
                        <hr></hr>
                        <div class="d-flex align-items-center defaultall gap-3 mb-4"><button class="btn tbtn rounded-3 px-3">0.5%</button><button class="btn tbtn rounded-3 px-3">1.00%</button><button class="btn tbtn rounded-3 px-3">5.00%</button></div>

                        <div class="pos">
                            <input type="text" class="form-control input_spot text-start fw-500" placeholder='0.00' value="5.00" />
                            
                            <p class="net_pos themeclr mb-0 fw-500 px-2">%</p>
                        </div>
                        <p className='orgclr text-end f-14'>YOU MAY PAY HIGH FEES</p>

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Transfersettings;