import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../../Components/Images";
import { IoClose } from "react-icons/io5";


function Depositmodals(props) {
    const [syndepositmodal, setSyndepositmodal] = useState(true);

    const [selectchain, setSelectchain] = useState(
        { id: 1, image: Images.coin, coinname: "Coinbase" }
    )
    const [chainslist, setChainslist] = useState([
        {
            id: 1, image: Images.eth, coinname: "WETH"
        },
        {
            id: 2, image: Images.bnb, coinname: "Binance"
        },
        {
            id: 3, image: Images.dai, coinname: "DAI"
        },
    ])

    return (
        <>
            <div className='walletmodal'>

                <Modal show={syndepositmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr">Deposit</h5>
                        <p>USDC deposits from select chains have the lowest fees. Other deposits may have additional third-party fees.</p>
                         
                            <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100">
                                <h6 className="themeclr fw-600 mb-2">Sources</h6>
                                <Dropdown className="no_drop dest_drop">
                                    <DropdownToggle><img src={selectchain.image} className="ethimg" /> {selectchain.coinname}</DropdownToggle>
                                    <DropdownMenu>
                                        <div className="pos mb-3">
                                            <input type="search" className="form-control token_input" placeholder="Search" />
                                            <img src={Images.searchicon} className="img-fluid search" />
                                        </div>
                                        <div className="height_set">
                                            <h6>Exchange</h6>
                                            <DropdownItem><img src={Images.coin} className="ethimg" /> Coinbase</DropdownItem>
                                            <h6 className="mt-2">Chains</h6>
                                            {chainslist.map((e, i) =>
                                                <DropdownItem onClick={() => setSelectchain(e)}><img src={e.image} className="ethimg" /> {e.coinname}</DropdownItem>)}
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                        <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100 mt-2">
                            <h6 className="themeclr fw-600 mb-2">Assets</h6>
                            <Dropdown className="no_drop dest_drop">
                                <DropdownToggle><img src={Images.usdc} className="ethimg" /> USDC</DropdownToggle>
                                <DropdownMenu>
                                    <div className="">
                                        <h6 className="mt-2">Assets</h6>
                                        <DropdownItem><img src={Images.usdc} className="ethimg" /> USDC</DropdownItem>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="rounded-3 py-2 px-2 mt-0">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-2">Swap</h6><h6 class="themeclr fw-500  mb-2">USDC → USDC</h6></div>
                        </div>
                        <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100 mt-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <h6 className="themeclr fw-600 mb-0">Amount</h6>
                                    <input type="text" className="form-control swap_input ps-0" placeholder="0.00" />
                                </div>
                                <button class="btn btn-brand-1 sitebtn hover-up py-1">Max</button></div>
                        </div>

                        <div className="rounded-3 py-2 px-2 mt-0">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-2">Available (USDC)</h6><h6 class="themeclr fw-500  mb-2">0.000</h6></div>
                        </div>

                        <div className="fundclr rounded-3 py-2 px-2 mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Expected Deposit Amount (USDC)</h6><h6 class="themeclr fw-500  mb-3">-</h6></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Equity (USDC)</h6><h6 class="themeclr fw-500  mb-3"> -</h6></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Buying Power (USDC)</h6><h6 class="themeclr fw-500  mb-3">-</h6></div>
                                <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Max Slippage</h6><h6 class="themeclr fw-500  mb-3">0.00%</h6></div>
                                <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Estimated Time</h6><h6 class="themeclr fw-500  mb-3">30 Minutes</h6></div>
                        </div>

                        <button class="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Deposit Funds</button>

                    </Modal.Body>



                </Modal>
            </div>
        </>
    )
}

export default Depositmodals