import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";


const CoinTokenSupply = () => {

    return (
        <>
           
                <div className="text-center">
                    <h6 className="formhead">Coin/Token Supply</h6>
                    <p className="content">
                        Lorem ipsum dolor sit amet consectetur. Amet placerat varius sit aliquet a pellentesque posuere. Sed pulvinar molestie in purus sit congue. Massa nulla condimentum enim turpis viverra sit at.
                    </p>
                </div>
                <Form>
                    <Row className="justify-content-between">
                        <Col xs={12} md={12} lg={6} xl={5}>
                            <Form.Group className="mb-3">
                                <Form.Label>Maximum Supply</Form.Label>
                                <Form.Control type="text" placeholder="Maximum Supply of your coin/token"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Burnt Amount</Form.Label>
                                <Form.Control type="text" placeholder="How much coin/token has been burnt"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Total Supply <span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Total Supply "></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Locked Amount <span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Locked amount"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Amount of coins/tokens <span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="amount of coins"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>The amount of Team allocation</Form.Label>
                                <Form.Control type="text" placeholder="Amount of team allocation"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Amount of any other coins/tokens</Form.Label>
                                <Form.Control type="text" placeholder="amount of any other coins"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Total Locked Amount</Form.Label>
                                <Form.Control type="text" placeholder="Total Locked Amount"></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={12} lg={6} xl={5}>
                            <Form.Group className="mb-3">
                                <Form.Label>blockchain URL</Form.Label>
                                <Form.Control type="text" placeholder="Supply the blockchain URL"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>blockchain URL</Form.Label>
                                <Form.Control type="text" placeholder="Supply the blockchain URL"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Unknown Maximum Coin Supply</Form.Label>
                                <Form.Control type="text" placeholder="Maximum coin supply"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Unknown Maximum Coin Supply</Form.Label>
                                <Form.Control type="text" placeholder="Maximum coin supply"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>URL Link</Form.Label>
                                <Form.Control type="text" placeholder="Url Link"></Form.Control>
                                <span className="add"><GoPlus /></span>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>URL link or wallet address</Form.Label>
                                <Form.Control type="text" placeholder="url or wallet address"></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                 
                </Form>
        


        </>
    )
}

export default CoinTokenSupply;