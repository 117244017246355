import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import {
    Container,
    Dropdown,
    InputGroup,
    Form,
    Table,
    Row,
    Col,
} from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { RiSendPlaneFill } from "react-icons/ri";
import { FaUpload } from "react-icons/fa6";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from "react-icons/fa";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Countdown from 'react-countdown';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SweetAlert2 from 'react-sweetalert2';


function Chartpage(props) {
    const navigate = useNavigate();
    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return "waiting";
        } else {
            // Render a countdown
            return <span>{hours}:{minutes}:{seconds}</span>;
        }
    };
    const { swal, ...rest } = props;
    function handleClick(){
        swal.fire({
            title: 'Example',
            text: 'Swal injected',
            icon: 'success',
        });
    }
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <>

            <Innerheader />

            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-1 container_max px-xxl-3">
                    <img src={Images.backlogo} className="backlogo farms__backlogo" />
                    <div className="p2ptrade pt-2 pt-sm-4 pb-5">
                        <Container>
                        <div onClick={() => navigate(-1)}><button class="btn btn-brand-1 sitebtn hover-up mt-2 mb-4"><MdKeyboardArrowLeft className="set_icon mts"/>Back</button></div>

                            <div className="row">
                                <div className="col-xxl-5">
                                    <div className="netborder py-4 px-4">
                                    <h5 className="themeclr fw-600 text-center mb-5">Information</h5>
                                    <div className="d-flex gap-4">
                                        <div>
                                            <p className="themeclr fw-600 mb-1">Trade ID</p>
                                            <CopyToClipboard text="198909324">
                                                <div className="d-flex cursor">
                                                    <p className="blkclr fw-400 mb-1 me-2">198909324</p>
                                                    <FaRegCopy className='grnclr' /></div>
                                            </CopyToClipboard>
                                        </div>
                                        <div>
                                            <p className="themeclr fw-600 mb-1">Rate</p>
                                            <p className="blkclr fw-400 mb-1">1 BTC = 11.21 ETH</p>
                                        </div>
                                        <div>
                                            <p className="themeclr fw-600 mb-1">Started</p>
                                            <p className="blkclr fw-400 mb-1">May 04,2024</p>
                                        </div>
                                    </div>
                                    <div className="marketbor rounded-3 w-100 py-3 px-3 mt-3">
                                        <p className="themeclr mb-0"><BsFillInfoCircleFill /> Keep all conversation within the trade chart. Moderator won't be able assist you if something goes wrong outside of Edaface-DEX.</p>
                                    </div>
                                    <div className="text-center mt-2">
                                        <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3">
                                            <div>Paid</div>
                                            <div className="mt-2">Time Left :  <Countdown
                                                date={Date.now() + 500000}
                                                renderer={renderer}
                                            /></div>
                                        </button></div>

                                    <div className="d-flex justify-content-between mt-3">
                                        <Link to="/p2ptrade">
                                        <button class="btn sellbtn">Cancel trade</button>
                                        </Link>
                                        <p className="mb-0 orgclr fw-500">you haven't paid yet</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-xxl-7">
                                <div className="netborder py-4 px-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                        <img src={Images.eimg} className="img-fluid ethimg me-2" />
                                        <div>
                                            <p className="blkclr fw-600 mb-0">Lorem_ipsum</p>
                                            <p className="grayclr fw-500 f-14 mb-0">last seen 1 min ago</p>
                                        </div>
                                    </div>
                                    <Link to="/advertisedetail" className="themeclr fw-600">Partner Details</Link></div>
                                    <hr></hr>

                                    <div className="in_msg msg_wd px-4 py-3 mb-2 rounded-2">
                                        <p className="whtclr mb-0">Hi,<br></br>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                    </div>
                                    <div className="out_msg msg_wd px-4 py-3 mb-2 pos rounded-2">
                                        <p className="whtclr mb-0">It is a long established fact that a reader will be distracted by the readable content </p>
                                        {/* <svg class="rce-mbox-right-notch" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 0v20L20 0"></path></svg> */}
                                    </div>
                                    <div className="in_msg msg_wd px-4 py-3 mb-2 rounded-2">
                                        <p className="whtclr mb-0">Hi,<br></br>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                    </div>
                                    <div className="out_msg msg_wd px-4 py-3 mb-2 rounded-2">
                                        <p className="whtclr mb-0">It is a long established fact that a reader will be distracted by the readable content </p>
                                    </div>

                                    <div className="mt-4 d-flex gap-2">
                                        <div className="pos">
                                            <input type="file" onChange={handleChange} className="upload_input" />
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up upload_btns cursor"><FaUpload className="set_icon" /></button>
                                        </div>
                                        <input type="text" placeholder="Write a message" className="form-control input_spot" />
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up"><RiSendPlaneFill className="set_icon" /></button>
                                    </div>


                                </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Container>
            </Container>

            <Footer />

        </>
    )
}

export default Chartpage;