import bnbimg from '../Assets/images/bnb.png'

export const sleep = (ms) => {
  new Promise(resolve => setTimeout(resolve, ms))
}

export const testnetTokens = [
  {

    //BNB

    ContractAddress: {
      //bsc
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', //M
      97: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c', //T

      //Eth
      1: '', //M
      11155111: '0x15690Bc1eA3083c747A31927a641c0566bf86A10', //T

      //Avax
      43114: '', //M
      43113: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T
    },
    decimal: 18,
    // symbol: 'BNB',
    Symbol: {
      //bsc
      56: 'BNB', //M
      97: 'BNB', //T

      //Eth
      1: 'ETH', //M
      11155111: 'ETH', //T

      //Avax
      43114: 'AVAX', //M
      43113: 'AVAX', //T

      //Arbitrum
      42161: 'ETH', //M
      421614: 'ETH', //T

      //zkSync
      324: 'ETH', //M
      280: 'ETH', //T
    },
    // name: 'BNB',
    Name: {
      56: 'BNB', //M
      97: 'BNB', //T

      //Eth
      1: 'ETH', //M
      11155111: 'ETH', //T

      //Avax
      43114: 'AVAX', //M
      43113: 'AVAX', //T

      //Arbitrum
      42161: 'ETH', //M
      421614: 'ETH', //T

      //zkSync
      324: 'ETH', //M
      280: 'ETH', //T
    },
    image: bnbimg,
    commonBase: true,
    isCoin: true
  },
  {

    //WBNB
    ContractAddress: {
      //bsc
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', //M
      97: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c', //T

      //Eth
      1: '', //M
      11155111: '0x15690Bc1eA3083c747A31927a641c0566bf86A10', //T

      //Avax
      43114: '', //M
      43113: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T
    },
    decimal: 18,
    Symbol: {
      //bsc
      56: 'WBNB', //M
      97: 'WBNB', //T

      //Eth
      1: 'WETH', //M
      11155111: 'WETH', //T

      //Avax
      43114: 'WAVAX', //M
      43113: 'WAVAX', //T

      //Arbitrum
      42161: 'WETH', //M
      421614: 'WETH', //T

      //zkSync
      324: 'WETH', //M
      280: 'WETH', //T
    },
    // name: 'BNB',
    Name: {
      56: 'Wrapped BNB', //M
      97: 'Wrapped BNB', //T

      //Eth
      1: 'Wrapped ETH', //M
      11155111: 'Wrapped ETH', //T

      //Avax
      43114: 'Wrapped AVAX', //M
      43113: 'Wrapped AVAX', //T

      //Arbitrum
      42161: 'Wrapped ETH', //M
      421614: 'Wrapped ETH', //T

      //zkSync
      324: 'Wrapped ETH', //M
      280: 'Wrapped ETH', //T
    },
    // symbol: 'WBNB',
    // name: 'Wrapped BNB',
    image: bnbimg,
    commonBase: true,
    isCoin: true
  },
  {

    //BUSD
    ContractAddress: {
      //bsc
      56: '0x55d398326f99059fF775485246999027B3197955', //M
      97: '0x53E08EDFfe02811f7f6362FA1D7303eC9986114b', //T

      //Eth
      1: '', //M
      11155111: '0xf9b391Eef761F255Bcde2815fE615F5A1e8b0414', //T

      //Avax
      43114: '', //M
      43113: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T
    },
    decimal: 18,
    symbol: 'BUSD',
    name: 'Binance USD',
    image: bnbimg,
    commonBase: true
  },
  {

    //DSP
    ContractAddress: {
      //bsc
      56: '0xC40B73d75Ffc7d01987ec172D7f9931f39e6ab8e', //M
      97: '0x2a5dBA026e0eD509A6D05C802C9725E5B7C08E71',//'0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5', //T

      //Eth
      1: '', //M
      11155111: '0xE04619f4e1108762F6bE0Ed26c94A9590aD71552', //T

      //Avax
      43114: '', //M
      43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T
    },
    decimal: 18,
    symbol: 'EDA',
    name: 'EDA Token',
    image: bnbimg,
    commonBase: true
  },
  // {
  //   ContractAddress: {
  //     //bsc
  //     56: '', //M
  //     97: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E', //T

  //     //Eth
  //     1: '', //M
  //     11155111: '', //T

  //     //Avax
  //     43114: '', //M
  //     43113: '', //T

  //     //Arbitrum
  //     42161: '', //M
  //     421614: '', //T

  //     //zkSync
  //     324: '', //M
  //     280: '', //T
  //   },
  //   decimal: 18,
  //   symbol: 'DB',
  //   name: 'DOUB Token',
  //   image: bnbimg,
  //   commonBase: false
  // },
  // {
  //   ContractAddress: {
  //     //bsc
  //     56: '', //M
  //     97: '0x0A1F4c4E14Dd83018942c5813768A8bf70b78599', //T

  //     //Eth
  //     1: '', //M
  //     11155111: '', //T

  //     //Avax
  //     43114: '', //M
  //     43113: '', //T

  //     //Arbitrum
  //     42161: '', //M
  //     421614: '', //T

  //     //zkSync
  //     324: '', //M
  //     280: '', //T
  //   },
  //   decimal: 18,
  //   symbol: 'USDT',
  //   name: 'USDT Token',
  //   image: bnbimg,
  //   commonBase: false
  // },
  // {
  //   ContractAddress: {
  //     //bsc
  //     56: '', //M
  //     97: '0x3Da81833d01C55462d21744E792e47813F24F7c6', //T

  //     //Eth
  //     1: '', //M
  //     11155111: '', //T

  //     //Avax
  //     43114: '', //M
  //     43113: '', //T

  //     //Arbitrum
  //     42161: '', //M
  //     421614: '', //T

  //     //zkSync
  //     324: '', //M
  //     280: '', //T
  //   },
  //   decimal: 18,
  //   symbol: 'Garil',
  //   name: 'Garil Token',
  //   image: bnbimg,
  //   commonBase: true
  // },
  // {
  //   ContractAddress: {
  //     //bsc
  //     56: '', //M
  //     97: '0x440b481A1F65deA03CA80E370284D0aC457eA929', //T

  //     //Eth
  //     1: '', //M
  //     11155111: '', //T

  //     //Avax
  //     43114: '', //M
  //     43113: '', //T

  //     //Arbitrum
  //     42161: '', //M
  //     421614: '', //T

  //     //zkSync
  //     324: '', //M
  //     280: '', //T
  //   },
  //   decimal: 18,
  //   symbol: 'testB',
  //   name: 'testB Token',
  //   image: bnbimg,
  //   commonBase: false
  // }
]

// export const tokens = {
//   56: '',
//   97: testnetTokens
// }
export const tokens = testnetTokens