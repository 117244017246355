import React, { useState } from 'react';
import { Offcanvas, Modal } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown, MdSwapHoriz, MdOutlineKeyboardArrowUp } from "react-icons/md";
import Transfermodals from './Modals/transfermodal';
import { MdKeyboardArrowLeft } from "react-icons/md";
function Optionmodal(props) {

    const [modaloption, setModaloption] = useState(true);

    const [tradeoption, setTradeoption] = useState(1)
    const [tranfermodal, setTransfermodal] = useState(false);

    const [opentabss, setOpentabss] = useState("opentab");
    const [openlimit, setOpenlimit] = useState("limit");

    return (
        <>
            {tranfermodal && <Transfermodals onDismiss={() => setTransfermodal(false)} />}
            <div>
                <Offcanvas show={modaloption} placement='end' className="tradingoptionss">
                    <Offcanvas.Header closeButton onClick={() => props.onDismiss()}>
                        <Offcanvas.Title ></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    {/* <div className="off_arrow cursor"><MdKeyboardArrowLeft /></div> */}
                        {tradeoption === 1 &&
                            <>
                                <h5 className='themeclr'>Welcome to Options Trading!</h5>
                                <p className="mb-0 blkclr">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                    <br /><br />
                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.
                                </p>
                                <div class="form-check hidecheck mt-3">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" onChange={(e) => props.onChange(e)} checked={props.checkright} />
                                    <label class="form-check-label" for="exampleCheck1" >I have read and agree to <span className='themeclr'>edaface-dex options service agreement.</span></label>
                                </div>
                                <button class="btn btn-brand-1 sitebtn hover-up mt-3 w-100" onClick={() => setTradeoption(2)} disabled={!props.checkright}>Open Options Account </button>
                                {/* <button class="btn btn-brand-1 sitebtn hover-up mt-3 w-100">Register Now</button>
                    <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100">Log In</button> */}
                            </>}
                        {tradeoption === 2 &&
                            <>
                                {props.detailstabss &&
                                    <div className='mb-3'>
                                        <h5 className='themeclr mb-1 mt-3'>USDC-22045 C</h5>
                                        <p className='orgclr fw-600 f-14'>7.96x</p>

                                        <ul className='table_tabs'>
                                            <li className={opentabss === "opentab" ? 'active w-100 text-center' : 'w-100 text-center'} onClick={() => setOpentabss("opentab")}>Open</li>
                                            <li className={opentabss === "closetab" ? 'active w-100 text-center' : 'w-100 text-center'} onClick={() => setOpentabss("closetab")}>Close</li>
                                        </ul>
                                        <ul className='listtab d-flex gap-3'>
                                            <li className={openlimit === "limit" ? 'active fw-600' : ''} onClick={() => setOpenlimit("limit")}>Limit</li>
                                            <li className={openlimit === "bbo" ? 'active fw-600' : ''} onClick={() => setOpenlimit("bbo")}>BBO</li>
                                        </ul>

                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <p className="themeclr mb-1 fw-500">Avbl - 0.00 BTC</p>
                                            <MdSwapHoriz className='orgclr set_icon1 cursor' onClick={() => setTransfermodal(true)} />
                                        </div>

                                        <div>
                                            <div className="pos">
                                                <input type="text" className="form-control input_spot text-end" value="165" placeholder='165' />
                                                <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                                <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                            </div>
                                            {opentabss === "opentab" ?
                                                <p className='f-14 blkclr mt-1 mb-0'>Buy Price Cap : <span className='grnclr fw-500'>397.8</span></p> :
                                                <p className='f-14 blkclr mt-1 mb-0'>Sell Price Floor : <span className='redclr fw-500'>7.8</span></p>}
                                        </div>
                                        <div className='mt-3'>
                                            <div className="pos">
                                                <input type="text" className="form-control input_spot text-end" value="165" placeholder='165' />
                                                <p className="p_pos blkclr mb-0 fw-500">Amount</p>
                                                <p className="net_pos themeclr mb-0 f-13 fw-500 px-2">contract</p>
                                            </div>
                                            <p className='f-14 blkclr mt-1 mb-0'>Max amount : <span className='themeclr fw-500'>754.8</span></p>
                                        </div>
                                        {opentabss === "closetab" &&
                                            <button class="btn sellbtn w-100 fw-600 mt-3">Sell</button>
                                        }
                                        {opentabss === "opentab" &&
                                            <button class="btn buybtn w-100 fw-600 mt-3">Buy</button>}
                                        <p className='f-14 blkclr mt-1 mb-0'>Cost : <span className='themeclr fw-500'>165.1</span></p>

                                    </div>
                                }

                                {/* <h5 className='themeclr text-center'>Trade Options</h5>
                                <p className='themeclr text-center pt-2'>Select an option from market list to enable trading panel.</p> */}
                                <div className='d-flex justify-content-between'><p className='fw-600 mb-0 blkclr'>Account</p><MdSwapHoriz onClick={() => setTransfermodal(true)} className='cursor orgclr set_icon1' /></div>
                                <div className='d-flex justify-content-between mt-3 mb-2'><p className='fw-500 blkclr mb-0'>Total Equity</p><p className='themeclr fw-500 mb-0'>588,470.00 USDT</p></div>
                                <div className='d-flex justify-content-between mt-2 ps-2'><p className='fw-500 blkclr f-14 mb-0'>Margin Balance</p><p className='themeclr f-14 fw-500 mb-0'>452,085.77 USDT</p></div>
                                <div className='d-flex justify-content-between mt-2 ps-2'><p className='fw-500 blkclr f-14 mb-0'>Market Value</p><p className='themeclr f-14 fw-500 mb-0'>250.639 USDT</p></div>
                                <hr></hr>
                                <div className='d-flex justify-content-between mt-3 mb-2'><p className='fw-500 blkclr mb-0'>Unrealized Pnl</p><p className='redclr fw-500 mb-0'>- 871.560 USDT</p></div>
                                <hr></hr>
                                <div className='d-flex justify-content-between mb-2'><p className='fw-500 mb-0 blkclr'>Available Margin</p><p className='themeclr  fw-500 mb-0'>826.910 USDT</p></div>
                                <div className='d-flex justify-content-between'><p className='fw-500 mb-0 blkclr'>In Order</p><p className='themeclr fw-500 mb-0'>826.910 USDT</p></div>
                                <hr></hr>
                                <div className='d-flex justify-content-between mb-2'><p className='fw-500 mb-0 blkclr'>Account Greeks</p><MdOutlineKeyboardArrowDown /></div>
                                <div className='d-flex justify-content-between mt-2 ps-2'><p className='fw-500 blkclr f-14 mb-0'>Delta</p><p className='themeclr f-14 fw-500 mb-0'>1.10459</p></div>
                                <div className='d-flex justify-content-between mt-2 ps-2'><p className='fw-500 blkclr f-14 mb-0'>Gamma</p><p className='themeclr f-14 fw-500 mb-0'>--</p></div>
                                <div className='d-flex justify-content-between mt-2 ps-2'><p className='fw-500 blkclr f-14 mb-0'>Theta</p><p className='themeclr f-14 fw-500 mb-0'>--</p></div>
                                <div className='d-flex justify-content-between mt-2 ps-2'><p className='fw-500 blkclr f-14 mb-0'>Vega</p><p className='themeclr f-14 fw-500 mb-0'>--</p></div>
                            </>
                        }




                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}

export default Optionmodal