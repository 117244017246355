import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import { IoClose } from "react-icons/io5";

function Transfernetmodalss(props) {
    const [tokennetmodal, setTokennetmodal] = useState(true);
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, coinname: "WETH"
        },
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
        {
            id: 3, image: Images.usdc, coinname: "USDC"
        },
        {
            id: 4, image: Images.bal, coinname: "BAL"
        },
        {
            id: 5, image: Images.snx, coinname: "SNX"
        },
    ]);
    return (
        <>
            <div className='walletmodal'>

                <Modal show={tokennetmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-4"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <div className="pos mb-3">
                            <input type="search" className="form-control token_input" placeholder="Search Token" />
                            <img src={Images.searchicon} className="img-fluid search" />
                        </div>

                        {coinlists.map((e, i) =>
                            <div className="d-flex justify-content-between py-2 px-2 nethover" onClick={() => { props.setTokenchengess(e); props.setInitialliqss(e); props.onDismiss() }}>
                                <div className="d-flex">
                                    <img src={e.image} className="imf-fluid ethimg me-2" />
                                    <div><h6 className="themeclr fw-600 mb-1">{e.coinname}</h6><p className="themeclr mb-0">Etherum</p></div>
                                </div>
                                <div>
                                    <h6 className="themeclr mb-1 fw-600">0.00</h6>
                                    <p className="themeclr mb-0 f-14">0.00</p>
                                </div>
                            </div>)}

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Transfernetmodalss