import React, { useState } from "react";
import Chart from "react-apexcharts";

function Heatmap(props) {

    const generateData = ()=> {
        let arr = []
        for(let i=25; i<40; ++i){
          arr.push({x: i, y: Math.floor((Math.random() * 55) + 1)})
        }
        return arr
    };

    const series = [
        {
            name: 'Jan',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Feb',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Mar',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Apr',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'May',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Jun',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Jul',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Aug',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        },
        {
            name: 'Sep',
            data: generateData(20, {
                min: -30,
                max: 55
            })
        }];

    const options = {
        chart: {
            height: 350,
            type: 'heatmap',
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.5,
              radius: 0,
              useFillColorAsStroke: true,
              colorScale: {
                ranges: [{
                    from: -30,
                    to: 5,
                    name: 'low',
                    color: '#00A100'
                  },
                  {
                    from: 6,
                    to: 20,
                    name: 'medium',
                    color: '#128FD9'
                  },
                  {
                    from: 21,
                    to: 45,
                    name: 'high',
                    color: '#FFB200'
                  },
                  {
                    from: 46,
                    to: 55,
                    name: 'extreme',
                    color: '#FF0000'
                  }
                ]
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1
          },
          title: {
            text: ''
          },
        };
    
      



    return (

        <div id="chart" className="px-2 px-md-4 py-4 py-md-5">
            <Chart options={options} series={series}  type="heatmap" height={450} />
        </div>
    );
}

export default Heatmap