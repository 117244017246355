import React, { useState, useEffect } from "react";
import { Form, Table, InputGroup } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";

function Recentputstable() {

    const[calltrade,setCalltrade] = useState([
        {id:1,name:"Buy",color:"grnclr"},{id:2,name:"Buy",color:"grnclr"},{id:3,name:"Sell",color:"redclr"},
        {id:4,name:"Sell",color:"redclr"},{id:5,name:"Buy",color:"grnclr"}
    ]);

    return (

        <>
            <div className="price_table recent_trade mt-4">
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Side</th>
                        <th>Symbol</th>
                        <th>Price</th>
                        <th>Size(Cont)</th>
                        <th>Time</th></tr>
                    </thead>
                    <tbody>
                        {calltrade.map((e,i)=>
                        <tr>
                            <td className={e.color}>{e.name}</td>
                            <td>USDC-23496-C</td>
                            <td>1,155.0</td>
                            <td>0.05</td>
                            <td>2024-05-23 10:15:13</td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>

        </>
    )
}

export default Recentputstable;