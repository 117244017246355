import React, { useState, useEffect } from 'react';
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";
import { MdOutlineDeleteOutline } from "react-icons/md";

function Openorders() {

    return (
        <div className="price_table w-100 overauto">
            <Table responsive>
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>Symbol</th>
                        <th>Side</th>
                        <th>Price</th>
                        <th>Amount</th>
                        <th>Filled</th>
                        <th>Free</th>
                        <th>Status</th>
                        <th>Cancel</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2024-08-22 19:51:04</td> 
                        <td>USDC-23440-Call</td>
                        <td className='redclr fw-500'>Sell</td>
                        <td>100</td>
                        <td>-0.93</td> 
                        <td>--</td> 
                        <td>--</td>
                        <td>Accepted</td>
                        <td><MdOutlineDeleteOutline className='set_icon'/></td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Openorders;