import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Dropdown } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import walletbanner from "../../Assets/walletbanner.png"
import { Link } from "react-router-dom";
import Images from '../Images';
import { FaArrowRight } from "react-icons/fa6";


const Transfermodals = (props) => {

    const [tranfermodal, setTransfermodal] = useState(true);
    const [typess, setTypess] = useState(1)
    const [fundlist, setFundlist] = useState([
        { id: 1, name: "Funding" }, { id: 2, name: "Cross margin" }, { id: 3, name: "Fiat and Spot" }, { id: 4, name: "Isolated Margin" }
    ])
    const [interchange, setInterchange] = useState(
        { id: 1, name: "Funding" },
    );
    const [intertwochange, setIntertwochange] = useState(
        { id: 2, name: "Cross margin" },
    );
    const onswap = () => {
        var changea = interchange
        var changeb = intertwochange
        setInterchange(changeb)
        setIntertwochange(changea)
    };
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, coinname: "WETH"
        },
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
        {
            id: 3, image: Images.usdc, coinname: "USDC"
        },
        {
            id: 4, image: Images.bal, coinname: "BAL"
        },
        {
            id: 5, image: Images.snx, coinname: "SNX"
        },
    ]);
    useEffect(() => {

    }, []);

    return (
        <>

            <div className='walletmodal'>

                <Modal show={tranfermodal} size="lg" aria-labelledby="contained-modal-title-vcenter walletlist" centered >
                    <Modal.Body>
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Transfer</h5>
                        <p className="themeclr">Internal transfers are free on Binance.</p>
                        <div className="swap__blueBox p-3 d-flex align-items-center justify-content-between rounded-3 mt-3">
                            <h6 className="svgclr mb-0"> From</h6>
                            <Dropdown className="networkdrop">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {interchange.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {fundlist.map((e, i) =>
                                        <Dropdown.Item className={e.id == interchange.id ? "hideclass fw-600" : "fw-600"} onClick={() => { setInterchange(e) }}>{e.name}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="text-center my-0 swapclick" onClick={() => onswap()}><img src={Images.swapimg} className="imf-fluid ehtimg" /></div>
                        <div className="swap__blueBox p-3 d-flex align-items-center justify-content-between rounded-3">
                            <h6 className="svgclr mb-0"> To</h6>
                            <Dropdown className="networkdrop">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {intertwochange.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {fundlist.map((e, i) =>
                                        <Dropdown.Item disabled={e.id == interchange.id} onClick={() => setIntertwochange(e)} className="fw-600">{e.name}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="mt-3">
                            <p className="themeclr fw-600 mb-1">Coin</p>
                            <Dropdown className="chaindrop droparrow">
                                <Dropdown.Toggle className="w-100 text-start" variant="success" id="dropdown-basic"><img src={Images.eth} className="img-fluid ethimg" /> ETH eth</Dropdown.Toggle>
                                <Dropdown.Menu className="w-100">
                                    <div className="pos mb-3">
                                        <input type="search" className="form-control token_input" placeholder="Search Coin" />
                                        <img src={Images.searchicon} className="img-fluid search" />
                                    </div>
                                    {coinlists.map((e, i) => <Dropdown.Item className="py-2"><img src={e.image} className="img-fluid ethimg me-2" />{e.coinname}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="mt-3">
                       <div className="d-flex justify-content-between"><p className="themeclr fw-600 mb-1">Amount</p><p className="themeclr fw-600 mb-1">0.002 avble/0.33 in order</p></div>
                        <div className="pos"><input type="text" className="form-control input_spot"/> <p className="net_pos themeclr mb-0 fw-500 px-2">MAX</p></div>
                        </div>
                        <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100">Confirm</button>
                    </Modal.Body >
                </Modal >

            </div >

        </>
    )
}

export default Transfermodals;