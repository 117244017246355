import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Dropdown, DropdownButton, InputGroup, Form } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import walletbanner from "../../Assets/walletbanner.png"
import { Link } from "react-router-dom";
import Images from '../Images';
import { FaArrowRight } from "react-icons/fa6";


const Crossborrowmodals = (props) => {

    const [crossborrowmodal, setCrossborrowmodal] = useState(true);
    const [crossactives, setCrossactives] = useState("borrows");
    const handlecross = (tab) => {
        setCrossactives(tab)
    };
    const [crossaccount, setCrossaccount] = useState("cross");
    const handlecrossaccount = (tab) => {
        setCrossaccount(tab)
    };
    const [selectcoin, setSelectcoin] = useState({
        id: 1, image: Images.eth, coinname: "WETH"
    })
    const [coinlistss, setCoinlistss] = useState([
        {
            id: 1, image: Images.eth, coinname: "WETH"
        },
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
        {
            id: 3, image: Images.usdc, coinname: "USDC"
        },
        {
            id: 4, image: Images.bal, coinname: "BAL"
        },
        {
            id: 5, image: Images.snx, coinname: "SNX"
        },
    ]);
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, coinname: "CELOUSDT"
        },
        {
            id: 2, coinname: "COMPUSDT"
        },
        {
            id: 3, coinname: "CELOUSDT"
        },
        {
            id: 4, coinname: "COMPUSDT"
        },
        {
            id: 5, coinname: "CELOUSDT"
        },
    ]);

    return (

        <>
            <div className='walletmodal'>
                <Modal show={crossborrowmodal} size="lg" aria-labelledby="contained-modal-title-vcenter walletlist" centered >
                    <Modal.Body>
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>

                        <ul className="table_tabs">
                            <li className={crossactives === "borrows" ? "active ms-0" : "ms-0"} onClick={() => handlecross("borrows")}>Borrow</li>
                            <li className={crossactives === "repays" ? "active" : ""} onClick={() => handlecross("repays")}>Repay</li>
                        </ul>

                        <ul className="listtab modes d-flex gap-1 mt-2 pt-2 pb-2">
                            <li className={crossaccount === "cross" ? "active ms-0 w-100 text-center" : "ms-0 w-100 text-center"} onClick={() => handlecrossaccount("cross")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1aeb7zd"><path d="M21 3h-8v2h4.586L3 19.586 4.414 21 19 6.414V11h2V3z" fill="currentColor"></path><path d="M4.414 3l6.168 6.168-1.414 1.414L3 4.414 4.414 3zM14.833 13.418L21 19.584v.002L19.586 21l-6.167-6.168 1.414-1.415z" fill="currentColor"></path></svg> Cross Account</li>
                            <li className={crossaccount === "isolated" ? "active w-100 text-center" : "w-100 text-center"} onClick={() => handlecrossaccount("isolated")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1aeb7zd"><path d="M7.5 6.5a1 1 0 11-2 0 1 1 0 012 0z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 11a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm0-2a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" fill="currentColor"></path><path d="M13 3h8v8h-2V6.414L4.414 21 3 19.586 17.586 5H13V3z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M22 17.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm-2 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z" fill="currentColor"></path></svg> Isolated Account</li>
                        </ul>
                        {crossaccount === "cross" &&
                            <>
                                {crossactives === "repays" &&
                                    <>
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <p className="themeclr fw-600 mb-1">Amount</p>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                                    Only show my debt
                                                </label>
                                            </div></div>

                                        <InputGroup className="mb-3 mt-0 repay_input inputall pos">
                                            <DropdownButton
                                                className="d-flex align-items-center ps-0"
                                                title={<p className="mb-0"><img src={selectcoin.image} className="img-fluid ethimg" /> {selectcoin.coinname}</p>}
                                                id="input-group-dropdown-1">
                                                <div className="pos mb-3">
                                                    <input type="search" className="form-control token_input" placeholder="Search coin" />
                                                    <img src={Images.searchicon} className="img-fluid search" />
                                                </div>
                                                {coinlistss.map((e, i) =>
                                                    <Dropdown.Item onClick={() => setSelectcoin(e)}>
                                                        <div className="d-flex justify-content-between py-2 px-2 nethover">
                                                            <div className="d-flex">
                                                                <img src={e.image} className="imf-fluid ethimg me-2" />
                                                                <div><h6 className="themeclr fw-600 mb-1">{e.coinname}</h6><p className="themeclr fw-400 mb-0">Etherum</p></div>
                                                            </div>
                                                            <div>
                                                                <p className="themeclr fw-600 mb-1">0</p>
                                                                <p className="themeclr fw-400 f-15 mb-0">$0.00</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                )}
                                            </DropdownButton>
                                            <Form.Control className="form-control input_spot text-end" aria-label="Text input with dropdown button" placeholder="No debts" />
                                            <p className="net_pos themeclr mb-0 fw-500 px-2">MAX</p>
                                        </InputGroup>
                                        {crossactives != "borrows" &&
                                            <div className="marketbor w-100 py-3 px-3 rounded-2 mt-4 mb-3">
                                                <p className="themeclr mb-0">You haven’t borrowed any USDT yet.</p>
                                                <button class="btn btn-brand-1 sitebtn hover-up mt-3" onClick={() => handlecross("borrows")}>Borrow now</button>
                                            </div>}
                                    </>
                                }
                                {crossactives != "repays" &&
                                    <div className="marketbor w-100 py-3 px-3 mt-4 mb-3">
                                        <p className="themeclr mb-0">Your cross margin account currently has no assets, please make a transfer transaction first.</p>
                                        <button class="btn btn-brand-1 sitebtn hover-up mt-3" onClick={() => props.transferclick()}>Transfer</button>
                                    </div>}
                            </>
                        }
                        {crossaccount === "isolated" &&
                            <>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center justify-content-between mb-1"><p className="themeclr fw-600 mb-1">Pair</p>
                                        {crossactives === "repays" &&
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                                    Only show my debt
                                                </label>
                                            </div>}</div>
                                    <Dropdown className="chaindrop droparrow">
                                        <Dropdown.Toggle className="w-100 text-start" variant="success" id="dropdown-basic">
                                            <div className="d-flex justify-content-between me-4"><p className="mb-0">CELOUSDT</p></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="w-100">
                                            <div className="pos mb-3">
                                                <input type="search" className="form-control token_input" placeholder="Search pair" />
                                                <img src={Images.searchicon} className="img-fluid search" />
                                            </div>
                                            {coinlists.map((e, i) => <Dropdown.Item className="py-2 d-flex justify-content-between"><p className="mb-0">{e.coinname}</p><p className="mb-0">0 | 0</p></Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                {crossactives === "repays" &&
                                    <div className="mt-3">
                                        <p className="themeclr fw-600 mb-1">Amount</p>
                                        <div className="d-block d-sm-flex gap-3">
                                            <div className="coinhover active rounded-3 py-3 px-3 mt-3 mt-sm-0">
                                                <p className="mb-0 themeclr fw-600"><img src={Images.eth} className="img-fluid ethimg"/> WETH</p>
                                                <p className="mb-0 f-14 themeclr ms-4 fw-400">Ethereum</p>
                                                <p className="mb-0 themeclr fw-600 mt-2">0</p>
                                                <p className="mb-0 f-14 themeclr fw-400">$0.00</p>
                                            </div>
                                            <div className="coinhover rounded-3 py-3 px-3 mt-3 mt-sm-0">
                                                <p className="mb-0 themeclr fw-600"><img src={Images.usdc} className="img-fluid ethimg"/> USDC</p>
                                                <p className="mb-0 f-14 themeclr ms-4 fw-400">Ethereum</p>
                                                <p className="mb-0 themeclr fw-600 mt-2">0</p>
                                                <p className="mb-0 f-14 themeclr fw-400">$0.00</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {crossactives != "borrows" &&
                                    <div className="marketbor w-100 py-3 px-3 rounded-2 mt-4 mb-3">
                                        <p className="themeclr mb-0">You haven’t borrowed any USDT yet.</p>
                                        <button class="btn btn-brand-1 sitebtn hover-up mt-3" onClick={() => handlecross("borrows")}>Borrow now</button>
                                    </div>}
                                {crossactives === "repays" &&
                                    <div>
                                        <div className="d-flex justify-content-between"><p className="themeclr">Available Balance</p><p className="themeclr fw-600">0.00000000 BTC</p></div>
                                        <div className="d-flex justify-content-between"><p className="themeclr">Interest (A)</p><p className="themeclr fw-600">0.00000000 BTC</p></div>
                                        <div className="d-flex justify-content-between"><p className="themeclr">Borrowed (B)</p><p className="themeclr fw-600">0.00000000 BTC</p></div>
                                        <div className="d-flex justify-content-between"><p className="themeclr">Total Debt (A + B)</p><p className="themeclr fw-600">0.00000000 BTC</p></div>
                                    </div>
                                }
                                {crossactives != "repays" &&
                                    <div className="marketbor w-100 py-3 px-3 mt-4 mb-3">
                                        <p className="themeclr mb-0">Your isolated margin account CELO / USDT currently has no assets, please make a transfer transaction first.</p>
                                        <button class="btn btn-brand-1 sitebtn hover-up mt-3" onClick={() => props.transferclick()}>Transfer</button>
                                    </div>}
                            </>
                        }

                        <div className="d-flex justify-content-end pb-3">
                            <button class="btn sellbtn mt-3 py-2 me-2" onClick={() => props.onDismiss()}>Cancel</button>
                            <button class="btn btn-brand-1 sitebtn rounded-2 hover-up mt-3" disabled>Confirm Borrow</button>
                        </div>


                    </Modal.Body >
                </Modal >

            </div >


        </>
    )
};
export default Crossborrowmodals;    