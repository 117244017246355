import { CURRENT_CHAIN_ID } from './useWeb3'
import { tokens } from '../config/tokens'
import { getWalletAddress } from "../Lib/localStorage"
import { Interface } from '@ethersproject/abi'
import MULTICALL_ABI from '../config/abi/multical.json'
import { GetChainIndex, useWeb3 } from "./useWeb3"
import { CHAINS, ZEROTH_ADDRESS } from "../config/env";
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import contractAddress from '../config/constant/contract'


//Abi
import PairAbi from '../config/abi/pairAbi.json'
import ERC20_ABI from '../config/abi/ERC20.json'
//lib
import { toFixedNumber } from "../Lib/FixedNumber"
import { consolelog } from '../Lib/consolelog'
import { approvelocal } from '../Lib/localStorage'

//hooks
import { GetPairInfo } from '../hooks/useFactory'
import { isEmpty } from '../Lib/isEmpty'
import { EstGas } from './useCommon'





export const UseERC20 = async (Token) => {
    const web3 = await useWeb3();
    try {
        // console.log(Token, "UseERC20__err")
        const contract = new web3.eth.Contract(ERC20_ABI, web3.utils.toChecksumAddress(Token));
        return contract;
    } catch (err) {
        console.log(err, "UseERC20__err")
    }
}

export const getTokens = () => {
    try {
        // console.log("getTokens", CURRENT_CHAIN_ID)
        // const CHAIN_ID = CURRENT_CHAIN_ID()
        // const token = tokens[`${CHAIN_ID}`]
        let token = JSON.stringify(tokens)
        token = JSON.parse(token)
        token = token.map((val)=>{
            // console.log(val,'getTokens')
            let address = getAddress(val.ContractAddress)
            if(val.isCoin){
                val.name = getAddress(val.Name)
                val.symbol = getAddress(val.Symbol)
            }
            val.address = address
            return val
        })
        // console.log(token,"getTokens")
        return token

    } catch (err) {
        console.log(err, "getTokens__err")
    }
}

export const getAddress = (ContractAddress)=>{
    try{
        console.log(ContractAddress,'ContractAddress')
        const CHAIN_ID = CURRENT_CHAIN_ID()
        let address = ContractAddress[`${CHAIN_ID}`]
        // console.log(address,"getAddress")
        return address
    }catch(err){
        console.log(err,'getAddress__err')
    }
}

export const getMulticallAddress = () => {
    try {
        const CHAINID =  CURRENT_CHAIN_ID()
        let nativeAddress = contractAddress.multicall[`${CHAINID}`]
        return nativeAddress
    } catch (err) {
        console.log(err, "getMulticallAddress__err")
    }
}


export const GetMultiCall = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(MULTICALL_ABI, await getMulticallAddress());
        return contract;
    } catch (err) {
        console.log(err, "GetMultiCall__err")
    }
}

export const multicall = async (abi, calls) => {
    try {
        console.log("multicall", abi, calls)

        const multi = await GetMultiCall();
        console.log("multi", multi)

        // const itf = new Interface(abi)
        const itf = new ethers.utils.Interface(abi)
        console.log("itf", itf, itf?.encodeFunctionData(calls[0]?.name, calls[0]?.params))
        const calldata = calls?.map((call) => [call?.address?.toLowerCase(), itf?.encodeFunctionData(call?.name, call?.params)])
        console.log("calldata", calldata)
        const { returnData } = await multi?.methods?.aggregate(calldata).call()
        const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i]?.name, call))
        console.log("res", res, returnData)
        return res
    } catch (err) {
        console.log(err, "multicall__err")
    }
}

export const getTokenBalance = async (calls) => {
    try {
        console.log("calls", calls)
        const data = await multicall(ERC20_ABI, calls)
        console.log("data", data[2], new BigNumber(data[2]).toNumber(), new BigNumber(data[2]).div(new BigNumber(10).pow(18)))
        let balance = data.map((bal, i) => {
            console.log("bal", bal, i, new BigNumber(bal).toNumber())
            return toFixedNumber(new BigNumber(bal).toNumber() / 10 ** 18)
        })
        console.log("balance", balance)
        return balance
    } catch (err) {
        console.log(err, "getTokenBalance__err")
    }
}


export const GetCurrencyBalance = async (calls) => {
    try {
        const web3 = await useWeb3();
        let account = getWalletAddress()
        const balance = await web3.eth.getBalance(account)
        console.log("getCurrencyBalance", balance / 10 ** 18)
        return balance / 10 ** 18
    } catch (err) {
        console.log(err, "getTokenBalance__err")
    }
}

export const ApproveToken = async (spenderAddress, amount, account, token) => {
    const web3 = await useWeb3()
    try {
        let contract = await UseERC20(token)
        let params = [web3.utils.toChecksumAddress(spenderAddress), amount]
        const { gasLimit, gasPrice } = await EstGas(params, ERC20_ABI, token, 'approve', account)
        let symbol = await contract.methods.symbol().call()
        let Approve = await contract.methods.approve(web3.utils.toChecksumAddress(spenderAddress), amount).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        if (Approve) {
            let qureyString = `Approve ${symbol}`
            let localdata = {
                txhash: Approve.transactionHash,
                qureyString: qureyString
            }
            approvelocal(JSON.stringify(localdata))
            return true
        }
    } catch (err) {
        consolelog("ApproveToken__err", err, true)
        return false
    }
}

export const CheckIsApproved = async (spenderAddress, amount, account, token) => {
    try {
        let contract = await UseERC20(token)
        const allow = await contract.methods.allowance(account, spenderAddress).call();
        consolelog("checkIsApproved", parseInt(allow) >= amount, true)
        return (parseInt(allow) >= amount) ? true : false;
    } catch (err) {
        consolelog("checkIsApproved__err", err, true)
        return false
    }
}

export const commonBase = () => {
    try {
        let tokens = getTokens()
        let allowedPair = tokens.filter((val) => { return val.commonBase === true })
        return allowedPair
    } catch (err) {
        consolelog('allowedPair__err', err, true)
    }
}

export const UseAllCommonPairs = async (tokenA, tokenB) => {
    try {
        consolelog('UseAllCommonPairs', tokenA, true)
        consolelog('UseAllCommonPairs', tokenA === void 0, true)

        let common = commonBase()
        let basePairs = []
        for (let i = 0; i < common.length; i++) {
            let base = common[i].address

            for (let j = 0; j < common.length; j++) {
                let Otherbase = common[j].address
                let arr = [base, Otherbase]
                if (i !== j) {
                    basePairs.push(arr)
                }
            }
        }
        let allPairCombinations = [
            [tokenA, tokenB],
            ...common.map((base) => [tokenA, base.address]),
            ...common.map((base) => [tokenB, base.address]),
            ...basePairs
        ]


        let allpair = await UsePair(allPairCombinations)
        consolelog(allPairCombinations, 'allPairCombinations', true)
        console.log(allpair,'allpair')
        return allpair
        // let basePairs =  common.map((val)=>{
        //     common.map((val2)=>{
        //         return [val.address,val2.address]
        //     })
        // })
    } catch (err) {
        consolelog('useAllCommonPairs__err', err, true)
    }
}

export const UsePair = async (curriens) => {
    try {
        // consolelog('UsePair1',pairAddresses,true)
        let pairAddresses = []

        for (let i = 0; i < curriens.length; i++) {
            let tokenA = curriens[i][0]
            let tokenB = curriens[i][1]
            console.log('UsePair2', i, tokenA, tokenB)
            if (tokenA.toLowerCase() !== tokenB.toLowerCase()) {
                let pairAddress = await GetPairInfo(tokenA, tokenB)
                console.log('pairAddress', pairAddresses, pairAddress, true)
                let isExit = isEmpty(pairAddresses) ? {} : pairAddresses.find((val) => (val.lpaddress?.toLowerCase() == pairAddress.toLowerCase()))
                console.log('UsePair_condition', isEmpty(isExit), pairAddress !== ZEROTH_ADDRESS)
                if (isEmpty(isExit) && pairAddress !== ZEROTH_ADDRESS) {
                    let calls = [
                        {
                            address: pairAddress,
                            name: 'getReserves',
                        },
                        {
                            address: pairAddress,
                            name: 'token0',
                        },
                        {
                            address: pairAddress,
                            name: 'token1',
                        }
                    ]


                    var pooldata = await multicall(PairAbi, calls)
                    // console.log(pooldata, 'pooldata')


                    let reserveA = new BigNumber(pooldata[0][0]._hex).toNumber()
                    let reserveB = new BigNumber(pooldata[0][1]._hex).toNumber()
                    let tokenA = pooldata[1][0]
                    let tokenB = pooldata[2][0]
                    let obj = {
                        reserveA: reserveA,
                        reserveB: reserveB,
                        lpaddress: pairAddress,
                        token0: tokenA,
                        token1: tokenB
                    }
                    pairAddresses.push(obj)

                }

            }
        }
        console.log(pairAddresses,'pairAddresses')
        // consolelog('UsePair', pairs, true)
        // return pairAddresses
        return pairAddresses

    } catch (err) {
        consolelog('UsePair__err', err, true)
    }
}