import React, { useState, useEffect } from "react";
import Images from "../../Components/Images";
import { Dropdown, DropdownMenu, DropdownToggle, Table } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import Orderbooktable from "../orderbooktable";
import { MdClose } from "react-icons/md";

function Optiontable(props) {

    const [activeDropdownId, setActiveDropdownId] = useState(null);

    const handleToggleDropdown = (id) => {
        if (activeDropdownId === id) {
            setActiveDropdownId(null); // Close dropdown if it's already open
        } else {
            setActiveDropdownId(id); // Open the clicked dropdown
        }
    };
  


    return (

        <div className="price_table w-100 overauto">

            <Table responsive hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Open (USDT)</th>
                        <th>Detta</th>
                        <th>Bid Size</th>
                        <th>Bid / IV</th>
                        <th>Mark / IV</th>
                        <th>Ask / IV</th>
                        <th>Ask Size</th>
                        <th>Position</th>
                    </tr>
                </thead>
                <tbody>
                    {props.mapdata.map((e, i) =>
                        // <tr className="cursor" onClick={()=>{ if(props.checkright) {props.detailtab(e);props.setCandleTab()}}}>
                        <tr key={i} className="cursor"
                        //  onClick={()=>{ props.detailtab(e);props.setCandleTab()}}
                        >
                            <td>
                                <div className="d-flex gap-2 align-items-center">
                                    <Dropdown className="drop_no calldrop firstarrow" 
                                      show={activeDropdownId === i} 
                                      onToggle={() => handleToggleDropdown(i)}
                                    >
                                       {/* { console.log("jsds",isDropdownoptionOpen)} */}
                                        
                                        <DropdownToggle 
                                         onClick={() => handleToggleDropdown(i)}
                                        ><MdKeyboardArrowDown className="callarrow" /></DropdownToggle>
                                        <DropdownMenu>
                                        <div className="text-end"><MdClose className="cursor" onClick={() => setActiveDropdownId(null)}/></div>
                                            <div className="row">
                                                <div className="col-xl-6 mt-2">
                                                    <div className="d-flex gap-3 flex-wrap">
                                                        <div>
                                                            <h6 className="themeclr">Leverage</h6>
                                                            <p className="mb-0">0.02</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">IV (Bid)</h6>
                                                            <p className="grnclr mb-0">43.07%</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">IV (Ask)</h6>
                                                            <p className="redclr mb-0">43.07%</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">24h High</h6>
                                                            <p className="mb-0">800.0</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">24h Low</h6>
                                                            <p className="mb-0">180.0</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">Volume</h6>
                                                            <p className="mb-0">52.07</p>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div>
                                                        <h6 className="themeclr mb-3">The Greeks</h6>
                                                        <div className="d-flex gap-3 flex-wrap">
                                                        <div>
                                                            <h6 className="themeclr">Delta</h6>
                                                            <p className="mb-0">0.02</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">Gamma</h6>
                                                            <p className="mb-0">0.00029</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">Theta</h6>
                                                            <p className="mb-0">-146.54</p>
                                                        </div>
                                                        <div>
                                                            <h6 className="themeclr">Vega</h6>
                                                            <p className="mb-0">12.00</p>
                                                        </div>
                                                      
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 mt-2">
                                                  <Orderbooktable/>
                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                    {/* <MdKeyboardArrowDown className="me-2 callarrow" /> */}

                                    <img src={Images.candle} onClick={() => { props.detailtab(e); props.setCandleTab() }} className="me-2" />
                                </div>
                            </td>
                            <td><p className="themeclr mb-0">358.58</p></td>
                            <td><p className="themeclr mb-0">0.58768</p></td>
                            <td><p className="themeclr mb-0">0.05</p></td>
                            <td>
                                <p className="grnclr mb-0">15.89520</p>
                                <p className="themeclr mb-0">0.00%</p>
                            </td>
                            <td>
                                <p className="themeclr mb-0">15.2584</p>
                                <p className="themeclr mb-0">158.00%</p>
                            </td>
                            <td>
                                <p className="redclr mb-0">18.2584</p>
                                <p className="themeclr mb-0">589.25%</p>
                            </td>
                            <td><p className="themeclr mb-0">89.48</p></td>
                            <td><p className="themeclr mb-0">--</p></td>

                        </tr>)}
                </tbody>
            </Table>

        </div>

    )
}

export default Optiontable;