import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import { IoClose } from "react-icons/io5";

function Transfernetworkmodals(props) {
    const [netwmodal, setNetwmodal] = useState(true);
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.presale, coinname: "BNB Chain"
        },
        {
            id: 2, image: Images.cointoken, coinname: "Arbitrum or ZK sync"
        },
        {
            id: 3, image: Images.exchange, coinname: "Polygon Matics"
        },
        {
            id: 4, image: Images.avalan, coinname: "Avalanche"
        },
    ]);
    return (
        <>
            <div className='walletmodal'>

                <Modal show={netwmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-4"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <div className="pos mb-3">
                            <input type="search" className="form-control token_input" placeholder="Search Network" />
                            <img src={Images.searchicon} className="img-fluid search" />
                        </div>

                        {props.types == 1 ?
                            coinlists.map((e, i) =>
                                <div className="d-flex py-2 px-2 nethover" onClick={() => { props.setInterchange(e); props.onDismiss() }}>
                                    <img src={e.image} className="imf-fluid ethimg me-2" />
                                    <div><h6 className="themeclr fw-600 mb-1">{e.coinname}</h6></div>
                                </div>) :
                            coinlists.filter(s => s.coinname != props.interchange.coinname).map((e, i) =>
                                <div className="d-flex py-2 px-2 nethover" onClick={() => { props.setIntertwochange(e); props.onDismiss() }}>
                                    <img src={e.image} className="imf-fluid ethimg me-2" />
                                    <div><h6 className="themeclr fw-600 mb-1">{e.coinname}</h6></div>
                                </div>)
                        }



                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Transfernetworkmodals