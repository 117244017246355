import React, { useState, useEffect } from 'react';
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";

function Position() {

    return (
        <div className="price_table w-100 overauto">
            <Table responsive>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Size</th>
                        <th>Available</th>
                        <th>Average Price</th>
                        <th>Unrealized PnL</th>
                        <th>ROE%</th>
                        <th>Market Value</th>
                        <th>Close Position</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>USDC-23440-Call</td>
                        <td className='grnclr fw-500'>0.93 Contracts</td>
                        <td>0.93</td>
                        <td>100</td> 
                        <td className='grnclr fw-500'>69 USDT</td> 
                        <td>74.00%</td>
                        <td>24</td>
                        <td className='d-flex gap-3 align-items-center'><p className='orgclr mb-0'>Custom</p><input type="text" placeholder='0.00' className='form-control input_spot custom_input'/><input type="text" className='form-control input_spot custom_input' placeholder='0.00'/></td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Position;