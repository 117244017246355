import React, { useState, useRef } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Container, Form, InputGroup } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import { Link } from "react-router-dom";
import { GoPlus } from "react-icons/go";

const WebsiteUrl = () => {

    const [websitestep, setWebsitestep] = useState(1);

  return (
    <>
        <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    <div className="cointoken websiteurl">
                        { websitestep == 1 && 
                            <div className="formbox formboxcenter">
                                <div className="text-center">
                                    <h6 class="formhead">Website URL</h6>
                                    <p className="content">
                                        Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                                    </p>
                                </div>
                                <Form>
                                    <Form.Label>Website URL (1)<span className="text-danger">*</span></Form.Label>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="Eda Token"></Form.Control>
                                    </InputGroup>
                                    <Form.Label>Website URL</Form.Label>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="Eda Token"></Form.Control>
                                    </InputGroup>
                                    <Form.Label>Website URL</Form.Label>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="Eda Token"></Form.Control>
                                    </InputGroup>                        
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setWebsitestep(2)} >Next</button>
                                    </div> 
                                </Form>
                            </div>
                        }
                        { websitestep == 2 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn mb-4">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setWebsitestep(1)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 class="formhead">Whitepaper</h6>
                                </div>
                                <Form>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Whitepaper url</Form.Label>
                                        <Form.Control type="text" className="borderin" placeholder="Please include the link to your project whitepaper"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Any Litepaper?</Form.Label>
                                        <Form.Control type="text" className="borderin" placeholder="If yes, include the Litepaper url"></Form.Control>
                                    </Form.Group>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setWebsitestep(3)} >Next</button>
                                    </div> 
                                </Form>
                            </div>

                        }
                        { websitestep == 3 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn mb-4">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setWebsitestep(2)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 class="formhead">Blockchain details</h6>
                                    <p className="content">
                                        What blockchain platform is your coin
                                        <span className="content noteblue d-block">
                                            (if it is a multichain coin/token, Blockchain Platform 1 will be taken as the Primary Chain)
                                        </span>
                                        <span className="d-block content noteblue my-4">
                                            <b>Blockchain Platform</b>
                                        </span>
                                    </p>
                                </div>
                                <Form>
                                    <Form.Label>Blockchain name<span className="text-danger">*</span></Form.Label>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                    </InputGroup>
                                    <Form.Label>Blockchain explorer link<span className="text-danger">*</span></Form.Label>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                    </InputGroup>
                                    <Form.Label>Contract address</Form.Label>
                                    <InputGroup className="topinputgroup align-items-start">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control as="textarea" placeholder="Enter your address...."></Form.Control>
                                    </InputGroup> 
                                    <span className="add"><GoPlus /></span>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100">Next</button>
                                    </div> 
                                </Form>
                            </div>

                        }
                    </div>
                </Container>
            </Container>
        <Footer />
    </>
  )
}

export default WebsiteUrl;