import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";


const Companydetail = () => {

    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <>
            <div className="text-center">
                <h6 className="formhead">Company Details</h6>
                <p className="content">
                    EdaFace will need to confirm which company owns the Project you are submitting.
                </p>
            </div>
            <Form>
                <Row className="justify-content-between">
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Registered Company name<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Company Name"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Country of Registration<span className="text-danger">*</span></Form.Label>
                            <Form.Select className="form-control">
                                <option value="1">Lorem ipsum</option>
                                <option value="2">Lorem ipsum</option>
                                <option value="3">Lorem ipsum</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            {/* <div className="flexbox justify-content-between">
                                <div>
                                    <Form.Label className="mb-0">Registered Names of Directors<span className="text-danger">*</span></Form.Label>
                                </div>
                                <div>
                                    <button type="button" className="btn anchorlink text-decoration-underline">
                                        <b>Click to add</b>
                                    </button>
                                </div>
                            </div> */}
                             <Form.Label className="mb-2">Registered Names of Directors<span className="text-danger">*</span></Form.Label>
                             <Form.Control type="text" placeholder="Names of director"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Proof of Company Registration Certificate</Form.Label>
                            <p className="content">
                                For Proof of Registration, you will need to upload a copy of Company Registration Certificate. Upload a copy of Company Registration Certificate
                            </p>
                            <div className="text-end mt-3 pos">
                                <input type="file" className="form-control uploadinput" onChange={handleChange}/>
                                <button type="button" className="btn anchorlink uploadcopybtn text-decoration-underline">
                                    <b>Upload a copy</b>
                                </button>
                            </div>
                            <img src={file} className="img-fluid fileimg"/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Company Trade name</Form.Label>
                            <Form.Control type="text" placeholder="Trade Name"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Website of Country's Registration body<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Country's Registration body"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Company Official Email address<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Email Address"></Form.Control>
                            <p className="content t-orange mt-2">
                                ** Note that only email address of the Project website is accepted, e.g. sales@company.com. No  public email such as yahoo mail, gmail, etc. will be accepted. **
                            </p>
                            <p className="content mt-2">
                                Verification of company’s email address will be done via email OTP.
                            </p>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Company Registration Number<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Registration Number"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date of Registration<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Date of Registration"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Company Official Phone number</Form.Label>
                            <Form.Control type="text" placeholder="Phone Number"></Form.Control>
                            <p className="content mt-2">
                                Verification of company’s phone number will be done via SMS OTP.
                            </p>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>


        </>
    )
}

export default Companydetail;