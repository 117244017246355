import { useWeb3 ,CURRENT_CHAIN_ID} from "./useWeb3";
import contractAddress from '../config/constant/contract'
import {ethers} from 'ethers'

//abi 
import MultiCallAbi from '../config/abi/MulticallABI.json'

//lib 
import { consolelog } from "../Lib/consolelog";


export const multicall = async (abi, calls) => {
    try {
        console.log("calls",calls)

        const multi = await GetMultiCall();
        console.log("multi",multi)

        const itf = new ethers.utils.Interface(abi)
        console.log("itf",itf)

        const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
        console.log("calldata",calldata)

        const { returnData } = await multi.methods.aggregate(calldata).call()
        console.log("returnData",returnData)

        const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))
         console.log("res",res)
        return res
    } catch (err) {
        consolelog('multicall___err', err, true)
    }

}

export const GetMultiCall = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(MultiCallAbi, getMulticallAddress());
        return contract;
    } catch (err) {
        consolelog('GetMultiCall___err', err, true)
    }
}

export const getMulticallAddress=()=>{
    try{
        const CHAINID =  CURRENT_CHAIN_ID()
        let multiAddress = contractAddress.multicall[`${CHAINID}`]
        return multiAddress
    }catch(err){
        consolelog('getMulticallAddress__err',err,true)
    }
}