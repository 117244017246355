import React, { useState, useEffect } from 'react';
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { isEmpty } from '../Lib/common';
import Transfernetmodalss from '../Components/transfernetmodal';
import Transfernetworkmodals from '../Components/tranfernetworkmodal';
import Transfersettings from '../Components/Modals/transfersettings';

function Bridges() {

    const [transfersetting, setTransfersetting] = useState(false);
    const [tokennetmodal, setTokennetmodal] = useState(false);
    const [tokenchengess, setTokenchengess] = useState({});
    const [initialliqss, setInitialliqss] = useState(
        {
            id: 1, image: Images.eth, coinname: "WETH",
        }
    );

    const [netwmodal, setNetwmodal] = useState(false);
    const [types, setTypes] = useState(1);
    const [interchange, setInterchange] = useState(
        {
            id: 1, image: Images.presale, coinname: "BNB Chain",
        }
    );
    const [intertwochange, setIntertwochange] = useState(
        {
            id: 2, image: Images.cointoken, coinname: "Arbitrum or ZK sync"
        },
    );
    const onswap = () => {
        var changea = interchange
        var changeb = intertwochange
        setInterchange(changeb)
        setIntertwochange(changea)
    };

    const [destination, setDestination] = useState(false);

    return (

        <>
            {tokennetmodal && <Transfernetmodalss onDismiss={() => setTokennetmodal(false)} setInitialliqss={(e) => setInitialliqss(e)} setTokenchengess={(e) => setTokenchengess(e)} />}
            {netwmodal && <Transfernetworkmodals onDismiss={() => setNetwmodal(false)} types={types} setInterchange={(e) => setInterchange(e)} setIntertwochange={(e) => setIntertwochange(e)} interchange={interchange}
            // setInitialnetwork={(e) => setInitialnetwork(e)} setTokennetchengess={(e) => setTokennetchengess(e)} 
            />}
            {transfersetting && <Transfersettings onDismiss={() => setTransfersetting(false)}/>}

            <div>
                <Innerheader />
                <Container fluid className="p-0 farms__whole">
                    <div className="swap__page position-relative pb-5">
                        <img src={Images.backlogo} className="backlogo farms__backlogo" />
                        <img src={Images.elogo} className="elogo farms__elogo" />

                        <Container className="">
                            {/* <div className="farms__table_holder rounded-4 px-3 py-3 py-sm-5 px-sm-4 p-xxl-5 pos"> */}
                               <div>
                                 <img src={Images.bluellipse} className="img-fluid rounde_circle1" />
                                <Row className="justify-content-center">
                                    <Col lg={6} xl={6} xxl={5} className="pos">
                                        <img src={Images.bluellipse} className="img-fluid rounde_circle" />
                                        <div className="farms__table_holder rounded-4 py-4 px-3 px-sm-4">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <p className="swap__leftTitle m-0 poppins_font">Transfer</p>
                                                <div>
                                                    <img src={destination ? Images.walletminus : Images.walletplus} className='img-fluid me-3 cursor' onClick={() => setDestination(!destination)} />
                                                    <img src={Images.setimg} className='img-fluid cursor' onClick={()=>setTransfersetting(true)}/></div>
                                            </div>

                                            <div className="swap__blueBox d-flex justify-content-between p-2 p-sm-3 rounded-4">
                                                <div>
                                                    <p className='themeclr fw-500'>Token</p>
                                                    <Dropdown className="networkdrop">
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => setTokennetmodal(true)}>
                                                            {!isEmpty(tokenchengess) && <img src={tokenchengess?.image} className='img-fluid ethimg me-2' />}{isEmpty(tokenchengess) ? 'Select Token' : tokenchengess?.coinname}
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </div>
                                                <div>
                                                    <p className='themeclr fw-500 text-end'>From</p>
                                                    <Dropdown className="chaindrop text-center text-sm-left">
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(1); setNetwmodal(true) }}>
                                                            <img src={interchange.image} className="imf-fluid ethimg" /> {interchange.coinname}                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="text-center my-2 swapclick" onClick={() => onswap()}><img src={Images.swapimg} className="imf-fluid ehtimg" /></div>

                                            <div className="swap__blueBox d-flex justify-content-between p-2 p-sm-3 rounded-4">
                                                <div>
                                                    <p className='themeclr fw-500'>Token</p>
                                                    <Dropdown className="networkdrop">
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => setTokennetmodal(true)}>
                                                            {!isEmpty(tokenchengess) && <img src={tokenchengess?.image} className='img-fluid ethimg me-2' />}{isEmpty(tokenchengess) ? 'Select Token' : tokenchengess?.coinname}
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </div>
                                                <div>
                                                    <p className='themeclr fw-500 text-end'>To</p>
                                                    <Dropdown className="chaindrop text-center text-sm-left">
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetwmodal(true) }}>
                                                            <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </div>
                                            </div>

                                            <div className="swap__blueBox d-flex justify-content-between p-2 p-sm-3 rounded-4 mt-3">
                                                <input type="text" class="form-control swap_input fw-500" placeholder='0.00' />
                                                <div className='d-flex align-items-center'>
                                                    <div>
                                                        <p className='blkclr mb-1 fw-500'>Balance</p>
                                                        <p className='themeclr f-13 mb-0'>0.00</p></div>
                                                    <p className='orgclr mb-0 fw-600 ms-3'>Max</p>
                                                </div>
                                            </div>
                                            <p className='themeclr mb-0 f-14 fw-400 mt-1'>Est. Value: $0.00</p>

                                            {destination ?
                                                <input type='text' className='form-control input_spot mt-2' placeholder='Add Destination Address' /> : <></>
                                            }

                                            <div className='row mb-4'>
                                                <div className='col-lg-6 mt-3'>
                                                    <div className='option_border rounded-3 px-2 py-2'>
                                                        <div className='d-flex'>
                                                            <div className=''>
                                                                <p className='blkclr fw-500 f-14 mb-0'>Gas Cost:</p>
                                                                <input type='text' className='form-control swap_input ps-0' placeholder='0' />
                                                            </div>
                                                            <p className='themeclr fw-500 f-14 mb-0'>Fast</p></div>
                                                        <p className='themeclr fw-500 f-14 mb-0'>Est time: -</p>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 mt-3'>
                                                    <div className='option_border rounded-3 px-2 py-2'>
                                                        <div className='d-flex'>
                                                            <div className=''>
                                                                <p className='blkclr fw-500 f-14 mb-0'>Gas Cost:</p>
                                                                <input type='text' style={{ minWidth: "unset" }} className='form-control swap_input w-100 ps-0' placeholder='0' />
                                                            </div>
                                                            <p className='themeclr fw-500 f-14 mb-0'>Economy</p></div>
                                                        <p className='themeclr fw-500 f-14 mb-0'>Max time: -</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="d-flex justify-content-between"><p className="themeclr">You will receive</p><p className="themeclr fw-600">--</p></div>
                                            <div className="d-flex justify-content-between"><p className="themeclr">Gas on destination</p><p className="themeclr fw-600">Add</p></div>
                                            <div className="d-flex justify-content-between"><p className="themeclr">Slipage</p><p className="themeclr fw-600">5.00%</p></div>
                                            <div className="d-flex justify-content-between"><p className="themeclr">Fee</p><p className="themeclr fw-600">-</p></div>

                                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100">Transfer</button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>

                    </div>
                </Container >

                <Footer />
            </div >
        </>
    )
}

export default Bridges;