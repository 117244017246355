import { GetChainIndex, useWeb3 } from "./useWeb3"

import ERC20_ABI from "../config/abi/ERC20.json";
import WETHABI from '../config/abi/wbnbabi.json'
import { consolelog } from "../Lib/consolelog"
import { getWalletAddress } from "../Lib/localStorage";
import { getRouterAddress } from './useRouter'
import { EstGas } from "./useCommon";
import { approvelocal } from '../Lib/localStorage'
import { multicall } from "./useTokens";
import BigNumber from 'bignumber.js'


export const UseERC20 = async (Token) => {
    const web3 = await useWeb3();
    try {
        console.log(Token, "UseERC20__err")
        const contract = new web3.eth.Contract(ERC20_ABI, web3.utils.toChecksumAddress(Token));
        return contract;
    } catch (err) {
        console.log(err, "UseERC20__err")
    }
}

export const checkIsApproved = async (Token, amount) => {
    try {
        console.log(Token, amount, "checkIsApproved")
        const account = getWalletAddress()
        const spenderAddress = getRouterAddress()
        const erc20contract = await UseERC20(Token);
        // let calls = [{
        //     address: Token,
        //     name: 'allowance',
        //     params: [account, spenderAddress],
        // }]
        // const allowances = await multicall(ERC20_ABI, calls)
        const allow = await erc20contract.methods.allowance(account, spenderAddress).call();
        console.log(allow,'allow')
        console.log(parseInt(allow) >= amount, parseInt(allow), amount,'checkIsApproved', spenderAddress)
        return (parseInt(allow) >= amount) ? true : false;
    } catch (err) {
        console.log("checkIsApproved", err)
    }
}

export const Balance = async (Token) => {
    const web3 = await useWeb3()
    try {
        const account = getWalletAddress()
        console.log("account_account",account);
        let contract = await UseERC20(Token)
        let balance = await contract.methods.balanceOf(web3.utils.toChecksumAddress(account)).call()
        console.log("Balance : ", balance,parseInt(balance) / 10 ** 18)
        return parseInt(balance) / 10 ** 18
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const ApproveToken = async (Token, spenderAddress) => {
    const web3 = await useWeb3()
    try {
        // const spenderAddress = getRouterAddress()
        let amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        const account = getWalletAddress()
        let contract = await UseERC20(Token)
        let params = [web3.utils.toChecksumAddress(spenderAddress), amount]
        const { gasLimit, gasPrice } = await EstGas(params, ERC20_ABI, Token, 'approve', account)
        let symbol = await contract.methods.symbol().call()
        let Approve = await contract.methods.approve(web3.utils.toChecksumAddress(spenderAddress), amount).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("approve", Approve)
        if (Approve) {
            console.log("approve2", Approve)
            let qureyString = `Approve ${symbol}`
            let localdata = {
                txhash: Approve.transactionHash,
                qureyString: qureyString
            }
            approvelocal(JSON.stringify(localdata))
            return {
                status: true,
                data: Approve
            }
        }
    } catch (err) {
        consolelog("ApproveToken__err", err, true)
        return {
            status: false,
            data: ""
        }
    }
}


export const TokenInfo = async (Token) => {
    try {
        const Contract = await UseERC20(Token)
        let tokenInfo = {}
        if (Contract) {
            tokenInfo['address'] = Token
            tokenInfo['name'] = await Contract?.methods?.name().call();
            tokenInfo['symbol'] = await Contract?.methods?.symbol().call();
            tokenInfo['decimal'] = await Contract?.methods?.decimals().call();
            tokenInfo['totalSupply'] = await Contract?.methods?.totalSupply().call();
            return tokenInfo
        } else {
            return tokenInfo
        }
    } catch (err) {
        console.log(err, "TokenInfo___err")
    }
}


export const ImportToken = async (tokenAddress, tokenSymbol, tokenDecimals, image) => {
    const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20',
            options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
                image: image,
            },
        },
    })

    return tokenAdded
}

export const Wrap = async(Token,amount)=>{
    try{
        let web3 =  await useWeb3()
        const contract = new web3.eth.Contract(WETHABI, web3.utils.toChecksumAddress(Token));
        let account =  getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas([],WETHABI,Token, 'deposit', account)
        console.log("gasLimit_gasPrice",gasLimit, gasPrice,amount);
        const wrap = await contract.methods.deposit().send({from: getWalletAddress(), value: amount.toString(),gasLimit: gasLimit, gasPrice: gasPrice})
        console.log(wrap,'wrap_dataaa')
        if(wrap){
            return wrap
        }
    }catch(err){
        console.log(err,"Wrap__err")
        return false
    }
}

export const UnWrap = async(Token,amount)=>{
    try{
        let web3 =  await useWeb3()
        const contract = new web3.eth.Contract(WETHABI, web3.utils.toChecksumAddress(Token));
        let account =  getWalletAddress()
        const { gasLimit, gasPrice } = await EstGas([amount],WETHABI,Token, 'deposit', account)
        const wrap = await contract.methods.withdraw(amount).send({from: getWalletAddress(),gasLimit: gasLimit, gasPrice: gasPrice})
        console.log(wrap,'UnWrap')
        if(wrap){
            return wrap
        }
    }catch(err){
        console.log(err,"Wrap__err")
        return false
    }
}
