import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "react-bootstrap";
import "../Karthick.css";
import Images from "../Components/Images";
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Switch from "react-switch";
import Liquidtytable from "../Components/Modals/liquidtytable";
import Roicalculator from '../Components//Modals/roicalculator';
import Walletpage from "../Components/Modals/walletmodal";
import Announcement from "../Components/announcement";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
// import Farmcard from "../../components/farmcard";
import bnblogo from "../../src/Assets/bnb.png";
import coinlogo from "../../src/Assets/coinbase.png";
import { useSelector } from 'react-redux';
import { isEmpty } from "../Lib/isEmpty";
import { getFarmsInfo } from "../hooks/useFarms";
import { toFixedNumber } from "../Lib/FixedNumber";
import { GetChainIndex } from "../hooks/useWeb3";
import toast, { Toaster } from "react-hot-toast";
import { UserDeposits } from "../hooks/useStake";

import Stakemodal from "../Components/Modals/staketokenmodal";
import {roundToSignificant} from '../hooks/useCommon'


import { CHAINS } from "../config/env";
import { BsBoxArrowUpRight } from "react-icons/bs";

import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

import {Deposit,CheckIsApproved,ApproveFarms} from '../hooks/useMasterChef'

import { getTokens, GetCurrencyBalance, multicall } from "../hooks/useTokens";
import Staketokenmodal from "../Components/Modals/staketokenmodal";
import { getWalletAddress } from "../Lib/localStorage";
import Unstaketokenmodal from "../Components/Modals/unstaketokenmodal";



let crtsearch = '';
let StakedOnly = false

function Farms(props) {
  console.log("proposssssssss", props);
  const [Walletmodal, setWalletmodal] = useState(false);
  const [statusTab, seStatusTab] = useState('live');
  const [accordionOpen, setAccordionOpen] = useState();
  const [unStakemodal, setUnStakemodal] = useState(false);

  const [piddatas,setValues]= useState()
console.log("piddatas_piddatas",piddatas);
  const toggleAccordion = (i,e) => {
    setAccordionOpen(i === accordionOpen ? null : i);
    console.log("target_val",e);
    setValues(e)
  };
  const [accordiontable, setAccordiontable] = useState([
    { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 },
  ]);
  const [checked, setChecked] = useState(true);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
  const [checkeds, setCheckeds] = useState(true);
  const handleChanges = nextChecked => {
    setCheckeds(nextChecked);
  };

  const userdetail = useSelector((state) => state.admin);

  const [liquimodal, setLiquimodal] = useState(false);
  const [roical, setRoical] = useState(false);
  const [types, setTypes] = useState(1)
  const [changefirst, setChangefirst] = useState({
    id: 1, image: Images.eth, coinname: "ETH"
  });
  const [changesecond, setChangesecond] = useState({
    id: 1, image: Images.usdc, coinname: "USDC"
  })
  const onswap = () => {
    var changea = changefirst
    var changeb = changesecond
    setChangefirst(changeb)
    setChangesecond(changea)
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  const navigate = useNavigate();
  // const [checked, setChecked] = useState(false);
  const [listtype, setListtype] = useState(false);
  const [status, setStatus] = useState(true);
  const [stakedOnly, setStakedOnly] = useState(false);
  const [sortBy, setSortBy] = useState("Hot");
  const [token, setToken] = useState(false);
  const [quoteToken, setQuoteToken] = useState(false);

  const [stakemodal, setStakemodal] = useState(false);


  const [farmdetails, setFarmdetails] = useState([
    {
      firstimg: bnblogo,
      secimg: coinlogo,
      fromname: "DSP",
      toname: "BNB",
      apr: "39,401,732,475.65%",
      multi: "1",
      liquidity: "0",
      earned: "0",
    },
    {
      firstimg: bnblogo,
      secimg: coinlogo,
      fromname: "DSP",
      toname: "BNB",
      apr: "39,401,732,475.65%",
      multi: "1",
      liquidity: "0",
      earned: "0",
    },
    {
      firstimg: bnblogo,
      secimg: coinlogo,
      fromname: "DSP",
      toname: "BNB",
      apr: "39,401,732,475.65%",
      multi: "1",
      liquidity: "0",
      earned: "0",
    },
  ]);

  const [Farms, setFarms] = useState([]);
  const [activeFarms, setActiveFarms] = useState([]);
  const [inactiveFarms, setinActiveFarms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  console.log("searchResults_searchResults12", searchResults);
  const [stakedAmount, setStakedAmount] = useState();
  const [loading, setLoading] = useState(true);
  const [liquidity, setLiquidity] = useState();
  console.log("liquidity_liquidity",liquidity);

  const [staketokemodals, setStaketokemodals] = useState(false);
  const [unstaketokemodals, setUnstaketokemodals] = useState(false);

  // console.log("liquidity_liquidity", liquidity);
  const [earningsBusd, setEarningsBusd] = useState();
  const [stakedUSDvalue, setStakedUSDvalue] = useState();
  const [nativePrice, setNativePrice] = useState();
  const [checkApprove, setCheckApprove] = useState(false);
  const [pending, setPending] = useState(false);
  const [value, setValue] =
    useState(
      115792089237316195423570985008687907853269984665640564039457584007913129639935
    );

  const { FarmData } = useSelector((state) => (state.farm))

  console.log("FarmData_FarmData4545", FarmData);
  useEffect(() => {
    console.log("testnetTokens");

    let results = [];

    if (!isEmpty(searchTerm)) {
      let regx = new RegExp(searchTerm, "i");

      results = activeFarms?.filter((val) => {
        return val?.lpSymbol?.match(regx);
      });
    } else {
      results = activeFarms;
    }
    setSearchResults(results);
    console.log(activeFarms, "search", results);
  }, [searchTerm]);

  // const onChangefn = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const onChangefn = (e) => {
    setSearchTerm(e.target.value);
    crtsearch = e.target.value;
  };
  console.log("searchResults_searchResults333",searchResults);

  var obj = {
    data: searchResults,
  };
  const enable = async () => {
    let result = await ApproveFarms(piddatas?.lpAddress);
    console.log("enable", result);
    if (result?.status == true) {
      setCheckApprove(true);
    }
  };

  useEffect(() => {
    // farmsInfo()
    setLoading(true)
    console.log("getFarmsInfo", FarmData)
    if (!isEmpty(FarmData)) {
      console.log("getFarmsInfo", FarmData)
      setFarms(FarmData);
      let active = FarmData.filter((farm) => {
        console.log(!!farm.isTokenOnly === !!false && farm.multiplier !== '0X', 'filter', !!true, !!farm.isTokenOnly)
        return !!farm.isTokenOnly === !!false && farm.multiplier !== '0X'
      })

      console.log('dattttaaa', active, crtsearch, searchTerm)
      let searchactive = active
      if (!isEmpty(crtsearch)) {
        let regx = new RegExp(crtsearch, 'i')
        searchactive = searchactive?.filter((val) => {
          console.log("activeFarms_search", val?.lpSymbol)
          return (val?.lpSymbol?.match(regx))
        })

      }
      console.log("searchactive", StakedOnly)
      if (StakedOnly) {
        searchactive = searchactive.filter((val) => {
          console.log("searchactive", val, val.StakedAmount)
          return val.StakedAmount > 0
        })
      }
      let SortBy = sortBy
      if (SortBy == 'Hot') {
        searchactive = HotOnclick(searchactive)

      }
      if (SortBy == 'APR') {
        searchactive = APROnclick(searchactive)
      }
      if (SortBy == 'Multiplier') {
        searchactive = MultiplierOnclick(searchactive)
      }
      if (SortBy == 'Earned') {
        searchactive = EarnedOnclick(searchactive)
      }
      if (SortBy == 'Liquidity') {
        searchactive = Liquidity(searchactive)
      }


      const inactive = FarmData.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier === '0X')
      setActiveFarms(active);
      setSearchResults(searchactive);
      setinActiveFarms(inactive);
      setLoading(false)

      // let farms =[]
      console.log(active, 'farms__farms')
    }
  }, [FarmData])

  const farmsInfo = async () => {
    setLoading(true);
    console.log("form");
    var data;
    if (localStorage.getItem("FarmInterval")) {
      let oldInterval = localStorage.getItem("FarmInterval");
      clearInterval(oldInterval);
    }
    const interval = setInterval(async () => {
      console.log("interval");

      data = await getFarmsInfo();
      console.log("getFarmsInfo", data);
      setFarms(data);
      const active = data?.filter((farm) => {
        console.log(
          !!farm.isTokenOnly === !!false && farm.multiplier !== "0X",
          "filter",
          !!true,
          !!farm.isTokenOnly
        );
        return !!farm.isTokenOnly === !!false && farm.multiplier !== "0X";
      });
      const inactive = data?.filter(
        (farm) => !!farm.isTokenOnly === !!false && farm.multiplier === "0X"
      );
      setActiveFarms(active);
      setSearchResults(active);
      setinActiveFarms(inactive);
      setLoading(false);

      // let farms =[]
      console.log(active, "farms__farms");
    }, 3000);
    localStorage.setItem("FarmInterval", interval);
    // if ((localStorage.getItem("Farms"))) {
    //     setFarms(JSON.parse(localStorage.getItem("Farms")));
    //     let Farms = JSON.parse(localStorage.getItem("Farms"))
    //     const active = Farms.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier !== '0X')
    //     console.log("active", active)
    //     const inactive = Farms.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier === '0X')
    //     console.log("inactive", inactive)
    //     setActiveFarms(active);
    //     setSearchResults(active)
    //     setinActiveFarms(inactive);
    //     return
    // }

    console.log("interval", interval);

    // for(let i = 0;i<data.length;i++){
    //     let e= data[i]

    //     // return{
    //     //     ...farm,
    //     //     StakedAmount:
    //     // }
    //     if(i==data.length-1){
    //         console.log('StakedAmount',farms)

    //         setActiveFarms(farms)
    //         setSearchResults(farms)
    //     }
    // }
  };

  const Approvecheck = async (address, value) => {
    var result = await CheckIsApproved(address, value);
if(result){
  setCheckApprove(result);
  console.log("setCheckApprove", result);
}
   
  };


  useEffect(() => {
    if (getWalletAddress()) {
      Approvecheck(props?.datas?.lpAddress, value);
    }
  }, [props?.datas?.lpAddress, value]);

  const stakedPools = (status) => {
    console.log('stakedPools')
    setChecked(status);
    setStakedOnly(status)
    StakedOnly = status
    if (status) {
      let FilterFarm = activeFarms.filter((val) => {
        console.log("val", val, val.StakedAmount)
        return val.StakedAmount > 0
      })
      console.log(FilterFarm, 'FilterFarm')
      setSearchResults(FilterFarm)
    } else {
      setSearchResults(activeFarms)
    }

  }
  useEffect(() => {
    console.log("props_dataslist", props?.datas, props?.status)
    let token1 = getTokens()?.find((val) => (val?.address?.toLowerCase() == props?.datas?.tokenAddress?.toLowerCase()));
    console.log("token1", token1)
    setToken(token1)
    let token2 = getTokens()?.find((val) => (val?.address?.toLowerCase() == props?.datas?.quoteTokenAddress?.toLowerCase()));
    console.log("token2", token2)
    setQuoteToken(token2)
    localFn()
  }, [])


  const localFn = () => {
    var price = localStorage.getItem("Farms")
    price = JSON.parse(price)
    console.log("price_price", price)
    let nativePrice = price?.find((val) => (val?.pid == 1)).tokenPriceBusd
    console.log("nativePrice", toFixedNumber(nativePrice), props?.datas?.earnings)
    setNativePrice(toFixedNumber(nativePrice))
    const earningsBusd = props?.datas?.earnings / 10 ** 18 ? props?.datas?.earnings / 10 ** 18 * toFixedNumber(nativePrice) : 0
    console.log("earningsBusd", toFixedNumber(earningsBusd))
    setEarningsBusd(earningsBusd)
    const totalLiquidity = new BigNumber(props?.datas?.lpTotalInQuoteToken).times(props?.datas?.quoteTokenPriceBusd)
    console.log("totalLiquidity_totalLiquidity", (totalLiquidity).toString())
    setLiquidity((totalLiquidity).toString())

  }

  const HotOnclick = (activeFarms) => {
    console.log("HotOnclick", activeFarms)
    if (stakedOnly) {
      activeFarms = activeFarms.filter((val) => {
        console.log("searchactive", val, val.StakedAmount)
        return val.StakedAmount > 0
      })
    }

    setSearchResults(activeFarms)
    return activeFarms
  }
  const stakedAmounts = async () => {
    let result = await UserDeposits(props?.datas?.pid);
    console.log("amount", result?.amount);
    setStakedAmount(result?.amount);
  };
  useEffect(() => {
    stakedAmounts();
  },[]);


  const APROnclick = (activeFarms) => {
    console.log("APROnclick")
    let Active = [...activeFarms]
    if (stakedOnly) {
      Active = Active.filter((val) => {
        console.log("searchactive", val, val.StakedAmount)
        return val.StakedAmount > 0
      })
    }
    let apr = Active.sort((a, b) => {
      console.log("apr", a, b)
      return a.apy - b.apy
    })
    console.log("apr", apr)
    setSearchResults(apr)
    return apr

  }


  const MultiplierOnclick = (activeFarms) => {
    console.log("multiplierOnclick")
    let Active = [...activeFarms]
    if (stakedOnly) {
      Active = Active.filter((val) => {
        console.log("searchactive", val, val.StakedAmount)
        return val.StakedAmount > 0
      })
    }
    let multiplier = Active.sort((a, b) => {
      console.log("multiplier", a, b)
      return b.multiplier - a.multiplier
    })
    console.log("multiplier", multiplier)
    setSearchResults(multiplier)
    return multiplier

  }


  const EarnedOnclick = (activeFarms) => {
    console.log("earnings")
    let Active = [...activeFarms]
    if (stakedOnly) {
      Active = Active.filter((val) => {
        console.log("searchactive", val, val.StakedAmount)
        return val.StakedAmount > 0
      })
    }
    let earnings = Active.sort((a, b) => {
      console.log("earnings", a, b)
      return b.earnings - a.earnings
    })
    console.log("earnings", earnings)
    setSearchResults(earnings)
    return earnings

  }


  const Liquidity = (activeFarms) => {
    let Active = [...activeFarms]
    if (stakedOnly) {
      Active = Active.filter((val) => {
        console.log("searchactive", val, val.StakedAmount)
        return val.StakedAmount > 0
      })
    }
    let Liquidity = Active.sort((a, b) => {
      const totalLiquidity = new BigNumber(a?.lpTotalInQuoteToken).times(a?.quoteTokenPriceBusd)
      const totalLiquidity2 = new BigNumber(b?.lpTotalInQuoteToken).times(b?.quoteTokenPriceBusd)
      return (totalLiquidity2).toString() - (totalLiquidity).toString()
    })
    console.log("Liquidity", Liquidity)
    setSearchResults(Liquidity)
    return Liquidity

  }

  const Harvesting = async () => {
    setPending(true);
    console.log(
      "parseFloat(unstakeValue*10**18).toFixed(0) : ",
      parseFloat(props?.datas?.earnings * 10 ** 18).toFixed(0)
    );
    var res = await Deposit(piddatas?.pid, 0);
    console.log("res", res);
    if (res == true) {
      toast.success(
        `Your ${piddatas?.lpSymbol} earnings have also been harvested to your wallet!`
      );
      //   props.onHide()
      setPending(false);
    } else {
      toast.error("Try Again!");
    }
  };



  return (
    <div>
      {Walletmodal && <Walletpage onDismiss={() => setWalletmodal(false)} />}
      {roical && <Roicalculator onDismiss={() => setRoical(false)} changefirst={changefirst} changesecond={changesecond} onswap={onswap} />}
      {liquimodal && <Liquidtytable onDismiss={() => setLiquimodal(false)} />}
      {staketokemodals && <Staketokenmodal onDismiss={() => setStaketokemodals(false)} />}
      {unstaketokemodals && <Unstaketokenmodal onDismiss={() => setUnstaketokemodals(false)} />}

      <Innerheader />

      <Container fluid className="p-0 farms__whole">

        <div className="container innerpagess">
          {/* <Announcement/> */}
        </div>

        <div className="d-flex align-items-end position-relative">

          <img src={Images.backlogo} className='backlogo farms__backlogo' />
          <img src={Images.elogo} className='elogo farms__elogo' />

          <div className="farms__bannerImg py-4 py-lg-0 position-relative d-flex justify-content-center align-items-center">

            <Container>

              <Row className="align-items-center">
                <Col lg={12} xl={10} xxl={9} className="mx-auto">
                  <div className="farms__titleGlass rounded-4 p-3 p-sm-4">
                    <Row className="align-items-center">
                      <Col md={6} className="mb-4 mb-md-0">
                        <h2 className="m-0 inter_font farms__title">Farms</h2>
                        <h6 className="m-0 poppins_font farms__subTitle mt-1">Stake LP tokens to earn</h6>
                        <button className="farms_galssBtn rounded-3 poppins_font mt-3">Community Auctions <i class="fa-solid fa-chevron-right" /></button>
                      </Col>
                      <Col md={6}>
                        <div className="farms__whiteGalss rounded-4 p-3 psm-4">
                          <p className="m-0 poppins_font farms__whiteGlassTtl">Yield Booster</p>
                          <p className="m-0 poppins_font farms__whiteGlassSkyText mt-3">Connect wallet to view booster</p>
                          <p className="m-0 poppins_font farms__whiteGlassHint mt-2">An active fixed-terms staking position is required
                            for activating farm yield boosters.</p>
                          <button className="btn btn-brand-1 sitebtn hover-up mt-3">Connect Wallet</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

            </Container>
          </div>
        </div>

        <Container className="pb-5">
          <div className="farms__table_holder rounded-4 p-3 p-sm-4 p-xxl-5 mt-4">
            <Row>
              <Col lg={6} className="mb-4 mb-lg-0">
                <div className="farms_tableOptLeft d-flex flex-wrap gap-3 justify-content-center justify-content-xl-start align-items-center w-100">
                  {/* <button className="farms__hamburger">
                    <i class="fa-solid fa-bars"></i></button> */}

                  <div className="farms__tableToggle rounded-1">
                    <button className={`poppins_font ${statusTab == 'live' ? 'farms__toggleButtonActive active' : 'farms__toggleButton'}`}
                      onClick={() => setStatus(true)}>
                      Live
                    </button>
                    <button className={`poppins_font  ${statusTab == 'finished' ? 'farms__toggleButtonActive active' : 'farms__toggleButton'}`}
                      onClick={() => setStatus(false)}>
                      Finished
                    </button>
                  </div>

                  {/* <button className="poppins_font farms__typesBtn">Farms Types</button> */}
                  <Dropdown className="farms_type">
                    <DropdownToggle>Farms Types</DropdownToggle>
                    <DropdownMenu className="px-3 py-3">
                      <div className="d-flex justify-content-between mb-2">
                        <h6 className="fw-600">V2 Farms</h6>
                        <Switch
                          onChange={handleChange}
                          checked={checked}
                          offColor="#162019"
                          onColor="#1888b7"
                          offHandleColor="#ffff"
                          onHandleColor="#00e7fa"
                          height={20}
                          width={36}
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className="react-switch"
                          id="material-switch"
                        />
                      </div>
                      <div className="d-flex justify-content-between mb-0">
                        <h6 className="fw-600">V1 Farms</h6>
                        <Switch
                          onChange={handleChanges}
                          checked={checkeds}
                          offColor="#162019"
                          onColor="#1888b7"
                          offHandleColor="#ffff"
                          onHandleColor="#00e7fa"
                          height={20}
                          width={36}
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className="react-switch"
                          id="material-switch"
                        />
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <label class="switch ">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                    <p className="farms__switchText m-0 poppins_text">stake only</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} className="d-flex justify-content-center justify-content-lg-end align-items-center gap-4">
                <Row>
                  <Col sm={6} className="mb-4">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center gap-2">
                      <p className="farms__switchText m-0 poppins_text">Sort By</p>
                      <Dropdown className="farms__tableOptDrop"
                        onSelect={(e) => {
                          setSortBy(e);
                        }}>
                        <Dropdown.Toggle id="dropdown-basic">
                          {sortBy}<i class="fa-solid fa-angle-down ms-4"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="farms__tableOptDropMenu">
                          <Dropdown.Item
                            eventKey="Hot"
                            onClick={() => {
                              HotOnclick(activeFarms);
                            }}
                          >Hot</Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-1"
                            eventKey="APR"
                            onClick={() => {
                              APROnclick(activeFarms);
                            }}
                          >APR</Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            eventKey="Multiplier"
                            onClick={() => {
                              MultiplierOnclick(activeFarms);
                            }}
                          >Multiplier</Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            eventKey="Earned"
                            onClick={() => {
                              EarnedOnclick(activeFarms);
                            }}
                          >Earned</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="d-flex justify-content-between justify-content-sm-end align-items-center gap-2">
                      <p className="farms__switchText m-0 poppins_text">Search</p>
                      <input type="text"
                        className="farms__tableOptSearch"
                        placeholder="Search Farms"
                        value={searchTerm}
                        onChange={onChangefn}
                      />
                    </div>
                  </Col>
                </Row>



              </Col>
            </Row>





            <div className="farms__accordHolder pb-5 rounded-4 p-2 my-5">
              {loading && (
                <p>Loading...</p>
              )}
              {status ?
                <Accordion defaultActiveKey={1} className="farms__accordion rounded-4" flush>
                  {searchResults.map((e, i) =>
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header onClick={() => toggleAccordion(i + 1,e)}>

                        <div className="farms__accordHeader d-flex  justify-content-between align-items-start w-100 gap-4">

                          <div className="farms__tableLogoCont d-flex justify-content-start align-items-center gap-2 farms__cmnChild">
                            <div className="farms__tableImgHolder position-relative">
                              <img src={Images.busd} className="img-fluid farms__busdLogo" />
                              <img src={Images.edaface} className="img-fluid farms__edafaceLogo position-relative" />
                            </div>
                            {console.log("e_pair", e)}
                            <p className="m-0 poppins_font farms__coinName">{e?.tokenSymbol}-{e?.quoteTokenSymbol}</p>
                          </div>

                          <div className="poppins_font farms__typesBtn farms__accordHeadBtn rounded-pill  farms__cmnChild">0.25%</div>
                          <div className="poppins_font farms__typesBtn farms__accordHeadBtn rounded-pill  farms__cmnChild"><img src={Images.rocket} className="img-fluid farms__accordRocket me-1" />Boosted</div>
                          <div className="farms__accordHeadEarn farms__cmnChild">
                            <p className="farms__earnText poppins_font m-0">Earned</p>
                            <p className="farms__liquidityPrice poppins_font m-0">{parseFloat(e?.earnings / 10 ** 18).toFixed(4)}</p>
                          </div>

                          <div className="farms__accordHeadPercentHolder farms__cmnChild">
                            <p className="farms__earnText poppins_font m-0">APR</p>
                            <div className="farms__accordHeadPercent d-flex justify-content-start align-items-center gap-1 mt-1">
                              <img src={Images.rocket} className="img-fluid farms__accordRocket me-1" />
                              <p className="farms__percentText poppins_font m-0">{parseFloat(e?.apy) !== Infinity
                                ? parseFloat(e?.apy).toLocaleString("en-Us")
                                : 0}</p>
                              {/* <span className="ms-2">69.87%</span></p> */}
                              <img src={Images.calculator} className="img-fluid farms__accordRocket me-1" onClick={() => setRoical(true)} />
                            </div>
                          </div>

                          <div className="farms__accordHeadLiquidity farms__cmnChild">
                            <p className="farms__earnText poppins_font m-0">Staked Liquidity</p>
                            <p className="farms__liquidityPrice poppins_font m-0"> $ {toFixedNumber(liquidity)}</p>
                          </div>
                          <div className="farms__cmnChild">
                            <button className="farms__hamburger">
                              <i class="fa-regular fa-circle-question" /></button>
                          </div>
                          <div className="farms__accordHeadLiquidity farms__cmnChild">
                            <p className="farms__earnText poppins_font m-0">Multiplier</p>
                            <p className="farms__liquidityPrice poppins_font m-0">{e.multiplier}<button className="farms__hamburger ms-2">
                              <i class="fa-regular fa-circle-question" /></button></p>
                          </div>

                          <div className="farms__accordHeadLiquidity farms__cmnChild">
                            <p className="farms__earnText poppins_font m-0">Available</p>
                            <p className="farms__liquidityPrice poppins_font m-0">0 LP</p>
                          </div>

                          <div className="farms__accordHeadLiquidity farms__cmnChild">
                            <p className="farms__earnText poppins_font m-0">Staked</p>
                            <p className="farms__liquidityPrice poppins_font m-0">0 LP</p>
                          </div>
                          <div className="farms__accordHeadLiquidity farms__cmnChild">
                            {accordionOpen == i + 1 ? <MdOutlineKeyboardArrowDown className="arrwoup" /> : <MdKeyboardArrowUp className="arrwoup" />}
                          </div>
                        </div>
                      </Accordion.Header>
                      {/* {console.log("eeee",e)} */}
                      {staketokemodals ? (
        <Stakemodal
          show={staketokemodals}
          datas={piddatas}
          onDismiss={() => setStaketokemodals(false)}
        />
// {unstaketokemodals && <Unstaketokenmodal show={unStakemodal} datas={piddatas} onDismiss={() => setUnstaketokemodals(false)} stakedAmount={stakedAmount} />}

        
      ):<>
{unstaketokemodals && <Unstaketokenmodal show={unstaketokemodals} datas={piddatas} onDismiss={() => setUnstaketokemodals(false)} stakedAmount={stakedAmount} />}
      </>}
                      <Accordion.Body>
                        <div className="farms__table_holder rounded-1 p-3">
                          <Row className="align-items-center">
                            <Col lg={2} md={3} className="d-flex flex-column flex-sm-row justify-content-between align-items-start gap-3 mb-4 mb-md-0 flex-md-column mt-3">
                              <p className="m-0 poppins_font farms__coinName">Add {e?.tokenSymbol}-{e?.quoteTokenSymbol}</p>
                              <p className="m-0 poppins_font farms__coinName">See Pair Info</p>
                              <p className="m-0 poppins_font farms__coinName hoverlist cursor"
                                onClick={() => {
                                  window.open(
                                    `${CHAINS[GetChainIndex()].Testnet}/${e?.lpAddress
                                    }`,
                                    "_blank"
                                  );
                                }}
                                target="_blank"

                              >View Contract <BsBoxArrowUpRight className="ms-1" /></p>
                            </Col>

                            {/* <Col lg={10} md={9}>
                            <div className="farms__toggleButtonActive farms__accordBodyBox rounded-1 p-4">
                              <p className="m-0 poppins_font farms__coinName text-center">No Position Found</p>
                              {sessionStorage.getItem("walletStatus") !== "connect" ?
                                <button className="btn btn-brand-1 sitebtn hover-up mt-3 w-100" onClick={() => { setWalletmodal(true) }}>Connect Wallet</button> :
                                <button className="btn btn-brand-1 sitebtn hover-up mt-3 w-100" onClick={() => setLiquimodal(true)}>Add Liquidity</button>}
                            </div>
                          </Col> */}
                            <Col lg={4} md={6} className="mt-3">
                              <div className="farms__toggleButtonActive farms__accordBodyBox rounded-3 p-3">
                                <h6 className="fw-600 orgclr">{e?.tokenSymbol}<span className="text-dark">Earned</span></h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h5 className="text-dark">{parseFloat(e?.earnings / 10 ** 18).toFixed(4)}</h5>
                                    <p className="mb-0">{parseFloat(earningsBusd).toFixed(14)}{" "}
                        {e?.rewardTokenSymbol[0]}</p>
                                  </div>
                                  {console.log("_checkApprove_stakedAmount",checkApprove,stakedAmount,piddatas?.earnings,)}
                                  <button className="btn btn-brand-1 sitebtn hover-up"
                                   disabled={
                                    checkApprove == false ||
                                    stakedAmount == 0 ||
                                    piddatas?.earnings == 0
                                  }
                                  onClick={() => {
                                    Harvesting();
                                  }}
                                  >
                                    {" "}
                                    Harvest</button>
                                </div>
                              </div>
                            </Col>

                            <Col lg={4} md={6} className="mt-3">
                              <div className="farms__toggleButtonActive farms__accordBodyBox rounded-3 p-3">
                                <h6 className="fw-600 orgclr">{e?.tokenSymbol}-{e?.quoteTokenSymbol} <span className="text-dark">LP STAKED</span></h6>
                              {console.log("piddatas?.status",piddatas?.status)}
                                {userdetail?.walletStatus == "connect" &&
                  !isEmpty(getWalletAddress()) ? (
                    checkApprove == false ? (
                      <div>
                        <div>
<button className="btn btn-brand-1 sitebtn hover-up w-100"  onClick={() => {
                            enable();
                          }}
                          disabled={piddatas?.status == "inactive"}>Enabled</button>

  </div>
                      </div>
                                ) :

                                (
                                  <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h5 className="text-dark">{stakedAmount !=0 ? roundToSignificant(stakedAmount / 10 ** 18,1) : 0}</h5>
                                    <p className="mb-0">~ {stakedUSDvalue!= 0 ? roundToSignificant(stakedUSDvalue, 4) : 0}</p>
                                  </div>
                                  <div className="d-flex gap-2">
                                  <button className="btn btn-brand-1 sitebtn hover-up" onClick={()=>setUnstaketokemodals(true)}><FaMinus/></button>
                                  <button className="btn btn-brand-1 sitebtn hover-up" onClick={()=>setStaketokemodals(true)}><FaPlus/></button>
                                  </div>
                                </div>
                                  
                                )
                  ) : (
<div>
<button className="btn btn-brand-1 sitebtn hover-up w-100">Connect Wallet</button>

  </div>
                  ) }
                                
                              </div>
                            </Col>
                          </Row>

                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    
                  )}
                </Accordion>
                :


                <p className="text-center mt-4">  These pools are no longer distributing rewards. Please unstake
                  your tokens.</p>


              }


            </div>



          </div>
          

        </Container>
      </Container>

      <Footer />
    </div>
  );
}

export default Farms;
