import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import Supplymodals from "../Components/Modals/supplymodal";

function Supplytable(props) {

    const [supplymodal, setSupplymodal] = useState(false);
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, names: "ETH", balance: "0", apy: "0.01", tick: Images.tick,
        },
        {
            id: 2, image: Images.dai, names: "DAI", balance: "1", apy: "0.01", tick: Images.tick,
        },
        {
            id: 3, image: Images.usdc, names: "USDC", balance: "0", apy: "0.01", tick: Images.tick,
        },
        {
            id: 4, image: Images.bal, names: "BAL", balance: "0", apy: "0.01", tick: Images.tick,
        },
        {
            id: 5, image: Images.snx, names: "SNX", balance: "0", apy: "0.01", tick: Images.tick,
        },
    ]);
    return (
        <>
        {supplymodal && <Supplymodals onDismiss={()=>setSupplymodal(false)}  setIsapplied={(e)=>props.setIsapplied(e)}/>}
            <div className="supplytable mt-3">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Assets <FaSort /></th>
                            <th>Wallet Balance <FaSort /></th>
                            <th>APY <FaSort /></th>
                            <th>Can be collateral <FaSort /></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="t_body">
                        {coinlists.map((e, i) =>
                            <tr>
                                <td className="pos"><img src={e.image} className="img-fluid wbox" /><span className="themeclr ms-2">{e.names}</span></td>
                                <td><p className="themeclr mb-0">{e.balance}</p></td>
                                <td><p className="themeclr mb-0">{e.apy}</p></td>
                                <td><img src={e.tick} className="img-fluid" /></td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <button className="btn btn-brand-1 sitebtn hover-up me-2" onClick={() => setSupplymodal(true)}>Supply</button>
                                        <Dropdown className="dot_drop">
                                            <DropdownToggle><BsThreeDots /></DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Action</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown></div> </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default Supplytable