import React, { useEffect, useState, Component } from "react";
import Chart from 'react-apexcharts';

export default function Donuts() {

    const series = [50, 50];
    const options = {
        chart: {
            type: 'donut',
        },
        labels: ["SNX", "AAVE"],
        colors: ["#A064A0", "#140028"],

        dataLabels: {
            enabled: false
        },
        stroke: {
            show: false,
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
        },
        legend: {
            show: true,
            // showForSingleSeries: false,
            // showForNullSeries: true,
            // showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '16px',
            fontWeight: 600,
            itemMargin: {
                horizontal: 15,
                vertical: 0
            },
        },
        // responsive: [{
        //     breakpoint: 480,
        //     options: {
        //         chart: {
        //             width: 200
        //         },
        //     }
        // }],
    }


    return (

        <>

            <div id="chart">            
            <Chart options={options} series={series} type="donut" height={350}/>
            <h5 className="textgreen text-center mt-3">In Your Wallet : $0.00</h5>
            </div>

        </>

    );



}