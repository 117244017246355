import React, { useState, useEffect } from "react";
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";

function Optiontable(props) {

    return (

        <div className="price_table">

            <Table responsive>
                <thead>
                    <tr>
                     <th>Strike</th>
                    </tr>
                </thead>
                <tbody>
                    {props.mapdata.map((e, i) =>
                        <tr>
                           <td className="padside"><p className="themeclr mb-0">0.059</p></td>
                        </tr>)}
                </tbody>
            </Table>

        </div>

    )
}

export default Optiontable