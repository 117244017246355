import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { GoMail } from "react-icons/go";


const Auditdetail = () => {

    const [yesthree, setYesthree] = useState(true);
    const [yesfour, setYesfour] = useState(false);
    const yesfourfunc = () => {
        setYesfour(!yesfour);
        setYesthree(!yesthree);
    }
    return (
<>

    <div className="text-center">
        <h6 className="formhead">Audit Details</h6>
        <p className="content">
            An audit of a Project establishes reputability as well as reliability, hence, determining how the  Market will react to the crypto project.
        </p>
    </div>
    <Form>
        <Form.Group className="mb-4">
            <Form.Label className="mb-3">Has your Project been audited?</Form.Label>
            <Row>
                <Col xs={12} md={3} lg={3} className="mb-3 mb-sm-0">
                    <Form.Check type="checkbox" label="Yes" onClick={() => setYesthree(!yesthree)}></Form.Check>
                </Col>
                <Col xs={12} md={3} lg={3}>
                    <Form.Check type="checkbox" label="No" onClick={yesfourfunc}></Form.Check>
                </Col>
            </Row>
        </Form.Group>
        {yesfour == false ?
            <>
                <Form.Group className="mb-4">
                    <Form.Label>Project been audited by any of the followings?</Form.Label>
                    <Form.Select className="form-control">
                        <option value="1">Certik</option>
                        <option value="2">Hacken</option>
                        <option value="3">ConsenSys</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>If yes, supply the audit URL link</Form.Label>
                    <Form.Control type="text" placeholder="Supply url"></Form.Control>
                    {/* <span className="add"><GoPlus /></span> */}
                </Form.Group>
            </>
            :
            <>
                <Form.Group className="mb-4">
                    <Form.Label>Name of the Auditor</Form.Label>
                    <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Audit URL link</Form.Label>
                    <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                </Form.Group>
            </>
        }
    </Form>


</>


)
}

export default Auditdetail;
