import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import walletbanner from "../../Assets/walletbanner.png"
import { Link } from "react-router-dom";
import Images from '../Images';
import mathwallet from "../../Assets/mathwallet.svg";
import tokenpocket from "../../Assets/tokenpocket.svg";
import walletcon from "../../Assets/walletconnect.svg";
import ellipse from "../../Assets/Ellipse.svg";
import { FaArrowRight } from "react-icons/fa6";
import {walletControl} from "../../Lib/localStorage"
import {MetamaskWallet} from "../../hooks/useWallet"
import { useDispatch, useSelector } from "react-redux";

const Walletmodal = (props) => {
    const dispatch = useDispatch();

    const [Walletmodal, setWalletmodal] = useState(true);

    const [walletnames, setWalletnames] = useState([
        { "id": 1, "image": Images.meta, "name":"Metamask" }, { "id": 2,"image": Images.coin, "name":"Coinbase Wallet" }, { "id": 3,"image": Images.trust, "name":"TrustWallet" }, { "id": 4,"image": mathwallet, "name":"MathWallet" }, { "id": 5,"image": tokenpocket, "name":"TokenPocket" }, { "id": 6, "image": walletcon, "name":"WalletConnect" }
    ]);




    const walletconnect = () => {
        dispatch(walletControl("connect"));
        sessionStorage.setItem("walletStatus", "connect");
        // localStorage.setItem("metamask",true)
        window.location.reload()
      };
    const MetamaskConnectWallet = async () => {
        try {
          const connection = await MetamaskWallet(dispatch);
          console.log("connection", connection);
          if (connection) {
            console.log("connection", connection);
            walletconnect();
          }
        } catch (e) {
          console.log("MetamaskConnectWallet", e);
        }
      };

    useEffect(() => {

    }, []);


    return (
        <>

            <div className='walletmodal'>

                <Modal show={Walletmodal} size="lg" aria-labelledby="contained-modal-title-vcenter walletlist" centered >
                    {/* <Modal.Header>
                        <Modal.Title>Connect Wallet</Modal.Title>
                        <button className='btn close' onClick={() => props.onDismiss()}><IoClose/></button>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div className="row">
                           <div className="col-md-12 col-lg-6 sidebanner">
                              <img src={walletbanner} className="img-fluid"/>
                            <h5 className="wallettext">Connect <br></br>Your Wallet <FaArrowRight/></h5>
                           </div>
                           <div className="col-md-12  col-lg-6 pt-3 pb-3 leftmodalback">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                           <h4 className="mb-0 fw-600">Choose your Wallet</h4>
                           <button className='btn close' onClick={() => props.onDismiss()}><IoClose/></button></div>

                        {/* {walletnames.map((e,i)=> */}

                            <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{MetamaskConnectWallet();props.onDismiss()}}>
                                <div className="col-7">
                                    <div className="d-flex">
                                    <div className="position-relative"><img src={Images.meta} className="img-fluid metaimg"/><img src={ellipse} className='ellipseeimg' /></div>   
                                   <p className="mb-0 walletname mt-2 ms-3">{"Metamask"}</p> </div>
                                </div>
                                <div className="col-5">
                              <Link className="btn outlines_btn">Connect Wallet</Link>
                                </div>
                            </div>


                            <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{localStorage.setItem("metamask",true);props.onDismiss();window.location.reload()}}>
                                <div className="col-7">
                                    <div className="d-flex">
                                    <div className="position-relative"><img src={Images.coin} className="img-fluid metaimg"/><img src={ellipse} className='ellipseeimg' /></div>   
                                   <p className="mb-0 walletname mt-2 ms-3">{"Coinbase Wallet"}</p> </div>
                                </div>
                                <div className="col-5">
                              <Link className="btn outlines_btn">Connect Wallet</Link>
                                </div>
                            </div>
                            <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{localStorage.setItem("metamask",true);props.onDismiss();window.location.reload()}}>
                                <div className="col-7">
                                    <div className="d-flex">
                                    <div className="position-relative"><img src={Images.trust} className="img-fluid metaimg"/><img src={ellipse} className='ellipseeimg' /></div>   
                                   <p className="mb-0 walletname mt-2 ms-3">{"TrustWallet"}</p> </div>
                                </div>
                                <div className="col-5">
                              <Link className="btn outlines_btn">Connect Wallet</Link>
                                </div>
                            </div>
                            <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{localStorage.setItem("metamask",true);props.onDismiss();window.location.reload()}}>
                                <div className="col-7">
                                    <div className="d-flex">
                                    <div className="position-relative"><img src={mathwallet} className="img-fluid metaimg"/><img src={ellipse} className='ellipseeimg' /></div>   
                                   <p className="mb-0 walletname mt-2 ms-3">{"MathWallet"}</p> </div>
                                </div>
                                <div className="col-5">
                              <Link className="btn outlines_btn">Connect Wallet</Link>
                                </div>
                            </div>
                            <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{localStorage.setItem("metamask",true);props.onDismiss();window.location.reload()}}>
                                <div className="col-7">
                                    <div className="d-flex">
                                    <div className="position-relative"><img src={tokenpocket} className="img-fluid metaimg"/><img src={ellipse} className='ellipseeimg' /></div>   
                                   <p className="mb-0 walletname mt-2 ms-3">{"TokenPocket"}</p> </div>
                                </div>
                                <div className="col-5">
                              <Link className="btn outlines_btn">Connect Wallet</Link>
                                </div>
                            </div>
                            
                            <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{localStorage.setItem("metamask",true);props.onDismiss();window.location.reload()}}>
                                <div className="col-7">
                                    <div className="d-flex">
                                    <div className="position-relative"><img src={walletcon} className="img-fluid metaimg"/><img src={ellipse} className='ellipseeimg' /></div>   
                                   <p className="mb-0 walletname mt-2 ms-3">{"WalletConnect"}</p> </div>
                                </div>
                                <div className="col-5">
                              <Link className="btn outlines_btn">Connect Wallet</Link>
                                </div>
                            </div>
                        {/* )} */}

                         
                             
                           </div>
                        </div>

                    </Modal.Body>
                </Modal>

            </div>

        </>
    )
}

export default Walletmodal;