import React, { useState, useEffect } from "react";
import Innerheader from "../../Layouts/innerheader";
import Footer from "../../Layouts/footer";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import "../../Karthick.css";
import "../../inner.css";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import Swal from 'sweetalert2';
import Submitrequest from "./submitrequest";
import Blockchainsubmission from "./blockchainsubmission";
import Websiteurl from "./websiteurl";
import Whitepaper from "./whitepaper";
import Blockchainplatform from "./blockchainplatform";
import Liquidity from "./liquiditysupply";
import Trafficflow from "./trafficflow";
import Auditdetail from "./auditdetail";
import Sourcecode from "./sourcecode";
import Projectteam from "./projectteam";
import Companydetail from "./companydetail";
import Socialmedia from "./socialmedial";
import Projectsubmitter from "./projectsubmitter";
import Projectscore from "./projectscore";
import Congrats from "./congrats";


const Allexchangeform = () => {

    const [exchangesteps, setExchangesteps] = useState(1);
    const [btndisable, setBtndisable] = useState(true);

    const submitlist = () => {
        Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Submitted Succesfully",
            showConfirmButton: false,
            timer: 3500
        });
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [exchangesteps]);

    return (
        <>
            <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />

                    <div className="cointoken">
                        {exchangesteps == 1 &&
                            <>
                                <div className="submitrequest">
                                    <div className="formbox formboxcenter">
                                        <Submitrequest path="/exchange" />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(2)}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 2 &&
                            <>
                                <div className="submitrequest">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(1)}>Back</button>
                                        </div>
                                        <div className="text-center">
                                            <h6 class="formhead">Accepts Legal Requirements</h6>
                                            <p className="content">
                                                Please, review all the legal requirements before you proceed further
                                            </p>
                                        </div>
                                        <Form>
                                            <div>
                                                <div className="d-flex align-items-start mt-5">
                                                    <Form.Check type="checkbox" onClick={() => setBtndisable(!btndisable)}></Form.Check>
                                                    <Form.Label>
                                                        <span className="content d-block">
                                                            I have read and I agree to all the <Link to="/" className="content noteblue">Terms of Use, Disclaimers, Listing Terms and Conditions, Privacy Policy</Link>, and all legal requirements of EDA
                                                        </span>
                                                    </Form.Label>
                                                </div>
                                            </div>
                                            <div className="mt-5 text-center">
                                                <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" disabled={btndisable} onClick={() => setExchangesteps(3)}>Next</button>
                                            </div>
                                        </Form>
                                    </div></div>
                            </>
                        }

                        {exchangesteps == 3 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(2)}>Back</button>
                                    </div>
                                    <div className="text-center mb-3">
                                        <h6 class="formhead">Submission Form <span className="d-block mt-1"> (Exchange, Markeplace, AI, etc.)</span></h6>
                                        <p className="content">
                                            Please, fill in the details. Note that EdaFace automated system will verify and grade your Project before submission.
                                        </p>
                                    </div>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Project Name<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Project Name"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Abbreviated name <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Abbrevation Name"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Business Category <span className="text-danger">*</span></Form.Label>
                                        <Form.Select className="form-control">
                                            <option value="1">Spot Exchange</option>
                                            <option value="2">Derivative Exchange </option>
                                            <option value="3">Dex Exchange</option>
                                            <option value="4">Marketplace</option>
                                            <option value="5">Others</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(4)} >Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 4 &&
                            <>
                                <div className="submissionform">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(3)}>Back</button>
                                        </div>
                                        <div className="text-center mb-3">
                                            <h6 class="formhead"> Description </h6>
                                            <p className="content">
                                                Please provide a clear and concise description of the project as follows:
                                            </p>
                                        </div>
                                        <Blockchainsubmission path="/exchange" />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(5)} >Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 5 &&
                            <>
                                <div className="websiteurl">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(4)}>Back</button>
                                        </div>
                                        <div className="text-center">
                                            <h6 class="formhead">Website URL</h6>
                                            <p className="content">
                                                Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                                            </p>
                                        </div>
                                        <Websiteurl />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(6)} >Next</button>
                                        </div>
                                    </div></div>
                            </>
                        }

                        {exchangesteps == 6 &&
                            <>
                                <div className="websiteurl">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(5)}>Back</button>
                                        </div>
                                        <Whitepaper />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(7)} >Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 7 &&
                            <>
                                <div className="websiteurl">
                                    <div className="formbox formboxcenter">
                                        <div className="backbtn mb-4">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(6)}>Back</button>
                                        </div>
                                        <div className="text-center">
                                            <h6 class="formhead">Blockchain details</h6>
                                            <p className="content">
                                                {/* What blockchain platform is your coin */}
                                                <span className="content noteblue d-block">
                                                    (if it is a multichain Project, Blockchain Platform 1 will be taken as the Primary Chain)
                                                </span>

                                            </p>
                                        </div>
                                        <Blockchainplatform />
                                        <div className="mt-5 text-center">
                                            <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(8)}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }


                        {exchangesteps == 8 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(7)}>Back</button>
                                    </div>
                                    <div className="text-center mb-4">
                                        <h6 class="formhead"> AVAILABLE MARKETS</h6>
                                        <p className="content">
                                            <span className="content noteblue d-block">
                                                (A coin token pair (e.g. EDA-USDT) forms a market. )
                                            </span>
                                        </p>
                                    </div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total Coins/Tokens <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="coin/token list"></Form.Control>
                                        <Form.Control className="mt-3" type="text" placeholder="Supply URL"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Trading Pairs  <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="coin/token pairs"></Form.Control>
                                        <Form.Control className="mt-3" type="text" placeholder="Supply URL"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fiat Trading  <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="fiat currencies"></Form.Control>
                                        <Form.Control className="mt-3" type="text" placeholder="Supply URL"></Form.Control>
                                    </Form.Group>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(9)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 9 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(8)}>Back</button>
                                    </div>
                                    <div className="text-center mb-4">
                                        <h6 class="formhead">Market Value</h6>
                                    </div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Listed Platforms </Form.Label>
                                        <Form.Control type="text" placeholder="your Project"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>24 Hours Highest Trading Volume </Form.Label>
                                        <Form.Control type="text" placeholder="trading volume in US Dollar"></Form.Control>
                                        <Form.Control className="mt-2" type="text" placeholder="supply URL"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>24 Hours Lowest Trading Volume </Form.Label>
                                        <Form.Control type="text" placeholder="trading volume in US Dollar"></Form.Control>
                                        <Form.Control className="mt-2" type="text" placeholder="supply URL"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>24 Hours Average Trading Volume </Form.Label>
                                        <Form.Control type="text" placeholder="automatically calculate the average"></Form.Control>                                    </Form.Group>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(10)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 10 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(9)}>Back</button>
                                    </div>
                                    <Liquidity path="/exchange" />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(11)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 11 &&
                            <>
                                <div className="formbox">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(10)}>Back</button>
                                    </div>
                                    <Trafficflow />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(12)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 12 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(11)}>Back</button>
                                    </div>
                                    <Auditdetail />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(13)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }


                        {exchangesteps == 13 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(12)}>Back</button>
                                    </div>
                                    <Sourcecode />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(14)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 14 &&
                            <>
                                <div className="formbox formboxcenter">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(13)}>Back</button>
                                    </div>
                                    <Projectteam />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setExchangesteps(15)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 15 &&
                            <>
                                <div className="formbox">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(14)}>Back</button>
                                    </div>
                                    <Companydetail />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-25" onClick={() => setExchangesteps(16)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 16 &&
                            <>
                                <div className="formbox">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(15)}>Back</button>
                                    </div>
                                    <Socialmedia />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-25" onClick={() => setExchangesteps(17)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 17 &&
                            <>
                                <div className="formbox">
                                    <div className="backbtn mb-4">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(16)}>Back</button>
                                    </div>
                                    <Projectsubmitter />
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-25" onClick={() => setExchangesteps(18)}>Next</button>
                                    </div>
                                </div>
                            </>
                        }


                        {exchangesteps == 18 &&
                            <>
                                <h3 className="h3">Your Project scores a total of <span className="t-orange">28%</span>.</h3>
                                <div className="formbox projectscore mt-3 mt-sm-5">
                                    <div className="backbtn mb-5">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(17)}>Back</button>
                                    </div>
                                    <Projectscore />
                                    <div className="mt-4 mb-3 text-center">
                                        <button type="button" className="btn txtbtn" onClick={() => setExchangesteps(19)}>
                                            (Please, Click here to improve your score and list on EdaFace)
                                        </button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 19 &&
                            <>
                                <h3 className="h3">Your Project scores a total of <span className="t-orange">40%</span>.</h3>
                                <div className="formbox congratstop mt-3 mt-sm-5">
                                    <div className="backbtn mb-5">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setExchangesteps(18)}>Back</button>
                                    </div>
                                    <Congrats />
                                    <div className="mt-5 mb-3 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setExchangesteps(20)}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </>
                        }

                        {exchangesteps == 20 &&
                            <>
                                <h3 className="h3">Project submission</h3>
                                <div className="formbox projectsubmit mt-3 mt-sm-5">
                                    <h3 className="h3">Please, tick:</h3>
                                    <Form className="mb-3 mb-sm-5 pb-3">
                                        <Form.Group className="mb-4">
                                            <Form.Check type="checkbox" label="All the information I have provided are accurate and true. Any misrepresentation may result in  delisting my Project and legal action."></Form.Check>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check type="checkbox" label="Since EdaFace Listing Platforms are decentralized and automated, once you successfully click  the Submit Button, your Project will automatically become live on EdaFace, and receive
                                            Prelim Verif status that will be confirmed later in accordance with EdaFace Compliance Grading."></Form.Check>
                                        </Form.Group>
                                    </Form>
                                    <div className="mt-3 mb-3 text-center  d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn me-0 me-sm-3" onClick={() => setExchangesteps(1)}>
                                            Make Correction
                                        </button>
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn mt-3 mt-sm-0" onClick={submitlist}>
                                            Submit Now
                                        </button>
                                    </div>
                                </div>
                            </>
                        }


                    </div>

                </Container>
            </Container>
            <Footer />

        </>
    )
}

export default Allexchangeform;