import React, { useState, useEffect } from "react";
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";

function Optiontable(props) {

    return (

        <div className="price_table w-100 overauto">

            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Bid Size</th>
                        <th>Bid / IV</th>
                        <th>Mark / IV</th>
                        <th>Ask / IV</th>
                        <th>Ask Size</th>
                        <th>Detta</th>
                        <th>Open (USDT)</th>
                    </tr>
                </thead>
                <tbody>
                    {props.mapdata.map((e, i) =>
                        // <tr className="cursor" onClick={()=>{ if(props.checkright) {props.detailtab(e);props.setCandleTab()}}}>
                        <tr className="cursor" 
                        // onClick={()=>{props.detailtab(e);props.setCandleTab()}}
                        >
                           <td><p className="themeclr mb-0">--</p></td>
                            <td><p className="themeclr mb-0">0.05</p></td>
                            <td>
                                <p className="grnclr mb-0">15.89520</p>
                                <p className="themeclr mb-0">0.00%</p>
                            </td>
                            <td>
                                <p className="themeclr mb-0">15.2584</p>
                                <p className="themeclr mb-0">158.00%</p>
                            </td>
                            <td>
                                <p className="redclr mb-0">18.2584</p>
                                <p className="themeclr mb-0">589.25%</p>
                            </td>
                            <td><p className="themeclr mb-0">0.05</p></td>
                            <td><p className="themeclr mb-0">-0.0569</p></td>
                            <td><p className="themeclr mb-0">98,058.69</p></td>
                            <td ><img  onClick={()=>{props.detailtab(e);props.setCandleTab()}} src={Images.candle} className="callimg" /></td>
                        </tr>)}
                </tbody>
            </Table>

        </div>

    )
}

export default Optiontable