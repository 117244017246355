import React, { useState, useEffect } from 'react';
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";
import { MdOutlineDeleteOutline } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Orderhistory() {
    const [startDate, setStartDate] = useState(new Date("2014/02/08"));
    const [endDate, setEndDate] = useState(new Date("2014/02/10"));
    return (
        <div className='text-center'>
            <div className='d-block d-lg-flex gap-3 align-items-center mt-5 mt-xl-4 mb-2'>
                <ul className='listtab orgtab d-flex justify-content-center gap-2 mb-0 mt-3 mt-lg-0'>
                    <li className='active'>1 Day</li> <li>1 Week</li> <li>1 Month</li>
                </ul>
                <div className='d-block d-sm-flex gap-3 justify-content-center align-items-center mt-3 mt-lg-0 mb-3 mb-lg-0'>
                    <p className='themeclr mb-2 mb-sm-0 f-15 fw-500'>Date</p>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                />
                <p className='themeclr f-14 mb-0'>to</p>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                /></div>
                <button className='btn btn-brand-1 py-1 sitebtn hover-up '>Search</button>
                <button className='btn btn-brand-1 sitebtn ms-3 ms-lg-0 hover-up' disabled>Reset</button>

            </div>
            <div className="price_table w-100 overauto">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Order Time</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Side</th>
                            <th>Average</th>
                            <th>Price</th>
                            <th>Executed</th>
                            <th>Amount</th>
                            <th>Amount per Iceberg Order</th>
                            <th>Total</th>
                            <th>Trigger Conditions</th>
                            <th>SOR</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2024-08-22 19:51:04</td>
                            <td>USDC-23440-Call</td>
                            <td className='redclr fw-500'>Sell</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>2.65</td>
                            <td>675.3</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default Orderhistory;