import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Dropdown, Table } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import walletbanner from "../../Assets/walletbanner.png";
import { Link } from "react-router-dom";
import Images from '../Images';
import { FaArrowRight } from "react-icons/fa6";


const Marginlevelmodals = (props) => {

    const [marginlevelmodal, setMarginlevelmodal] = useState(true);

    return (
        <>

            <div className='walletmodal'>

                <Modal show={marginlevelmodal} size="lg" aria-labelledby="contained-modal-title-vcenter walletlist" centered >
                    <Modal.Body>
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Margin Level (ML)</h5>
                        <p className="f-15 fw-500">Cross Margin 5x<br />Margin Level = Total Asset Value / (Total Borrowed Amount + Unrepaid Interest)<br />Default Margin Level is 999.</p>
                        <div className='liq_table margintable'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Level</th>
                                        <th>Trade</th>
                                        <th>Borrow</th>
                                        <th>Transfer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="mb-1 fw-500">ML {">"} 2</p>
                                            <p className="grnclr f-15 fw-500 mb-0">Low Risk</p>
                                        </td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="mb-1 fw-500">{"1.25 < ML ≤ 2"}</p>
                                            <p className="grnclr f-15 fw-500 mb-0">Low Risk</p>
                                        </td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1tx86"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-7.233 0l3.006 3.005-1.768 1.768L12 13.767l-3.005 3.005-1.768-1.768 3.005-3.005-3.005-3.005 1.768-1.767L12 10.23l3.005-3.005 1.768 1.767L13.767 12z" fill="#848e9c"></path></svg></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="mb-1 fw-500">{"1.16 < ML ≤ 1.25"}</p>
                                            <p className="yellowclr f-15 fw-500 mb-0">MediumRisk</p>
                                        </td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1tx86"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-7.233 0l3.006 3.005-1.768 1.768L12 13.767l-3.005 3.005-1.768-1.768 3.005-3.005-3.005-3.005 1.768-1.767L12 10.23l3.005-3.005 1.768 1.767L13.767 12z" fill="#848e9c"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1tx86"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-7.233 0l3.006 3.005-1.768 1.768L12 13.767l-3.005 3.005-1.768-1.768 3.005-3.005-3.005-3.005 1.768-1.767L12 10.23l3.005-3.005 1.768 1.767L13.767 12z" fill="#848e9c"></path></svg></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="mb-1 fw-500">{"1.1 < ML ≤ 1.16"}</p>
                                            <p className="redclr f-15 fw-500 mb-0">HighRisk Margin Call</p>
                                        </td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1fb9xco"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z" fill="#0ecb81"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1tx86"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-7.233 0l3.006 3.005-1.768 1.768L12 13.767l-3.005 3.005-1.768-1.768 3.005-3.005-3.005-3.005 1.768-1.767L12 10.23l3.005-3.005 1.768 1.767L13.767 12z" fill="#848e9c"></path></svg></td>
                                        <td className="ticksvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1tx86"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-7.233 0l3.006 3.005-1.768 1.768L12 13.767l-3.005 3.005-1.768-1.768 3.005-3.005-3.005-3.005 1.768-1.767L12 10.23l3.005-3.005 1.768 1.767L13.767 12z" fill="#848e9c"></path></svg></td>
                                    </tr>
                                    <tr><td colSpan={4} className="text-end fw-500 py-0 f-14">Inform you to add more collateral (transfer in more collateral assets) to avoid the liquidation</td></tr>
                                    <tr>
                                        <td>
                                            <p className="mb-1 fw-500">{"ML ≤ 1.1"}</p>
                                            <p className="grayclr f-15 fw-500 mb-0">ForcedLiquidation</p>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={4} className="text-end py-0 fw-500 f-14">All assets will be liquidated to pay back the interest and loan.</td></tr>
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body >
                </Modal >

            </div >

        </>
    )
}

export default Marginlevelmodals;