import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { BsQuestionCircle } from "react-icons/bs";


function Transactionmodal(props) {
    const [transactionmodals, setTransactionmodals] = useState(true);
    return (
        <> 
            <div className='walletmodal'>
                <Modal show={transactionmodals} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>                     
                        <h5 className="themeclr fw-600 mb-4">Recent Transaction</h5>                    
                      <p className="themeclr f-14 f-400 text-center my-5">No transaction history</p>
                    </Modal.Body>
                </Modal>


            </div>
        </>

    )
}

export default Transactionmodal;