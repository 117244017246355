import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { GoMail } from "react-icons/go";


const Sourcecode = () => {
    
    const [yesfive, setYesfive] = useState(true);

    return (
        <>


            <div className="text-center">
                <h6 className="formhead">Source Code</h6>
                <p className="content">
                    It seeks to establish transparency and reliability of the crypto project.
                </p>
            </div>
            <Form>
                <Form.Group className="mb-4">
                    <Form.Label className="mb-3">
                        Does the Crypto Project have an accessible Source Code?
                    </Form.Label>
                    <Row>
                        <Col xs={12} md={3} lg={3} className="mb-3 mb-sm-0">
                            <Form.Check type="checkbox" label="Yes" onClick={() => setYesfive(!yesfive)}></Form.Check>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                            <Form.Check type="checkbox" label="No" onClick={() => setYesfive(!yesfive)}></Form.Check>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>If yes, supply the URL link?</Form.Label>
                    <Form.Control type="text" placeholder="Supply url"></Form.Control>
                </Form.Group>
            </Form>


        </>


    )
}

export default Sourcecode;