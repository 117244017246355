import React, { useState, useEffect } from "react";
import Images from "../../Components/Images";
import { Table, Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa";
import Checkbox from "./checkbox";

function Claimtable() {

    const [claimtable, setClaimtable] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }
    ]);
    const Catalogues = [
        {
            id: "01",
            name: "Market Increase"
        },
        {
            id: "02",
            name: "Market Decrease"
        },
        {
            id: "03",
            name: "Deposit"
        },
        {
            id: "04",
            name: "Withdraw"
        },
        {
            id: "05",
            name: "Failed Market Increase"
        },
        {
            id: "06",
            name: "Failed Market Decrease"
        },
        {
            id: "07",
            name: "Failed Deposit"
        },
        {
            id: "08",
            name: "Failed Withdraw"
        }
    ];

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        setList(Catalogues);
    }, [list]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    console.log(isCheck);

    const catalog = list.map(({ id, name }) => {
        return (
            <>
                <div className="ms-2 allcheckbox">
                    <Checkbox
                        key={id}
                        type="checkbox"
                        name={name}
                        id={id}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(id)}
                    />
                    {name}
                </div>
            </>
        );
    });

    return (

        <div className="price_table w-100 overauto">
            <div className="d-flex justify-content-between align-items-center px-3 pb-3">
            <h5 className="themeclr mb-0">Claims History</h5>
            <div><button class="btn btn-brand-1 sitebtn hover-up"><FaDownload/> CSV</button></div>
            </div>
            
            <Table responsive>
                <thead>
                    <tr>
                        <th>
                        <Dropdown className="filterdrop">
                                <Dropdown.Toggle className="fw-500 ps-0"> Action <FaFilter /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="pos mb-3">
                                        <input type="search" className="form-control token_input" placeholder="Search action" />
                                        <img src={Images.searchicon} className="img-fluid search" />
                                    </div>
                                    <p className="mb-2 themeclr f-14" style={{ cursor: "pointer" }}>Clear Seletion</p>
                                    <div className="allcheckbox">
                                        <Checkbox
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            handleClick={handleSelectAll}
                                            isChecked={isCheckAll}
                                        />
                                        Market Orders
                                        {catalog}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </th>
                        <th>
                        <Dropdown className="filterdrop">
                                <Dropdown.Toggle className="fw-500 ps-0"> Market <FaFilter /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="pos mb-2">
                                        <input type="search" className="form-control token_input" placeholder="Search market" />
                                        <img src={Images.searchicon} className="img-fluid search" />
                                    </div>
                                    <p className="mb-2 themeclr f-14" style={{ cursor: "pointer" }}>Clear Seletion</p>
                                    <div>
                                        <div className="form-check all_input mb-2">
                                            <input className="form-check-input" type="checkbox"/>
                                            <label class="form-check-label f-14 themeclr fw-500" for="flexCheckDefault"><img src={Images.eth} className="ethimages"/> BTC/USD</label>
                                        </div>
                                        <div className="form-check all_input mb-2">
                                            <input className="form-check-input" type="checkbox"/>
                                            <label class="form-check-label f-14 themeclr fw-500" for="flexCheckDefault"><img src={Images.eth} className="ethimages"/> BTC/USD</label>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                {!claimtable.length[0] ?
                <>
                    {claimtable.map((e, i) =>
                        <tr>
                            <td>Lorem</td>
                            <td>Lorem</td>
                            <td>$23.87</td>
                        </tr>
                    )}</>
                    :
                    <h6 className="themeclr ms-2 fw-600 my-4">No claims yet</h6>}
                </tbody>
            </Table>
        </div>

    )
}

export default Claimtable;