// local

// export default {
//     nativetoken: {
//         56: '',
//         97: '0xB91cE3a9095CD9A01Ab419081c8F63bf6d9ABe2a',
//     },
//     xtoken: {
//         56: '',
//         97: '0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8',
//     },
//     dividends: {
//         56: '',
//         97: '0x51cf7388044bB4C119941973161328aEbB52809b',
//     },
//     multicall:{
//         56: '',
//         97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe',
//     },
//     router:{
//         56: '',
//         97: '0x773212bAea847b7b091eCc2Eb9a96E3b240Af21D'//'0x4E03F9e5B375008d84F54C5feE6f5835843fAE77' //'0x15b9456f3F4c6F147bE2493B45C19f510cf1337f',
//     },
//     factory:{
//         56: '',
//         97: '0x60e6d21F53b5cB53085B71785F26afC0Dd923aa8'//'0xD919BC4Bed9FcA4264dc178D94D2Db762833Dc96' //'0xCAEE07F8A8C1DA4546E864863139137F0951F450',
//         // 97: '0x4E03F9e5B375008d84F54C5feE6f5835843fAE77',
//     },
//     masterChef:{
//         56: '',
//         97:'0x52883c4253b064e3787FD808fdDe6d7d5cEC8bfB'//"0x82928cBB69CED2D7a301c2B468acbD066cF1c3Fc",//'0x99Cc266c865f480d9C21Dbd0C538CFC9c843e4d8'//'0x05990d2619b89BdDa6eD51A3e461A9145487634E' //'0xCde08C466BD78a67f1c0a7b27779aBc012063d25',
//     },
//     SmartChefFactory:{
//         56: '',
//         97:'0x9ce139296D1c1d2704f384f57055fd2c59C12853'
//     },
//     poolAddress:{
//         56:'',
//         97:'0xeAa81cc2174e73b4C4311f4c7182dC746D1B1627'
//     }
// }


//demo
export default {
    nativetoken: {

        //bsc
        56: '0xC40B73d75Ffc7d01987ec172D7f9931f39e6ab8e',  //M
        97: '0x2a5dBA026e0eD509A6D05C802C9725E5B7C08E71',//'0xB91cE3a9095CD9A01Ab419081c8F63bf6d9ABe2a', //T

        //Eth
        1: '', //M
        11155111: '0xE04619f4e1108762F6bE0Ed26c94A9590aD71552', //T

        //Avax
        43114: '', //M
        43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    xtoken: {

        //bsc
        56: '', //M
        97: '0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    dividends: {

        //bsc
        56: '', //M
        97: '0x51cf7388044bB4C119941973161328aEbB52809b', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    multicall: {

        //bsc
        56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B', //M
        97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe', //T

        //Eth
        1: '', //M
        11155111: '0xE8039f1fbbd2531AA92f553De008510CdA2F7EB9', //T

        //Avax
        43114: '', //M
        43113: '0x279A2739dF44E9E12968909968985bAd8e5520dB', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    router: {

        //bsc
        56: '0x3d0818122a87451e7Fc03dF1718E64a18C01ebDd', //M
        97:'0x1ca348605484937606562608045Da5Bb28462BC7',//'0x4ac4356ab7Ef501a184B91061d651Ae4771197FF', //0x0DEb4ccCd468Ca51b56641a40570b24b61087Fb6  //T

        //Eth
        1: '', //M
        11155111: '0x50AA0056F910c57c4AF54Bc3B1E58B8Aafc0b0F1', //T
        

        //Avax
        43114: '', //M
        43113: '0xC4e02705F9513fB3ce120F95aB8a0d8c4F0419bf', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    factory: {

        //bsc
        56: '0xF6a699942022c1a106800972864Dd3f8C02Bc434', //M
        97: '0x8886a7e4d490Da279AfCD58948b5d13a6e051649', // '0xf6DFAC0edd10Fc06D5B69B8bAE8E287850F6848f',//'0x582fDAE9a8dEfa3C59e989F25e7ADE2Dc5B29538', //T

        //Eth
        1: '', //M
        11155111: '0xae539ec87B0AA9F82607360582bd411907F753a8', //T

        //Avax
        43114: '', //M
        43113: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    masterChef: {

        //bsc
        56: '0x1D9ef7f426E9aB25Ad46E5C99bc5267C23212930', //M
        97: '0xf802bFb84AAE27dC97Fc3F4080f2ec77432f0df6',//'0x7482005B3271CfbAc64D1928E17194287f02EB6e', //T

        //Eth
        1: '', //M
        11155111: '0x55DbF8c0b49C238c3279e3C430032D0c6A3E557B', //T

        //Avax
        43114: '', //M
        43113: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    SmartChefFactory: {

        //bsc
        56: '0xBe2C755404aF61d8946a850316162A9aEF30e672', //M
        97: '0x036068610C528e3a8D8726c1A7ebDFF39a82Eb12',//'0x758c2861114970b01dD1981fCc19Cb7B64cc956f', //T

        //Eth
        1: '', //M
        11155111: '0x86d2EA28E3cba5A2e60eB0c0FAd9164D7F45adD3', //T

        //Avax
        43114: '', //M
        43113: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    poolAddress: {

        //bsc
        56: '0xea3B56818609f6b8144eCf16aE0A6738474473CA', //M
        97: '0x5bA6e674F90a06f26786d13caC319897223E26C8', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    }
}