import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown, MdSwapHoriz, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaArrowDown } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import toast, { Toaster } from "react-hot-toast";

import { Balance } from "../../hooks/useErc20";
import { Harvest, Deposit } from "../../hooks/useMasterChef";
import { isEmpty } from "../../Lib/isEmpty";
import { toFixedNumber } from "../../Lib/FixedNumber";

function Staketokenmodal(props) {
    console.log("props_props33333333333",props);
    const [staketokemodals, setStaketokemodals] = useState(true);
  const [data, setData] = useState();
  console.log("datadata",data);

    const [bal, setBal] = useState();
  const [pending, setPending] = useState(false);
  const [stakeValue, setStakeValue] = useState("");



  useEffect(() => {
    console.log("stakemodal_stakemodal", props?.datas);
    setData(props?.datas);
    getBalance();
  },[props]);


    const getBalance = async () => {
      console.log("bal_getbalance", data?.lpAddress);
        var bal = await Balance(data?.lpAddress);
        setBal(bal);
      };

      const Max = () => {
        setStakeValue(bal);
      };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "stakeValue") {
      var numbers = /^\d+((.)|(.\d{0,30})?)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false;
      }
      setStakeValue(value);
    }
  };
    
      const staking = async () => {
        setPending(true);
        console.log(
          "parseFloat(stakeValue*10**18).toFixed(0) : ",
          parseFloat(stakeValue * 10 ** 18).toFixed(0)
        );
        var res = await Deposit(
          data?.pid,
          toFixedNumber(parseFloat(stakeValue * 10 ** 18).toFixed(0))
        );
        console.log("res", res);
        if (res === true) {
          toast.success(
            `Your ${data?.lpSymbol} funds have been staked in the farms!`
          );
          props.onHide();
          setPending(false);
        } else {
          toast.error("Try Again!");
        }
      };

    return (
        <>
            <div className='walletmodal'>
                <Modal show={staketokemodals} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Stake LP tokens</h5>
                        <div className="swap__blueBox rounded-3 py-3 px-3">
                           <div className="d-flex justify-content-between">
                               <p className="themeclr fw-600 mb-1">Stake</p>
                               <p className="themeclr fw-600">Balance:{bal}</p>
                           </div>
                           <div className="d-flex justify-content-between align-items-center">
                               <input className="form-control fset ps-0" placeholder="0.00"
                                type="text"
                                id="tokenAval"
                                value={
                                  isEmpty(stakeValue) && isNaN(stakeValue)
                                    ? 0.0
                                    : stakeValue
                                }
                                name="stakeValue"
                                onChange={(e) => {
                                  onChange(e);
                                }}
                               ></input>
                               <div className="d-flex align-items-center gap-2">
                                 <button className="btn btn-brand-1 py-2  hover-up"
                                  onClick={() => {
                                    Max();
                                  }}>  {" "}
                                  Max{" "}</button>
                                 <h6 className="mb-0">BNB-USDT</h6>
                               </div>
                           </div>
                        </div>

                        <div className="mt-4 d-flex justify-content-center gap-3">
                          <button className="btn btn-brand-1 sitebtn  hover-up">Cancel</button>
                          <button className="btn btn-brand-1 sitebtn  hover-up"
                          
                          onClick={() => {
                            staking();
                          }}
                          disabled={stakeValue == 0}
                           >
                            {pending == true ? "Confirming..." : "Confirm"}
                            </button>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>

        </>
    )
}

export default Staketokenmodal;