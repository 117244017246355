import React, { useEffect, useState } from 'react';
import { BiSolidGroup } from "react-icons/bi";
import Areachart from './Chartss/areachart';

function Chartcard(props) {

    return (

        <div>
            <div className='chartcard px-3 py-3 rounded-3'>
                <div className='d-flex justify-content-between'>
                    <h6 className='fw-600 blkclr'>BTC/USDT</h6>
                    <h6 className='fw-500 themeclr'><BiSolidGroup className='mts' /> 95</h6>
                </div>
                <div className='mb-3'>

                    <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        {props.tab != "pnl" &&
                            <p className='fw-600 themeclr mb-1'>ROI</p>}
                        {props.tab == "pnl" && <p className='fw-600 themeclr mb-1'>PNL (USDT)</p>}
                        <h5 className='grnclr'>2.22%</h5></div>
                        <Areachart/>
                        </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div><p className='blkclr fw-500 mb-1 f-15'>PNL (USD)</p><p className='themeclr f-15 fw-600 mb-1'>$ 22.26</p></div>
                    <div><p className='blkclr fw-500 mb-1 f-15'>Runtime</p><p className='themeclr f-15 fw-600 mb-1'>4d 13h 20m</p></div>
                    <div><p className='blkclr fw-500 mb-1 f-15'>Min. Investment</p><p className='themeclr f-15 fw-600 mb-1 text-end'>21.13 USDT</p></div>
                </div>
                <div className='d-flex align-items-center justify-content-between mt-2'>
                    <div><p className='blkclr fw-500 mb-1 f-15'>24H/Total Matched Trades</p><p className='themeclr f-15 fw-600 mb-1'>$ 22.26</p></div>
                    <button className='btn btn-brand-1 py-2 sitebtn hover-up' >Copy</button>
                </div>
            </div>

        </div>

    )
}

export default Chartcard;