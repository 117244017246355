import React,{ useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

function Liqtable() {
    const [tablelisted, setTablelisted] = useState([
        { id: 1, clr: "#2C3553", name: "BAL", no: "$100,000", per: "50%" },
        { id: 2, clr: "#656565", name: "WETH", no: "$100,000", per: "50%" },
        { id: 3, clr: "#1AAB9B", name: "MKR", no: "$100,000", per: "50%" },
        { id: 4, clr: "#C36F39", name: "WBTC", no: "$100,000", per: "50%" },
        { id: 4, clr: "#3E73C4", name: "USDC", no: "$100,000", per: "50%" },
    ]);
    return (

        <div>
            <div className='liq_table'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Token</th>
                            <th>USD Value</th>
                            <th>Pool %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tablelisted.map((e, i) =>
                            <tr>
                                <td className='d-flex align-items-center'><span className='clrwd me-2' style={{ backgroundColor: e.clr }}></span>{e.name}</td>
                                <td>{e.no}</td>
                                <td>{e.per}</td>
                            </tr>)}
                        <tr className='px-2'>
                            <td>Total</td>
                            <td>$200,000</td>
                            <td>100%</td>
                        </tr>
                    </tbody>
                </Table></div>

        </div>

    )
}

export default Liqtable