import React, { useEffect, useState } from 'react';
import { Form, Table, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../../Karthick.css";
import "../../inner.css";
import Images from "../../Components/Images";
import { FaSort } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoArrowUp } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function Syntheticsbook(props) {

    const [progresstabs, setProgresstabs] = useState("alltab");
    const [listnumber, setListnumber] = useState([
        { id: 1, width: "20%" }, { id: 2, width: "32%" }, { id: 3, width: "14%" }, { id: 4, width: "10%" },
        { id: 5, width: "19%" }, { id: 6, width: "20%" }, { id: 7, width: "22%" }, { id: 8, width: "24%" },
        // { id: 9, width: "20%" }, { id: 10, width: "34%" }, { id: 11, width: "15%" }, { id: 12, width: "39%" },
    ]);

    const[ethtab,setEthtab] = useState("eth");
    const [width, setWidth] = useState(0);    

    useEffect(() => {
        const handleresize = () => {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleresize);
        return () => {
            window.removeEventListener("resize", handleresize);
        }
    }, []);


    return (
        <div>

            <h6 className='mb-3 fw-600'>Order Book</h6>
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <Dropdown className=''>
                    <DropdownToggle className='px-2 py-1'>$ 0.01</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>$0.01</DropdownItem>
                        <DropdownItem>$1.00</DropdownItem>
                        <DropdownItem>$10.00</DropdownItem>
                        <DropdownItem>$100.00</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <div>
                    <button className={ethtab == "eth"?'btn btn-brand-1 active hover-up py-2 me-2':"btn btn-brand-1 hover-up py-2 me-2"} onClick={()=>setEthtab("eth")}>ETH</button>
                    <button className={ethtab == "usd"?'btn btn-brand-1 active hover-up py-2 me-0':"btn btn-brand-1 hover-up py-2 me-0"} onClick={()=>setEthtab("usd")}>USD</button>
                </div>
            </div>
            <div className="price_header mx-0">
                <div class="contents mb-3">
                    <p className='mb-0 themeclr fw-500'>Price(USDT)</p>
                    <p className='mb-0 themeclr fw-500'>Size {ethtab == "eth" ? "(ETH)":"(USD)"}</p>
                    <p className='mb-0 themeclr fw-500'>Sum{ethtab == "eth" ? "(ETH)":"(USD)"}</p>
                </div>
            </div>

            {listnumber.map((e, i) =>
                              <OverlayTrigger
                              placement={width > 1199 ? "right" : "bottom" }
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip" className='w300'>
                                 <div className='d-flex justify-content-between mt-1 mb-2'><p className='mb-0'>Avg Price</p><p className='mb-0'>0.051</p></div>
                                 <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum ETH</p><p className='mb-0'>19,254</p></div>
                                 <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum USDT</p><p className='mb-0'>62,014</p></div>
                              </Tooltip>}
                          >
                <div className='price_change px-2 cursor hovertr'>
                    <span className='width_set' style={{ background: "#f6465d", width: e.width }}></span>
                    <p className='mb-0 redclr'>560.00</p>
                    <p className='mb-0'>2.50</p>
                    <p className='mb-0'>48.39</p>
                </div>
                </OverlayTrigger>
                )}
            <div className='b_btop mt-1'>
                <div className='price_change px-2 mt-2 mb-2'>
                    <h6 className='mb-0'>Price</h6>
                    <h6 className='mb-0'>354.84</h6>
                    <p className='mb-0'>-</p>
                </div>
            </div>
            <div className='mt-2'>
                {listnumber.map((e, i) =>
                   <OverlayTrigger
                   placement={width > 1199 ? "right" : "bottom" }
                   delay={{ show: 250, hide: 400 }}
                   overlay={<Tooltip id="button-tooltip" className='w300'>
                      <div className='d-flex justify-content-between mt-1 mb-2'><p className='mb-0'>Avg Price</p><p className='mb-0'>0.051</p></div>
                      <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum ETH</p><p className='mb-0'>19,254</p></div>
                      <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum USDT</p><p className='mb-0'>62,014</p></div>
                   </Tooltip>}
               >
                    <div className='price_change px-2 cursor hovertr'>
                        <span className='width_set' style={{ background: "green", width: e.width }}></span>
                        <p className='mb-0 grnclr'>505.00</p>
                        <p className='mb-0'>11.84</p>
                        <p className='mb-0'>9.60</p>
                    </div>
                    </OverlayTrigger>
                    )}
            </div>

        </div>
    )
}

export default Syntheticsbook;