import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, ProgressBar, Table } from 'react-bootstrap';
import Images from './Images';
import Selecttokenmodal from "./selecttokenmodal";
import { FaLock } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import Donuts from './Chartss/donutcharts';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import Liqtable from './tables/liqtable';
import { isEmpty } from '../Lib/common';
import { Slider } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { TiTick } from "react-icons/ti";


function Hetrotype() {
    const [checkedopen, setCheckedopen] = useState(true);
    const [tickopen, setTickopen] = useState(false);
    const [tokennetmodal, setTokennetmodal] = useState(false);
    const [value, setvalue] = useState(60);
    const [stepsss, setStepsss] = useState(1);
    const [filterRows, setFilterRows] = useState([{ filters: "" }]);
    const addFilters = () => {
        setFilterRows([...filterRows, { filters: "" }])
    };
    const removeFilters = (index) => {
        const list = [...filterRows]
        list.splice(index, 1)
        setFilterRows(list)
    };
    const [tokenchenge, setTokenchenge] = useState({});
    const [initialliq, setInitialliq] = useState(
        {
            id: 1, image: Images.eth, coinname: "WETH",
        }
    );
    const [liquiditylist, setLiquiditylist] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }
    ]);
    const [weightpool, setWeightpool] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }
    ]);
    const [einput, setEinput] = useState(false);
    const [einput1, setEinput1] = useState(false);
    const [einput2, setEinput2] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [stepsss]);

    return (
        <>
            {tokennetmodal && <Selecttokenmodal onDismiss={() => setTokennetmodal(false)} setInitialliq={(e) => setInitialliq(e)} setTokenchenge={(e) => setTokenchenge(e)} />}
            <div >

                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-6 col-xxl-5 mt-4'>
                        <div className='option_border px-4 py-4'>
                            <h6>BNB Mainnet</h6>
                            {stepsss == 1 &&
                                <>
                                    <h5 className='tabbtntxt pb-3'>Choose tokens & weights</h5>
                                    {filterRows.map((index, id) => (
                                        <div className='s_token d-block d-sm-flex justify-content-between rounded-4 py-4 px-3 mt-3'>
                                            <Dropdown>
                                                <DropdownToggle className='fw-600' onClick={() => setTokennetmodal(true)}>{!isEmpty(tokenchenge) && <img src={tokenchenge?.image} className='img-fluid ethimg me-2' />}{isEmpty(tokenchenge) ? 'Select Token' : tokenchenge?.coinname}</DropdownToggle>
                                            </Dropdown>
                                            <div className='d-flex align-items-center mt-3 mt-sm-0 fitcontent'>
                                                <h5 className='themeclr mb-0 me-sm-3 fw-500'>50%</h5>
                                                <button className='btn tbtn me-sm-2 fw-500'><FaLock /> Lock</button>
                                                <button className='btn tbtn fw-500' onClick={removeFilters}><MdDelete /> Delete</button>
                                            </div>
                                        </div>
                                    ))}
                                    <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3" onClick={addFilters}>Add Token</button>

                                    <div className='d-flex align-items-center justify-content-between mt-2 mb-2'>
                                        <h5 className='themeclr fw-500'>Total Allocated</h5>
                                        <h5 className='themeclr fw-500'>{value}%</h5>
                                    </div>
                                    <span className="pt-3"><ProgressBar now={value} /></span>

                                    <div className='inst rounded-3 px-3 py-3 mt-4'>
                                        <p className='themeclr fw-600'><RiErrorWarningLine className='redclr mts' /> It’s  recommended to provide new pools with at least $20,000 in initial funds</p>
                                        <p className='themeclr fw-400 mb-0'>Based  on your wallet balances for these tokens, the maximum amount you can fund this pool with is ~$0.00.</p>
                                    </div>
                                    <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => setStepsss(2)}>Next</button>
                                </>
                            }

                            {stepsss == 2 &&
                                <>
                                    <h5 className='tabbtntxt pb-3'> <MdOutlineKeyboardArrowLeft style={{ cursor: "pointer" }} onClick={() => setStepsss(1)} className="mts" /> Set Pool Fees</h5>
                                    <h5 className='tabbtntxt'>Initial Swap Fees</h5>

                                    <p className='fw-400 themeclr'>0.30% is best for most weighted pools with established
                                        token. Go higher for more exotic tokens.</p>
                                    <div className='d-flex flex-column flex-sm-row gap-3'>
                                        <button className='btn tbtn rounded-2 px-3'>0.1%</button>
                                        <button className='btn tbtn rounded-2 px-3'>0.1%</button>
                                        <button className='btn tbtn rounded-2 px-3'>0.1%</button>
                                        <button className='btn tbtn rounded-2 px-3'>0.1%</button>
                                    </div>
                                    <div class="form-check pt-3">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={checkedopen} onClick={() => setCheckedopen(!checkedopen)} />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Allow balancer governance to manage fees
                                        </label>
                                    </div>
                                    {checkedopen ? <></> :
                                        <div>
                                            <h5 className="title mt-3 mb-0">Alternative fee management options</h5>
                                            <div class="form-check pt-3">
                                                <input class="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Allow balancer governance to manage fees
                                                </label>
                                            </div>
                                            <div class="form-check pt-3">
                                                <input class="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Allow balancer governance to manage fees
                                                </label>
                                            </div>
                                        </div>}

                                    <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => setStepsss(3)}>Next</button>

                                </>
                            }

                            {stepsss == 3 &&
                                <>
                                    <h5 className='tabbtntxt pb-3'> <MdOutlineKeyboardArrowLeft style={{ cursor: "pointer" }} onClick={() => setStepsss(2)} className="mts" /> Set initial liquidity</h5>
                                    {liquiditylist.map((e, i) =>
                                        <div className='s_token rounded-3 py-3 px-3 mt-3'>
                                            <div className='d-flex align-items-center justify-content-between flex-column flex-sm-row gap-3 gap-sm-0'>
                                                <Dropdown>
                                                    <DropdownToggle className='fw-600' onClick={() => setTokennetmodal(true)}><img src={initialliq?.image} className='img-fluid ethimg me-1' /> {initialliq?.coinname} 50%</DropdownToggle>
                                                </Dropdown>
                                                <p className='lgtblue mb-0'>4803,073967339097022094</p></div>
                                            <div className='d-flex align-items-center justify-content-between mt-3'>
                                                <p className='mb-0 lgtblue fw-600'><span>Balance: 15,000 </span> <span className='orgclr'> MAX</span></p>
                                                <p className='mb-0 lgtblue fw-500'>$1,00,000</p>
                                            </div>
                                            <div className='mt-3'>
                                                <Slider
                                                    disabled
                                                    progress
                                                    defaultValue={50}
                                                    onChange={value => {
                                                        console.log(value);
                                                    }}
                                                />
                                            </div>
                                        </div>)}
                                    <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => setStepsss(4)}>Preview</button>
                                </>
                            }


                            {stepsss == 4 &&
                                <>
                                    <h5 className='tabbtntxt pb-1'> <MdOutlineKeyboardArrowLeft style={{ cursor: "pointer" }} onClick={() => setStepsss(3)} className="mts" /> Preview new weighted pool</h5>
                                    {weightpool.map((e, i) =>
                                        <div class="d-flex justify-content-between bbs mt-3">
                                            <div className='d-flex'>
                                                <img src={Images.eth} className='img-fluid ethimg me-2' />
                                                <div>
                                                    <p className='thinblue mb-1'>50.00% <span className='themeclr fw-600'>BAL</span></p>
                                                    <p className='thinblue mb-0 fw-500'>Initial weight: 50.5%</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className='fw-500 themeclr mb-1'>4,803.074</p>
                                                <p className='themeclr mb-0'>$100,000</p>
                                            </div>
                                        </div>)}
                                    <div class="d-flex justify-content-between mt-3">
                                        <h5 className='themeclr'>Total</h5>
                                        <h5 className='themeclr'>$ 199,785</h5>
                                    </div>
                                    <div>
                                        <div className='s_token rounded-top-3 px-3 py-2 mt-3'>
                                            <h5 className='mb-0 fw-500 themeclr'>Summary</h5>
                                        </div>
                                        <div className='option_border py-3 px-3 mt-1 rounded-top-1 rounded-bottom-3'>
                                            <div className='d-block d-sm-flex justify-content-between'><p className='themeclr fw-500'>Pool symbol :</p>
                                                <div className='d-flex'>
                                                    {einput ? <input type="text" className="form-control  me-3 mb-2" placeholder="0.00" /> : <p className='themeclr'>50BAL-15WETH-15MKR-</p>}
                                                    {einput ? <TiTick className="set_icon grnclr cursor mt-1 ms-1" onClick={() => setEinput(!einput)} /> : <FaEdit className="orgclr cursor mt-1 ms-1" onClick={() => setEinput(!einput)} />}</div>
                                            </div>
                                            <div className='d-block d-sm-flex justify-content-between'><p className='themeclr fw-500'>Pool name:</p>
                                            <div className='d-flex'>
                                                    {einput1 ? <input type="text" className="form-control  me-3 mb-2" placeholder="0.00" /> : <p className='themeclr'>50BAL-15WETH-15MKR-</p>}
                                                    {einput1 ? <TiTick className="set_icon grnclr cursor mt-1 ms-1" onClick={() => setEinput1(!einput1)} /> : <FaEdit className="orgclr cursor mt-1 ms-1" onClick={() => setEinput1(!einput1)} />}</div>
                                            </div>
                                            <div className='d-block d-sm-flex justify-content-between'><p className='themeclr fw-500'>Pool name:</p>
                                                <p className='themeclr'>Weighted</p>
                                            </div>
                                            <div className='d-block d-sm-flex justify-content-between'><p className='themeclr fw-500'>Swap fee :</p>
                                            <div className='d-flex'>
                                                    {einput2 ? <input type="text" className="form-control  me-3 mb-2" placeholder="0.00" /> : <p className='themeclr'>1.00</p>}
                                                    {einput2 ? <TiTick className="set_icon grnclr cursor mt-1 ms-1" onClick={() => setEinput2(!einput2)} /> : <FaEdit className="orgclr cursor mt-1 ms-1" onClick={() => setEinput2(!einput2)} />}</div>
                                            </div>

                                            <div className="confirmlisted mt-2">
                                                {!tickopen ?
                                                    <ul className='mb-0'>
                                                        <li><span>2</span></li>
                                                        <li><span>3</span></li>
                                                        <li><span>4</span></li>
                                                        <li><span>5</span></li>
                                                        <li><span>6</span></li>
                                                    </ul> :
                                                    <ul>
                                                        <li>
                                                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                            </svg>
                                                        </li>
                                                        <li>
                                                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                            </svg>
                                                        </li>
                                                        <li>
                                                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                            </svg>
                                                        </li>
                                                        <li>
                                                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                            </svg>
                                                        </li>
                                                        <li>
                                                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                            </svg>
                                                        </li>
                                                    </ul>}
                                            </div>
                                        </div>
                                        {tickopen == true ?
                                            <Link to="/view-pools"><button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" >View Pools</button></Link> :
                                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => setTickopen(true)}>Approve BAL for investing</button>
                                        }
                                    </div>
                                </>
                            }


                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-6 col-xxl-7 mt-4'>
                        <div className='option_border px-4 py-4'>
                            <h5 className='themeclr'>Pools Summary</h5>
                            <Donuts />
                            <p className='themeclr fw-500 mt-4'>Max initial liquidity in wallet</p>
                            <Liqtable />
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Hetrotype;