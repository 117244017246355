import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import Images from '../Images';
import Walletpage from "../../Components/Modals/walletmodal"

const Subheader = () => {

    const [Walletmodal, setWalletmodal] = useState(false);

    return (
        <>
            {Walletmodal && <Walletpage onDismiss={() => setWalletmodal(false)} />}
            <div>

                <div className='silderss slidelist mb-2 mb-sm-4'>
                    <div className="ruby-wrapper">
                        <ul className="ruby-menu allgrid">
                            <li>
                                <Link to="/"> <div className='d-flex align-items-center'>
                                    <img src={Images.trading1} className='img-fluid weblogo me-2' />
                                    <span>Trading</span>
                                </div></Link>
                                <ul className="lastss">
                                    {/* <li><Link to="/swap"><img src={Images.docss} className="img-fluid navimg me-1" alt="images" /> Swap</Link></li> */}
                                    <li><Link to="/spot"><img src={Images.docss} className="img-fluid navimg me-1" alt="images" /> Spot</Link></li>
                                    <li><Link to="/future"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Futures Perpetual</Link></li>
                                    <li><Link to="/option"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Options</Link></li>
                                    <li><Link to="/synthetics"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Synthetics</Link></li>
                                    <li><Link to="https://p2pmarket.edaface.com/" target="_blank"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> P2P Market</Link></li>
                                </ul>
                                <span className="ruby-dropdown-toggle"></span>
                            </li>
                            <li>
                                <Link to="/">    <div className='d-flex align-items-center'>
                                    <img src={Images.trading2} className='img-fluid weblogo me-2' />
                                    <span>Staking</span>
                                </div></Link>
                                <ul className="lastss">
                                    <li><Link to="/pools"><img src={Images.docss} className="img-fluid navimg me-1" alt="images" /> MIM Pools</Link></li>
                                    <li><Link to="/farms"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Farms</Link></li>
                                    <li><Link to="/pond"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Ponds</Link></li>
                                </ul>
                                <span className="ruby-dropdown-toggle"></span>
                            </li>
                            <li>
                                <Link to="/"><div className='d-flex align-items-center'>
                                    <img src={Images.trading3} className='img-fluid weblogo me-2' />
                                    <span>NFTs</span>
                                </div></Link>
                                <ul className="lastss">
                                    <li><Link to="/"><img src={Images.docss} className="img-fluid navimg me-1" alt="images" /> NFT Marketplace</Link></li>
                                    <li><Link to="/"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> NFT Staking</Link></li>
                                </ul>
                                <span className="ruby-dropdown-toggle"></span>
                            </li>
                            <li>
                                <Link to="/lendandborrow"><div className='d-flex align-items-center'>
                                    <img src={Images.trading4} className='img-fluid weblogo me-2' />
                                    <span>Lend and Borrow</span>
                                </div></Link>
                                {/* <ul className="lastss">
                                    <li><Link to="/"><img src={Images.docss} className="img-fluid navimg me-1" alt="images" /> Swap</Link></li>
                                    <li><Link to="/"><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Options</Link></li>
                                </ul>
                                <span className="ruby-dropdown-toggle"></span> */}
                            </li>
                            <li>
                                <Link to="/">     <div className='d-flex align-items-center'>
                                    <img src={Images.trading5} className='img-fluid weblogo' />
                                    <span>Ecosystem</span>
                                </div></Link>
                                <ul className="lastss">
                                    <li><Link to="/" target='_blank'><img src={Images.docss} className="img-fluid navimg me-1" alt="images" /> Bridges</Link></li>
                                    <li><Link to="/"  target='_blank'><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Chat Forum</Link></li>
                                    <li><Link to="/"  target='_blank'><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Developers</Link></li>
                                    <li><Link to="/"  target='_blank'><img src={Images.square} className="img-fluid navimg me-1" alt="images" /> Launchpad</Link></li>
                                </ul>
                                <span className="ruby-dropdown-toggle"></span>
                            </li>
                            {/* <li>
                                <Link to="/"  target='_blank'>         <div className='d-flex align-items-center'>
                                    <img src={Images.trading6} className='img-fluid weblogo' />
                                    <span>Resources</span>
                                </div></Link>
                            </li> */}
                            {/* <li>
                                <Link to="/">
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.trading7} className='img-fluid weblogo' />
                                        <span>Profile</span>
                                    </div></Link>
                                <ul className="lastss">
                                    <li><Link to="/login"><img src={Images.login} className="img-fluid navimg me-1" alt="images" /> Login</Link></li>
                                    <li><Link to="/login"><img src={Images.registration} className="img-fluid navimg me-1" alt="images" /> Register</Link></li>
                                    <li><Link to="/"><img src={Images.registration} className="img-fluid navimg me-1" alt="images" /> Buy $EDA</Link></li>
                                </ul>
                                <span className="ruby-dropdown-toggle"></span>
                            </li> */}
                        </ul>
                    </div>
                </div>

                {/* <div className='silderss slidelist mb-2 mb-sm-4'>
                                            <div className='allgrid'>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading1} className='img-fluid weblogo' />
                                                    <span>Trading</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading2} className='img-fluid weblogo' />
                                                    <span>Staking</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading3} className='img-fluid weblogo' />
                                                    <span>NFTs</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading4} className='img-fluid weblogo' />
                                                    <span>Lend and Borrow</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading5} className='img-fluid weblogo' />
                                                    <span>Ecosystem</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading6} className='img-fluid weblogo' />
                                                    <span>Resources</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={trading7} className='img-fluid weblogo' />
                                                    <span>Profile</span>
                                                </div>
                                            </div>
                            </div> */}


            </div>



        </>
    )
}

export default Subheader;