import { getMasterChefAddress, UseMasterChef } from "./useMasterChef"
import {UseERC20} from './useErc20'
import { useWeb3 } from "./useWeb3";
import { getWalletAddress } from "../Lib/localStorage";


export const UserDeposits = async(pid)=>{
   try{ const stake = await UseMasterChef();
    const account = getWalletAddress()
    const info = await stake.methods.userInfo(pid,account).call();
    console.log("UserDeposits",info)
    return info;}catch(err){
        console.log('UserDeposits__err',err)
    }
}

export const IsValidAddress = async(ADDRESS) =>{
    const web3 = await useWeb3();
    const value = await web3.utils.isAddress(ADDRESS);
    return value;
}

export const DepositFunds = async (amount,account,isMax,pid)=>{
    const staker = await UseMasterChef();
    const amt = isMax ? amount : toFixedNumber(amount * 10 ** 18);
    const valid = await IsValidAddress(account);
    console.log("Deposit params : ",pid,amt.toString(),account,isMax,valid);
    await staker.methods.deposit(pid.toString(),amt.toString()).send({ from: account.toString() });
   
}

export const UseUserAllownace = async(account,token) =>{
    const contract = await UseERC20(token);
    const allowance = await contract.methods.allowance(account,getMasterChefAddress()).call();
    return allowance;
}

export const Withdrawl = async(amount,account,isMax,pid)=>{
    const staker = await UseMasterChef();
    const amt = isMax ? amount : toFixedNumber(amount * 10 ** 18);
    await staker.methods.withdraw(pid,amt.toString()).send({ from: account });
   
}

export const Claim = async (account,pid)=>{
    const staker = await UseMasterChef();  
    await staker.methods.deposit(pid,0).send({ from: account });
}


export const approveContract = async(token, account,CONTRACT_ADDRESS)=>{
    const contract = await UseERC20(token);
    await contract.methods.approve(CONTRACT_ADDRESS,"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account})
}

export const toFixedNumber = (x)=> {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}